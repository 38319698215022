import React from "react";
import PropTypes from "prop-types";
import QuillEditor from "../components/QuillEditor";
import { Button, Card, CardBody, CardHeader, Input, Table } from "reactstrap";
import * as Api from "../Api";
import DialogManager from "../dialogs/DialogManager";

class Notice extends React.Component {
  static propTypes = {};

  state = {
    inputStr: "",
    htmlMode: false,
    popupList: [],
    selectedItem: null,
    inputStartDate: "",
    inputEndDate: "",
  };

  componentDidMount() {
    this.fetchPopupList();
  }

  fetchPopupList = () => {
    Api.getPopupList().then((response) => {
      this.setState({ popupList: response.data });
    });
  };

  onPopupItemClick = (item) => {
    if (this.state.selectedItem && this.state.selectedItem.id === item.id) {
      this.setState({
        selectedItem: null,
        inputStr: "",
        inputStartDate: "",
        inputEndDate: "",
      });
    } else {
      this.setState({
        selectedItem: item,
        inputStr: item.content,
        inputStartDate: this.dateToString(item.startDate),
        inputEndDate: this.dateToString(item.endDate),
      });
    }
  };

  dateToString(date) {
    if (date) return new Date(date).toFormat("YYYY-MM-DD HH:MI:SS");
    return "";
  }

  onSubmit = () => {
    if (this.state.selectedItem) {
      Api.editPopup(this.state.selectedItem.id, this.state.inputStr, this.state.inputStartDate, this.state.inputEndDate)
        .then((response) => {
          this.fetchPopupList();
        })
        .catch((error) => {
          this.fetchPopupList();
          DialogManager.alert("수정에 실패했습니다.");
        });
    } else {
      Api.postPopup(this.state.inputStr, this.state.inputStartDate, this.state.inputEndDate)
        .then((response) => {
          this.fetchPopupList();
        })
        .catch((error) => {
          this.fetchPopupList();
          DialogManager.alert("추가에 실패했습니다.");
        });
    }
  };

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <div style={styles.container}>
        <Table style={styles.table}>
          <tbody>
            <tr>
              <th>id</th>
              <th>내용</th>
              <th>시작일</th>
              <th>종료일</th>
            </tr>
            {this.state.popupList
              ? this.state.popupList.map((item, key) => {
                  return (
                    <tr
                      key={key}
                      style={this.state.selectedItem && this.state.selectedItem.id === item.id ? styles.selectedTr : styles.tr}
                      onClick={() => {
                        this.onPopupItemClick(item);
                      }}
                    >
                      <td>{item.id}</td>
                      <td>{item.content}</td>
                      <td>{this.dateToString(item.startDate)}</td>
                      <td>{this.dateToString(item.endDate)}</td>
                    </tr>
                  );
                })
              : undefined}
          </tbody>
        </Table>

        <Card>
          <CardHeader>{this.state.selectedItem ? "수정" : "추가"}</CardHeader>
          <CardBody>
            {!this.state.htmlMode ? (
              <div style={styles.whiteBg}>
                <QuillEditor
                  value={this.state.inputStr}
                  modules={editorModules}
                  onChange={(value) => {
                    this.setState({ inputStr: value });
                  }}
                />
              </div>
            ) : (
              <Input
                type="textarea"
                value={this.state.inputStr}
                onChange={(e) => {
                  this.setState({ inputStr: e.target.value });
                }}
              />
            )}

            <div className="margin-bottom-10">
              <Button
                onClick={() => {
                  this.setState({ htmlMode: false });
                }}
                color={this.state.htmlMode ? "" : "primary"}
              >
                text
              </Button>
              <Button
                onClick={() => {
                  this.setState({ htmlMode: true });
                }}
                color={!this.state.htmlMode ? "" : "primary"}
              >
                html
              </Button>
            </div>

            <Input
              value={this.state.inputStartDate}
              onChange={this.onInputChange}
              name="inputStartDate"
              placeholder="시작일 (2018-01-01 18:00:00)"
              className="margin-bottom-10"
            />
            <Input
              value={this.state.inputEndDate}
              onChange={this.onInputChange}
              name="inputEndDate"
              placeholder="종료일 (2018-01-01 18:00:00)"
              className="margin-bottom-10"
            />

            <Button onClick={this.onSubmit} color="primary" block>
              {this.state.selectedItem ? "수정" : "추가"}
            </Button>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const styles = {
  container: {},
  whiteBg: {
    backgroundColor: "white",
  },
  table: {
    marginBottom: 10,
    backgroundColor: "white",
  },
  tr: {
    cursor: "pointer",
  },
  selectedTr: {
    cursor: "pointer",
    backgroundColor: "#BBDEFB",
  },
};

const editorModules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],

    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button
    ["link", "image"],
    ["clean"],
  ],
};

export default Notice;
