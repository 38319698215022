/**
 * Created by kimchangduk on 2017-10-27.
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Consts } from "../../constants";
import { getCBTSubjectList } from "../../actions/cbt";
import ListSearch from "../ListSearch/ListSearch";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import * as Api from "../../Api";
import DialogManager from "../../dialogs/DialogManager";
import { processHttpError } from "../../actions/utils";
import { getMessageFromResponse } from "../../utils";

class SubjectEditor extends React.Component {
  static propTypes = {
    actions: PropTypes.object,
    subjectList: PropTypes.array,
  };

  static StateToProps = (state, ownProps) => {
    return { subjectList: state.cbt.subjectList.dataSource };
  };

  static DispatchToProps = (dispatch, ownProps) => {
    return {
      actions: {
        getSubjectList: () => {
          dispatch(getCBTSubjectList());
        },
      },
    };
  };

  state = {
    selectedSubject: null,
    editMode: Consts.EditModes.ADD,
    nameInput: "",
  };

  componentDidMount() {
    this.props.actions.getSubjectList();
  }

  onSelect = (item) => {
    this.setState({
      selectedSubject: item,
      nameInput: item.name,
      editMode: Consts.EditModes.MODIFY,
    });
  };

  onAddClick = () => {
    this.setState({
      selectedSubject: null,
      nameInput: "",
      editMode: Consts.EditModes.ADD,
    });
  };

  onInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = () => {
    const request =
      this.state.editMode === Consts.EditModes.ADD
        ? Api.addCBTSubject(this.state.nameInput)
        : Api.editCBTSubject(this.state.selectedSubject.id, this.state.nameInput);

    request
      .then((response) => {
        this.props.actions.getSubjectList();
      })
      .catch((error) => {
        DialogManager.alert(getMessageFromResponse(error.response, `과목 ${this.state.editMode === Consts.EditModes.ADD ? "추가" : "수정"}에 실패했습니다.`));
      });
  };

  render() {
    return (
      <Row>
        <ListSearch
          dataSource={this.props.subjectList}
          title="과목"
          xs={12}
          md={4}
          getName={(item) => item.name}
          onSelect={this.onSelect}
          isSelectedItem={(item) => item === this.state.selectedSubject}
          onAddClick={this.onAddClick}
        />
        <Col xs={12} md={8}>
          <Card>
            <CardHeader>과목 {this.state.editMode === Consts.EditModes.ADD ? "추가" : "수정"}</CardHeader>
            <CardBody className="editor">
              {this.state.editMode === Consts.EditModes.MODIFY && this.state.selectedSubject ? (
                <div className="data-row">
                  <div className="name">id</div>
                  <div className="value">{this.state.selectedSubject.id}</div>
                </div>
              ) : undefined}

              <div className="data-row">
                <div className="name">이름</div>
                <div className="value">
                  <input type="text" className="full-width" name="nameInput" value={this.state.nameInput} onChange={this.onInputChange} />
                </div>
              </div>
              <Button color="primary" block onClick={this.onSubmit}>
                {this.state.editMode === Consts.EditModes.ADD ? "추가" : "수정"}
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default connect(SubjectEditor.StateToProps, SubjectEditor.DispatchToProps)(SubjectEditor);
