/**
 * Created by kimchangduk on 2018-02-10.
 */

import React from "react";
import Radium from "radium";
import PropTypes from "prop-types";
import axios from "axios";
import AutoCompleteInput from "./AutoCompleteInput";

class CouponItemSearchInput extends React.Component {
  static propTypes = {
    onItemSelect: PropTypes.func,
  };

  state = {
    itemList: [],
  };

  componentDidMount() {
    axios.get("/v1/manage/util/items").then((response) => {
      this.setState({ itemList: response.data });
    });
  }

  render() {
    return (
      <AutoCompleteInput
        placeholder="추가할 덱, CBT, 멤버쉽 이름"
        searchResultHeaders={["", "type", "name", "urlKey", "id"]}
        dataSource={this.state.itemList}
        onFilter={(item, input) =>
          input
            ? item.name.toLowerCase().match(input.toLowerCase()) ||
              item.urlKey.toLowerCase().match(input.toLowerCase()) ||
              item.id.toString().toLowerCase().match(input.toLowerCase())
            : true
        }
        onTdMapping={(item, key) => {
          return [item.coverImage ? <img src={item.coverImage} style={styles.itemItemThumbnail} /> : "", item.type, item.name, item.urlKey, item.id];
        }}
        onSelect={this.props.onItemSelect}
      />
    );
  }
}

const styles = {
  itemList: {
    position: "absolute",
    top: "100%",
    left: 0,
    right: 0,
    maxHeight: 300,
    height: "auto",
    backgroundColor: "#FFFFFF",
    border: "1px solid #c8c8c8",
    overflowY: "auto",
    zIndex: 1,
  },
  listItem: {
    cursor: "pointer",
    [":hover"]: {
      backgroundColor: "#B3E5FC",
    },
  },
  itemItemThumbnail: {
    width: 30,
    height: 30,
    verticalAlign: "middle",
  },
  listItemType: {
    verticalAlign: "middle",
    marginLeft: 30,
  },
  listItemNamename: {
    verticalAlign: "middle",
    marginLeft: 30,
  },
};

export default Radium(CouponItemSearchInput);
