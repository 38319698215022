/**
 * Created by kimchangduk on 2017-10-27.
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Consts } from "../../constants";
import { getCBTDetail, getCBTTestList } from "../../actions/cbt";
import ListSearch from "../ListSearch/ListSearch";
import { Button, Card, CardBody, CardHeader, Col, Input, Row } from "reactstrap";
import * as Api from "../../Api";
import DialogManager from "../../dialogs/DialogManager";
import { getMessageFromResponse, hasChangedRequestToSuccess } from "../../utils";
import ClassSelectField from "./ClassSelectField";
import QuestionEditor from "./QuestionEditor";
import TestPageEditor from "./TestPageEditor";
import QuestionCSVBulkInsert from "./QuestionCSVBulkInsert";
import PassGradeEditor from "./PassGradeEditor";
import SafeDeleteDialog from "../../dialogs/SafeDeleteDialog";
import PriceEditor from "../PriceEditor";
import HashtagEditor from "../HashtagEditor";
import Loader from "../../views/Loader/Loader";
import QuillEditor from "../QuillEditor";

class TestEditor extends React.Component {
  static propTypes = {
    actions: PropTypes.object,
    testDetail: PropTypes.object,
    testDetailRequest: PropTypes.string,
    testDetailId: PropTypes.number,
    testList: PropTypes.array,
    testListRequest: PropTypes.string,
  };

  static StateToProps = (state, ownProps) => {
    return {
      testList: state.cbt.testList.dataSource,
      testListRequest: state.cbt.testList.state.request,
      testDetail: state.cbt.testDetail.dataSource,
      testDetailRequest: state.cbt.testDetail.state.request,
      testDetailId: state.cbt.testDetail.state.id,
    };
  };

  static DispatchToProps = (dispatch, ownProps) => {
    return {
      actions: {
        getTestList: () => {
          dispatch(getCBTTestList());
        },
        getCBTDetail: (id) => {
          dispatch(getCBTDetail(id));
        },
      },
    };
  };

  state = {
    waiting: false,

    selectedCBT: null,
    selectedCBTQuestion: null,
    selectItemType: SelectItemType.CBT,
    editMode: EditModes.TEST_ADD,
    nameInput: "",
    classId: "",
    year: "",
    round: "",
    price: "",
    salesLabel: "",
    minuteLimit: "",
    description: "",
    image: null,
    imageName: "",
    sale: false,
    questions: [],
    discountPrice: null,
  };

  componentDidMount() {
    this.props.actions.getTestList();
  }

  componentWillReceiveProps(nextProps) {
    if (hasChangedRequestToSuccess(this.props.testListRequest, nextProps.testListRequest) && this.state.selectedCBT) {
      const newSelected = nextProps.testList.find((item) => this.state.selectedCBT.id === item.id);
      if (newSelected) {
        this.onSelect(newSelected);
      }
    }

    if (hasChangedRequestToSuccess(this.props.testDetailRequest, nextProps.testDetailRequest)) {
      this.setState({
        questions: [],
      });
      Api.getCBTRowQuestionList(nextProps.testDetailId)
        .then((response) => {
          this.setState({
            questions: response.data,
          });
          if (this.state.selectedCBT && this.state.selectedCBTQuestion && this.state.selectedCBT.id === nextProps.testDetailId) {
            const newSelected = response.data.find((item) => this.state.selectedCBTQuestion.id === item.id);
            this.onSelectQuestion(newSelected);
          }
        })
        .catch((error) => {
          DialogManager.alert(getMessageFromResponse(error.response, "문제를 가져올 수 없습니다."));
        });
    }
  }

  onSelect = (item) => {
    this.props.actions.getCBTDetail(item.id);
    const updateValue = {
      selectedCBT: item,
      selectedCBTQuestion: null,
      editMode: EditModes.TEST_MODIFY,
    };

    if (item) {
      updateValue.nameInput = item.name;
      updateValue.classId = item.clazz ? item.clazz.id : "";
      updateValue.visibility = item.visibility ? item.visibility : "";
      updateValue.showDashboard = item.showDashboard;
      updateValue.year = item.year !== null ? item.year.toString() : "";
      updateValue.round = item.round !== null ? item.round.toString() : "";
      updateValue.price = item.price !== null ? item.price.toString() : "";
      updateValue.minuteLimit = item.minuteLimit !== undefined && item.minuteLimit !== null ? item.minuteLimit.toString() : "";
      updateValue.description = item.description ? item.description : "";
      updateValue.image = null;
      updateValue.imageName = "";
      updateValue.sale = item.price !== null;
      updateValue.price = item.price ? item.price.toString() : "0";
      updateValue.discountPrice = item.discountPrice !== null && item.discountPrice !== undefined ? item.discountPrice.toString() : null;
      updateValue.salesLabel = item.salesLabel !== null ? item.salesLabel.toString() : "";
    }

    this.setState(updateValue);
  };

  onAddClick = () => {
    this.setState({
      selectedCBT: null,
      selectedCBTQuestion: null,
      editMode: EditModes.TEST_ADD,

      nameInput: "",
      classId: "",
      year: "",
      round: "",
      price: "",
      salesLabel: "",
      minuteLimit: "",
      description: "",
      image: null,
      imageName: "",
    });
  };

  onSelectQuestion = (item) => {
    this.setState({
      selectedCBTQuestion: item,
      editMode: EditModes.QUESTION_MODIFY,
    });
  };

  onAddQuestionClick = () => {
    this.setState({
      selectedCBTQuestion: null,
      editMode: EditModes.QUESTION_ADD,
    });
  };

  onInputChange = (e) => {
    switch (e.target.name) {
      case "showDashboard":
        this.setState({
          [e.target.name]: e.target.checked,
        });
        break;
      default:
        this.setState({
          [e.target.name]: e.target.value,
        });
        break;
    }
  };

  onSubmit = () => {
    const requestSubmit = () => {
      const request =
        this.state.editMode === EditModes.TEST_ADD
          ? Api.addCBTTest(
              this.state.nameInput,
              this.state.classId,
              this.state.year,
              this.state.round,
              this.state.price,
              this.state.minuteLimit,
              this.state.description
            )
          : Api.editCBTTest(
              this.state.selectedCBT.id,
              this.state.nameInput,
              this.state.classId,
              this.state.year,
              this.state.round,
              this.state.price,
              this.state.minuteLimit,
              this.state.visibility,
              this.state.description
            );

      this.setState({ waiting: true });
      request
        .then((response) => {
          const id = response.data.id;
          if (this.state.image) {
            Api.editCBTTestImage(id, this.state.image)
              .then((response) => {
                this.props.actions.getTestList();
                this.setState({ waiting: false });
              })
              .catch((error) => {
                DialogManager.alert("이미지 업로드에 실패했습니다.");
                this.props.actions.getTestList();
                this.setState({ waiting: false });
              });
          } else {
            this.props.actions.getTestList();
            this.setState({ waiting: false });
          }
        })
        .catch((error) => {
          DialogManager.alert(getMessageFromResponse(error.response, `테스트 ${this.state.editMode === EditModes.TEST_ADD ? "추가" : "수정"}에 실패했습니다.`));
          this.setState({ waiting: false });
        });
    };
    if (this.state.editMode === EditModes.TEST_ADD) {
      requestSubmit();
    } else {
      if (this.state.selectedCBT.showDashboard === this.state.showDashboard) {
        requestSubmit();
      } else {
        this.setState({ waiting: true });
        Api.setShowDashboard(Consts.ItemTypes.CBT_TEST, this.state.selectedCBT.urlKey, this.state.showDashboard)
          .then((response) => {
            requestSubmit();
          })
          .catch((error) => {
            alert("대시보드 노출 변경에 실패했습니다.");
            this.setState({ waiting: false });
          });
      }
    }
  };

  onDeleteClick = () => {
    DialogManager.push(SafeDeleteDialog, {
      name: this.state.selectedCBT.name,
      checkUrlKey: this.state.selectedCBT.urlKey,
      onDelete: (dialog) => {
        Api.deleteCBTTest(this.state.selectedCBT.id)
          .then((response) => {
            this.props.actions.getTestList();
            if (dialog.props.onClose) {
              dialog.props.onClose();
            }
          })
          .catch((error) => {
            DialogManager.alert(getMessageFromResponse(error.response, "시험 삭제에 실패했습니다."));
          });
      },
    });
  };

  onClassSelectField = (e, id) => {
    this.setState({
      classId: id,
    });
  };

  onFileChange = (e) => {
    const fileName = e.target.value;
    const file = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
    this.setState({
      image: file,
      imageName: fileName,
    });
  };

  onImageFileSelectorClick = () => {
    this.refs.file.click();
  };

  onPriceSubmit = (e) => {
    e.preventDefault();
    const parameter = {};
    let sale = this.state.sale;
    if (typeof sale === "string") {
      sale = sale === "true";
    }
    parameter.sale = sale;

    if (sale) {
      let price = this.state.price;
      if (price === "") {
        price = 0;
      }
      if (typeof price === "string") {
        price = parseInt(price);
        if (isNaN(price)) {
          DialogManager.alert("판매가가 숫자형식이 아닙니다");
          return;
        }
      }
      parameter.price = price;

      let discountPrice = this.state.discountPrice;
      if (discountPrice === "") {
        discountPrice = null;
      }
      if (typeof discountPrice === "string") {
        discountPrice = parseInt(discountPrice);
        if (isNaN(discountPrice)) {
          DialogManager.alert("할인가가 숫자형식이 아닙니다");
          return;
        }
      }
      parameter.discountPrice = discountPrice;
    }

    const priceEditRequest = () => {
      Api.editCBTPrice(this.state.selectedCBT.urlKey, parameter)
        .then((response) => {
          this.props.actions.getTestList();
        })
        .catch((error) => {
          DialogManager.alert(getMessageFromResponse(error.response, "CBT 가격 수정에 실패했습니다."));
        });
    };
    if (this.state.selectedCBT.salesLabel !== this.state.salesLabel) {
      Api.setSalesLabel(Consts.ItemTypes.CBT_TEST, this.state.selectedCBT.urlKey, this.state.salesLabel)
        .then((response) => {
          priceEditRequest();
        })
        .catch((error) => {
          DialogManager.alert(getMessageFromResponse(error.response, "판매문구 수정에 실패했습니다."));
        });
    } else {
      priceEditRequest();
    }
  };

  renderTestEditor = () => {
    return [
      <Col xs={12} md={12} key={0}>
        <Card>
          <CardHeader>테스트 {this.state.editMode === EditModes.TEST_ADD ? "추가" : "수정"}</CardHeader>
          <CardBody className="editor">
            {this.state.editMode === EditModes.TEST_MODIFY && this.state.selectedCBT ? (
              <div className="data-row">
                <div className="name">id</div>
                <div className="value">{this.state.selectedCBT.id}</div>
              </div>
            ) : undefined}
            {this.state.editMode === EditModes.TEST_MODIFY && this.state.selectedCBT ? (
              <div className="data-row">
                <div className="name">urlKey</div>
                <div className="value user-select-all">{this.state.selectedCBT.urlKey}</div>
              </div>
            ) : undefined}

            <div className="data-row">
              <div className="name">이름</div>
              <div className="value">
                <input type="text" className="full-width" name="nameInput" value={this.state.nameInput} onChange={this.onInputChange} />
              </div>
            </div>

            <div className="data-row">
              <div className="name">시험종목</div>
              <div className="value">
                <ClassSelectField autoLoadDataSource={false} value={this.state.classId} onChange={this.onClassSelectField} />
              </div>
            </div>

            <div className="data-row">
              <div className="name">visibility</div>
              <div className="value">
                <Input type="select" name="visibility" value={this.state.visibility} onChange={this.onInputChange}>
                  <option value="">NONE</option>
                  <option value={Consts.DeckVisibility.PUBLIC}>PUBLIC</option>
                  <option value={Consts.DeckVisibility.PRIVATE}>PRIVATE</option>
                </Input>
              </div>
            </div>

            {this.state.editMode === EditModes.TEST_MODIFY && this.state.selectedCBT ? (
              <div className="data-row">
                <div className="name">대시보드 노출</div>
                <div className="value">
                  <input type="checkbox" name="showDashboard" checked={this.state.showDashboard} onChange={this.onInputChange} />
                </div>
              </div>
            ) : undefined}

            <div className="data-row">
              <div className="name">연도</div>
              <div className="value">
                <input type="text" className="full-width" name="year" value={this.state.year} onChange={this.onInputChange} />
              </div>
            </div>

            <div className="data-row">
              <div className="name">회차</div>
              <div className="value">
                <input type="text" className="full-width" name="round" value={this.state.round} onChange={this.onInputChange} />
              </div>
            </div>

            <div className="data-row">
              <div className="name">cover image</div>
              <div className="value">
                <Button type="button" onClick={this.onImageFileSelectorClick}>
                  파일 선택
                </Button>
                <input ref="file" type="file" onChange={this.onFileChange} className="display-none" accept="image/*" />
                <p>{this.state.imageName}</p>
              </div>
            </div>

            <div className="data-row">
              <div className="name">minuteLimit</div>
              <div className="value">
                <input type="text" className="full-width" name="minuteLimit" value={this.state.minuteLimit} onChange={this.onInputChange} />
              </div>
            </div>
            <div className="data-row">
              <div className="name">설명</div>
              <div className="value">
                <QuillEditor
                  value={this.state.description}
                  onChange={(value) => {
                    this.setState({ description: value });
                  }}
                />
              </div>
            </div>
            {this.state.waiting ? (
              <div className="text-align-center">
                <Loader />
              </div>
            ) : undefined}
            <Button disabled={this.state.waiting} color="primary" block onClick={this.onSubmit}>
              {this.state.editMode === EditModes.TEST_ADD ? "추가" : "수정"}
            </Button>
            {this.state.editMode === EditModes.TEST_MODIFY ? (
              <Button color="danger" onClick={this.onDeleteClick} block>
                삭제
              </Button>
            ) : undefined}
          </CardBody>
        </Card>
        {this.state.editMode === EditModes.TEST_MODIFY && this.props.testDetail ? (
          <HashtagEditor
            hashtag={this.props.testDetail.hashtag}
            itemType={Consts.ItemTypes.CBT_TEST}
            urlKey={this.props.testDetail.urlKey}
            id={this.props.testDetail.id}
          />
        ) : undefined}
        {this.state.editMode === EditModes.TEST_MODIFY &&
        this.state.selectedCBT &&
        this.props.testDetail &&
        this.props.testDetailId === this.state.selectedCBT.id ? (
          <div>
            <TestPageEditor
              test={this.props.testDetail}
              questions={this.state.questions}
              onUpdated={() => {
                this.props.actions.getCBTDetail(this.state.selectedCBT.id);
              }}
            />
            <QuestionCSVBulkInsert
              test={this.props.testDetail}
              onUpdated={() => {
                this.props.actions.getCBTDetail(this.state.selectedCBT.id);
              }}
            />
            <PassGradeEditor
              test={this.props.testDetail}
              onUpdated={() => {
                this.props.actions.getCBTDetail(this.state.selectedCBT.id);
              }}
            />
          </div>
        ) : undefined}
      </Col>,
      <Col xs={12} md={12} key={1}>
        {this.state.editMode === EditModes.TEST_MODIFY ? (
          <PriceEditor
            title="CBT 가격 수정"
            onInputChange={this.onInputChange}
            onPriceSubmit={this.onPriceSubmit}
            sale={this.state.sale}
            price={this.state.price}
            discountPrice={this.state.discountPrice}
            salesLabel={this.state.salesLabel}
          />
        ) : undefined}
      </Col>,
    ];
  };

  renderEditors = () => {
    switch (this.state.editMode) {
      case EditModes.TEST_ADD:
      case EditModes.TEST_MODIFY:
        return this.renderTestEditor();

      case EditModes.QUESTION_ADD:
      case EditModes.QUESTION_MODIFY: {
        if (
          this.state.selectedCBT &&
          (this.state.editMode === EditModes.QUESTION_ADD || (this.state.editMode === EditModes.QUESTION_MODIFY && this.state.selectedCBTQuestion))
        )
          return (
            <QuestionEditor
              test={this.props.testDetail && this.props.testDetail.id === this.state.selectedCBT.id ? this.props.testDetail : this.state.selectedCBT}
              onUpdated={() => {
                this.props.actions.getCBTDetail(this.state.selectedCBT.id);
              }}
              question={this.state.selectedCBTQuestion}
              editMode={this.state.editMode === EditModes.QUESTION_ADD ? Consts.EditModes.ADD : Consts.EditModes.MODIFY}
            />
          );
        return undefined;
      }
    }
  };

  render() {
    const selectedCBT = this.state.selectedCBT;

    return (
      <Row>
        <ListSearch
          dataSource={this.props.testList}
          title="테스트"
          xs={12}
          md={4}
          getAvatar={(item) => item.coverImage}
          getName={(item) => (item.state === TestStates.DELETED ? "(삭제됨) " : "") + item.name}
          onSelect={this.onSelect}
          isSelectedItem={(item) => item === this.state.selectedCBT}
          onAddClick={this.onAddClick}
        />
        {selectedCBT && this.props.testDetail && this.props.testDetail.id === selectedCBT.id ? (
          <ListSearch
            dataSource={this.state.questions.sort((a, b) => a.id - b.id)}
            title="문제"
            xs={12}
            md={8}
            getName={(item, key) => `${item.id}. ${item.content}`}
            onSelect={this.onSelectQuestion}
            isSelectedItem={(item) => item === this.state.selectedCBTQuestion}
            onAddClick={this.onAddQuestionClick}
          />
        ) : undefined}
        {this.renderEditors()}
      </Row>
    );
  }
}

const SelectItemType = {
  CBT: "CBT",
  CBT_QUESTION: "CBT_QUESTION",
};

const styles = {
  testContentP: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
};

const EditModes = {
  TEST_ADD: "TEST_ADD",
  TEST_MODIFY: "TEST_MODIFY",
  QUESTION_ADD: "QUESTION_ADD",
  QUESTION_MODIFY: "QUESTION_MODIFY",
};

const TestStates = {
  NORMAL: "NORMAL",
  DELETED: "DELETED",
};

export default connect(TestEditor.StateToProps, TestEditor.DispatchToProps)(TestEditor);
