import React from 'react';
import PropTypes from 'prop-types';
import Loader from "./Loader";

class LoaderBlock extends React.Component {
    static propTypes = {};

    render() {
        return <div style={styles.container}>
            <div style={styles.wrapper}>
                <Loader/>
            </div>
        </div>;
    }
}

const styles = {
    container: {
        position: 'absolute',
        top: 0, left: 0, right: 0, bottom: 0,
        backgroundColor: 'rgba(255,255,255,0.5)'
    },
    wrapper: {
        textAlign: 'center',
        position: 'absolute',
        top: '50%', left: 0, right: 0,
        transForm: 'translateY(-50%)'
    }
};

export default LoaderBlock;