const routes = {
    '/': 'Home',
    '/notice': '공지 팝업',
    '/dashboard': '대시보드',
    '/api': 'API',
    '/category': '카테고리',
    '/members': '유저 관리',
    '/decks': '덱 관리',
    '/memberships': '멤버쉽 관리',
    '/cbt/subject': 'CBT 종목/과목',
    '/cbt/test': 'CBT 테스트',
    '/coupons': '쿠폰',
    '/coupons/result': '쿠폰 추가 기록',
    '/templates': 'CBT 관리'
};
export default routes;
