/**
 * Created by kimchangduk on 2017-10-20.
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Card, CardBody, CardHeader, Collapse, Input, Table } from "reactstrap";
import { Consts } from "../../constants";
import * as Api from "../../Api";
import { getMessageFromResponse } from "../../utils";
import Loader from "../../views/Loader/Loader";
import DialogManager from "../../dialogs/DialogManager";
import { getDeckDetail } from "../../actions/deckDetail";
import Radium from "radium";

class DeckCardBulkInsert extends React.Component {
  static propTypes = {
    actions: PropTypes.object,
    onAddSuccess: PropTypes.func,
    deck: PropTypes.object,
  };

  static StateToProps = (state, ownProps) => {
    return {};
  };

  static DispatchToProps = (dispatch, ownProps) => {
    return {
      actions: {
        getDeckDetail: () => {
          dispatch(getDeckDetail(ownProps.deck.urlKey));
        },
      },
    };
  };

  constructor(props) {
    super(props);
    this.state = {
      file: null,
      csvToJson: "",
      csvToJsonRequest: "",
      csvToJsonErrorMessage: "",
      imageUri: `https://memorybox.s3.ap-northeast-2.amazonaws.com/images/cardImages/${props.deck.id}`,
      audioUri: `https://memorybox.s3.ap-northeast-2.amazonaws.com/audio/${props.deck.id}`,
      insertRequest: Consts.REQUEST_NONE,
    };
  }

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onFileChange = (e) => {
    const file = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
    this.setState({ file, csvToJsonRequest: Consts.REQUEST_WAITING });
    Api.convertDeckCsvToJson(this.state.imageUri, this.state.audioUri, file)
      .then((response) => {
        this.setState({ csvToJson: response.data, csvToJsonRequest: Consts.REQUEST_SUCCESS });
      })
      .catch((error) => {
        this.setState({ csvToJsonRequest: Consts.REQUEST_FAILURE, csvToJsonErrorMessage: getMessageFromResponse(error.response, "json 변환에 실패했습니다.") });
      });
  };

  onInsertClick = () => {
    this.setState({ insertRequest: Consts.REQUEST_WAITING });
    Api.addCards(this.props.deck.urlKey, this.state.csvToJson)
      .then((response) => {
        this.setState({ insertRequest: Consts.REQUEST_SUCCESS });
        if (this.props.onAddSuccess) {
          this.props.onAddSuccess();
        }
        this.props.actions.getDeckDetail();
      })
      .catch((error) => {
        this.setState({ insertRequest: Consts.REQUEST_FAILURE });
        if (error && error.response && error.response.status === 504) {
          DialogManager.alert(
            <div>
              <p>응답이 오래 걸립니다.</p>
              <p>잠시 후 다시 확인해주세요.</p>
            </div>
          );
        } else {
          const message = getMessageFromResponse(error.response, "");
          DialogManager.alert(
            <div>
              <p>csv로 카드 추가에 실패했습니다.</p>
              <p>{message}</p>
            </div>
          );
        }
      });
  };

  onFileSelectorClick = () => {
    this.refs.fileSelector.click();
  };

  render() {
    return (
      <Card>
        <CardHeader>CSV 카드 추가</CardHeader>
        <CardBody>
          <div style={styles.marginBottom5}>
            <div style={styles.name}>imageUri</div>
            <div style={styles.value}>
              <Input type="text" name="imageUri" value={this.state.imageUri} onChange={this.onInputChange} />
            </div>
          </div>
          <div style={styles.marginBottom5}>
            <div style={styles.name}>audioUri</div>
            <div style={styles.value}>
              <Input type="text" name="audioUri" value={this.state.audioUri} onChange={this.onInputChange} />
            </div>
          </div>
          <div style={styles.marginBottom5}>
            <div style={styles.name}>CSV 파일</div>
            <div style={styles.value}>
              <Button type="button" onClick={this.onFileSelectorClick}>
                파일 선택
              </Button>
              <input ref="fileSelector" type="file" value="" onChange={this.onFileChange} style={styles.hidden} accept=".csv, .CSV" />
            </div>
          </div>
          {this.state.csvToJsonRequest === Consts.REQUEST_WAITING ? (
            <div style={styles.textAlignCenter}>
              <Loader />
            </div>
          ) : undefined}
          {this.state.csvToJsonRequest === Consts.REQUEST_SUCCESS ? (
            <div>
              {this.state.csvToJson ? (
                <div style={[styles.table, styles.marginBottom5]}>
                  <Table>
                    <thead>
                      <tr>
                        <th style={{ minWidth: 140 }}>lesson</th>
                        <th>templateId</th>
                        <th>data</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.csvToJson.map((item, key) => {
                        return (
                          <tr key={key}>
                            <td>{item.lesson}</td>
                            <td>{item.templateId}</td>
                            <td>{JSON.stringify(item.data)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              ) : undefined}
              <div style={styles.marginBottom5}>
                <Button
                  type="submit"
                  size="sm"
                  color="primary"
                  block
                  onClick={this.onInsertClick}
                  disabled={this.state.insertRequest === Consts.REQUEST_WAITING}
                >
                  추가
                </Button>
                {this.state.insertRequest === Consts.REQUEST_WAITING ? (
                  <div style={styles.textAlignCenter}>
                    <Loader />
                  </div>
                ) : undefined}
              </div>
            </div>
          ) : undefined}
          {this.state.csvToJsonRequest === Consts.REQUEST_FAILURE ? <p>{this.state.csvToJsonErrorMessage}</p> : undefined}
        </CardBody>
      </Card>
    );
  }
}

const styles = {
  name: { width: 100, display: "inline-block", textAlign: "right", fontWeight: "bold", paddingRight: 10, verticalAlign: "top", lineHeight: "35px" },
  value: { width: "calc(100% - 100px)", display: "inline-block", paddingLeft: 10, verticalAlign: "top", lineHeight: "35px" },
  marginBottom5: {
    marginBottom: 5,
  },
  textarea: {
    width: "100%",
    lineHeight: "1.2em",
    minHeight: 100,
  },
  textAlignCenter: {
    textAlign: "center",
  },
  hidden: {
    display: "none",
  },
  margin0: {
    margin: 0,
  },
  table: {
    maxHeight: 300,
    overflowY: "auto",
  },
};

export default connect(DeckCardBulkInsert.StateToProps, DeckCardBulkInsert.DispatchToProps)(Radium(DeckCardBulkInsert));
