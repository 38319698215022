/**
 * Created by kimchangduk on 2017-07-23.
 */
import {Consts} from './constants';

export function getLessonFromElement(rootElement, packagePaths, lessonId) {
    let _package = rootElement;
    if (!_package) {
        return null;
    }

    if (packagePaths) {
        for (let i = 0; i < packagePaths.length; i++) {
            _package = _package.packages[packagePaths[i]];
        }
    }

    if (!_package || !_package.lessons) {
        return null;
    }

    return _package.lessons.find(item => item.id === lessonId);
}

export const hasChangedRequestToSuccess = (before, after) => {
    if (typeof (before) === 'object') {
        before = before.request;
    }
    if (typeof(after) === 'object') {
        after = after.request;
    }
    return before === Consts.REQUEST_WAITING && after === Consts.REQUEST_SUCCESS;
};

export const hasChangedRequestToFailure = (before, after) => {
    if (typeof (before) === 'object') {
        before = before.request;
    }
    if (typeof(after) === 'object') {
        after = after.request;
    }
    return before === Consts.REQUEST_WAITING && after === Consts.REQUEST_FAILURE;
};

export function getMessageFromResponse(response, defaultMessage) {
    if (response && response.data && response.data.message !== undefined) {
        return response.data.message;
    }
    return defaultMessage;
}

export function validateEmail(email) {
    if (/[^@]+@[^@]+\.[^@]+/.test(email)) {
        return true;
    }
    return false;
}

export function scrollIntoView(element, parent) {
    if (parent === undefined) {
        parent = element.parentElement;
    }

    // element가 상단에 보일때 스크롤탑
    const topFixedScrollTop = element.offsetTop;
    const bottomFixedScrollTop = topFixedScrollTop - parent.clientHeight + element.clientHeight;

    if (parent.scrollTop > topFixedScrollTop) {
        parent.scrollTop = topFixedScrollTop;
    } else if (parent.scrollTop < bottomFixedScrollTop) {
        parent.scrollTop = bottomFixedScrollTop;
    }
}

//<editor-fold desc="Check browser">

// Opera 8.0+
export function isOpera() {
    return (!!window.opr && !!window.opr.addons) || window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
}

// Firefox 1.0+
export function isFirefox() {
    return typeof window.InstallTrigger !== 'undefined';
}

// Safari 3.0+ "[object HTMLElementConstructor]"
export function isSafari() {
    return /constructor/i.test(window.HTMLElement) || (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
    })(!window['safari'] || (typeof window.safari !== 'undefined' && window.safari.pushNotification));
}

// Internet Explorer 6-11
export function isIE() {
    return /*@cc_on!@*/false || !!document.documentMode;
}

// Edge 20+
export function isEdge() {
    return !isIE() && !!window.StyleMedia;
}

// Chrome 1+
export function isChrome() {
    return !!window.chrome && !isEdge();
}

// Blink engine detection
export function isBlink() {
    return (isChrome() || isOpera()) && !!window.CSS;
}

//</editor-fold>

export function combineStrings(arr, splitter) {
    let result = '';
    for (let i = 0; i < arr.length; i++) {
        if (i > 0) result += splitter;
        result += arr[i];
    }
    return result;
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}