/**
 * Created by kimchangduk on 2017-7-25.
 */

import { ActionTypes, Consts } from "../constants";
import update from "immutability-helper";

const initialState = {
  getList: {
    state: {
      request: Consts.REQUEST_NONE,
      errorMessage: "",
      lastRequestTime: 0,
    },
    dataSource: null,
  },
  get: {
    state: {
      urlKey: "",
      request: Consts.REQUEST_NONE,
      errorMessage: "",
    },
    dataSource: null,
  },
  put: {
    state: {
      request: Consts.REQUEST_NONE,
      errorMessage: "",
    },
    dataSource: null,
  },
  post: {
    state: {
      request: Consts.REQUEST_NONE,
      errorMessage: "",
    },
    dataSource: null,
  },
};

export default function getMembershipsReducer(state = initialState, action) {
  switch (action.type) {
    //<editor-fold desc="Get Membership List">
    case ActionTypes.GET_MEMBERSHIP_LIST_REQUEST:
      return update(state, {
        getList: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            errorMessage: { $set: "" },
            lastRequestTime: { $set: new Date() },
          },
        },
      });

    case ActionTypes.GET_MEMBERSHIP_LIST_SUCCESS: {
      const data = action.data;
      return update(state, {
        getList: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
          },
          dataSource: { $set: data },
        },
      });
    }

    case ActionTypes.GET_MEMBERSHIP_LIST_FAILURE:
      return update(state, {
        getList: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            errorMessage: { $set: action.errorMessage },
          },
        },
      });
    //</editor-fold>

    //<editor-fold desc="Get Membership">
    case ActionTypes.GET_MEMBERSHIP_REQUEST:
      return update(state, {
        get: {
          state: {
            urlKey: { $set: action.urlKey },
            request: { $set: Consts.REQUEST_WAITING },
            errorMessage: { $set: "" },
          },
        },
      });

    case ActionTypes.GET_MEMBERSHIP_SUCCESS: {
      if (state.get.state.urlKey !== action.urlKey) {
        return state;
      }

      const data = action.data;
      return update(state, {
        get: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
          },
          dataSource: { $set: data },
        },
      });
    }

    case ActionTypes.GET_MEMBERSHIP_FAILURE:
      if (state.get.state.urlKey !== action.urlKey) {
        return state;
      }

      return update(state, {
        get: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            errorMessage: { $set: action.errorMessage },
          },
        },
      });
    //</editor-fold>

    //<editor-fold desc="Edit Membership List">
    case ActionTypes.POST_MEMBERSHIP_REQUEST:
      return update(state, {
        post: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            errorMessage: { $set: "" },
          },
        },
      });

    case ActionTypes.POST_MEMBERSHIP_SUCCESS: {
      const data = action.data;
      return update(state, {
        post: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
            errorMessage: { $set: "" },
          },
          dataSource: { $set: data },
        },
      });
    }

    case ActionTypes.POST_MEMBERSHIP_FAILURE:
      return update(state, {
        post: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            errorMessage: { $set: action.errorMessage },
          },
        },
      });
    //</editor-fold>

    //<editor-fold desc="Edit Membership List">
    case ActionTypes.PUT_MEMBERSHIP_REQUEST:
      return update(state, {
        put: {
          state: { request: { $set: Consts.REQUEST_WAITING } },
          errorMessage: { $set: "" },
        },
      });

    case ActionTypes.PUT_MEMBERSHIP_SUCCESS: {
      const data = action.data;
      return update(state, {
        put: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
            errorMessage: { $set: "" },
          },
          dataSource: { $set: data },
        },
      });
    }

    case ActionTypes.PUT_MEMBERSHIP_FAILURE:
      return update(state, {
        put: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            errorMessage: { $set: action.errorMessage },
          },
        },
      });
    //</editor-fold>

    default:
      return state;
  }
}
