/**
 * Created by kimchangduk on 2018-02-07.
 */

import React from "react";
import Radium from "radium";
import { Consts, Strings } from "../../constants";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Input, Table } from "reactstrap";
import * as Api from "../../Api";
import queryString from "query-string";
import Paginator from "../../components/Paginator";
import Loader from "../Loader/Loader";
import RefreshIcon from "@material-ui/icons/Refresh";
import UpIcon from "@material-ui/icons/ArrowDropUp";
import DownIcon from "@material-ui/icons/ArrowDropDown";
import CouponEditor from "../../components/Coupons/CouponEditor";
import DialogManager from "../../dialogs/DialogManager";
import { getMessageFromResponse } from "../../utils";
import CouponAddedResults from "../../components/Coupons/CouponAddedResults";

class Coupons extends React.Component {
  static propTypes = {
    actions: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
  };

  static StateToProps = (state, ownProps) => {
    return {};
  };

  static DispatchToProps = (dispatch, ownProps) => {
    return {
      actions: {},
    };
  };

  state = {
    searchResultVisible: true,
    searchWaiting: false,
    searchResult: null,
    searchStr: "",
    selectedCouponCode: "",
    selectedCoupon: null,
  };

  componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    this.search(params.code, params.page !== null && params.page !== undefined ? params.page - 1 : undefined);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.search !== nextProps.location.search) {
      const params = queryString.parse(nextProps.location.search);
      this.search(params.code, params.page !== undefined && params.page !== null ? params.page - 1 : undefined);
    }
  }

  search = (code = undefined, page = undefined) => {
    this.setState({ searchWaiting: true });
    Api.searchCoupon(code, page)
      .then((response) => {
        const updateParams = { searchWaiting: false, searchResult: response.data };
        if (this.state.selectedCouponCode) {
          const newCoupon = response.data.content.find((a) => a.code === this.state.selectedCouponCode);
          if (newCoupon) updateParams.selectedCoupon = newCoupon;
        }
        this.setState(updateParams);
      })
      .catch((error) => {
        this.setState({ searchWaiting: false, searchResult: null });
      });
  };

  handlePageChange = (newPage) => {
    const { code } = queryString.parse(this.props.location.search);
    const params = {};
    if (code) {
      params.code = code;
    }
    params.page = newPage;
    this.props.history.push(`/coupons?${queryString.stringify(params)}`);
  };

  handleSearchStrChange = (e) => {
    this.setState({ searchStr: e.target.value });
  };

  handleSearchStrKeyDown = (e) => {
    if (e.keyCode === 13) this.props.history.push(`/coupons?code=${this.state.searchStr}`);
  };

  refreshSearchResult = () => {
    let { code, page } = queryString.parse(this.props.location.search);
    if (page !== null && page !== undefined) {
      page--;
    }
    this.search(code, page);
  };

  onListItemClick = (item) => {
    if (this.state.selectedCouponCode === item.code) {
      this.setState({
        selectedCouponCode: "",
        selectedCoupon: null,
      });
    } else {
      this.setState({
        selectedCouponCode: item.code,
        selectedCoupon: item,
        searchWaiting: false,
      });
    }
  };

  render() {
    return (
      <div>
        <div style={{ marginBottom: 10 }}>
          <Input type="text" value={this.state.searchStr} onChange={this.handleSearchStrChange} onKeyDown={this.handleSearchStrKeyDown} placeholder="검색어" />
        </div>

        <div className="position-relative">
          <p
            style={{ margin: 0, cursor: "pointer", userSelect: "none" }}
            onClick={() => {
              this.setState({ searchResultVisible: !this.state.searchResultVisible });
            }}
          >
            <strong className="vertical-middle">쿠폰 리스트</strong>{" "}
            {this.state.searchResultVisible ? <DownIcon className="vertical-middle" /> : <UpIcon className="vertical-middle" />}
          </p>
          {this.state.searchResultVisible ? (
            <div>
              <div style={styles.list}>
                <Table>
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th>Items</th>
                      <th>사용량</th>
                      <th>만기일</th>
                      <th>등록일</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.searchResult
                      ? this.state.searchResult.content.map((item, key) => {
                          return (
                            <tr
                              key={key}
                              style={[styles.searchResultTr, this.state.selectedCouponCode === item.code ? styles.selectedTr : undefined]}
                              onClick={() => {
                                this.onListItemClick(item);
                              }}
                            >
                              <td>{item.code}</td>
                              <td>
                                {item.type.items
                                  ? item.type.items
                                      .filter((a) => a)
                                      .map((a) => a.name)
                                      .join(", ")
                                  : ""}
                              </td>
                              <td>{item.usedQuantity}</td>
                              <td>{item.limitDate !== null && item.limitDate !== undefined ? new Date(item.limitDate).toFormat("YYYY-MM-DD") : ""}</td>
                              <td>{item.registeredDate !== null && item.registeredDate !== undefined ? Strings.buildDateString(item.registeredDate) : ""}</td>
                            </tr>
                          );
                        })
                      : undefined}
                  </tbody>
                </Table>
              </div>
              {this.state.searchResult ? (
                <div style={styles.paginator}>
                  <Paginator
                    totalPages={this.state.searchResult.totalPages}
                    currentPage={this.state.searchResult.number + 1}
                    onChange={this.handlePageChange}
                  />
                  <RefreshIcon style={styles.refreshIcon} onClick={this.refreshSearchResult} />
                </div>
              ) : undefined}
              {this.state.searchWaiting ? (
                <div style={styles.loaderWrapper}>
                  <Loader style={styles.loaderMiddle} />
                </div>
              ) : undefined}
            </div>
          ) : undefined}
        </div>
        <CouponEditor
          style={styles.marginTop10}
          coupon={this.state.selectedCoupon}
          editorType={this.state.selectedCoupon ? Consts.EditorTypes.EDIT : Consts.EditorTypes.NEW}
          onChange={() => {
            this.refreshSearchResult();
          }}
        />
      </div>
    );
  }
}

const styles = {
  list: {
    backgroundColor: "#FFFFFF",
    maxHeight: 500,
    overflowY: "auto",
    position: "relative",
    marginTop: 5,
  },
  paginator: {
    backgroundColor: "white",
    textAlign: "center",
    position: "relative",
  },
  refreshIcon: {
    position: "absolute",
    top: 7,
    right: 5,
    cursor: "pointer",
  },
  loaderWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255,255,255,0.5)",
  },
  loaderMiddle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)",
  },
  searchResultTr: {
    cursor: "pointer",
  },
  selectedTr: {
    backgroundColor: "#BBDEFB",
  },
  marginTop10: {
    marginTop: 10,
  },
};

export default connect(Coupons.StateToProps, Coupons.DispatchToProps)(Radium(Coupons));
