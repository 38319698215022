import React from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {Router, Route, Switch} from 'react-router-dom';
import {appHistoryObject} from './history';
import {Consts} from './constants';
import {store} from './store';
import querystring from 'querystring';

import './App.scss';

// Containers
import Full from './containers/Full/';

// Views
import Login from './views/Pages/Login/';
import Register from './views/Pages/Register/';
import Page403 from "./views/Pages/Page403/";
import Page404 from './views/Pages/Page404/';
import Page500 from './views/Pages/Page500/';

// CSS
import './assets/css/style.css';
import './assets/css/simple-line-icons.css';
import './assets/css/font-awesome.css';

import DialogManager from "./dialogs/DialogManager";


//<editor-fold desc="axios 설정">
axios.defaults.baseURL = Consts.API_URL;
axios.defaults.withCredentials = true;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
axios.defaults.headers.put['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
axios.defaults.headers.patch['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';

axios.defaults.transformRequest = [function (data, config) {
    if (data instanceof FormData) return data;
    if (config && config['Content-Type'] === 'application/json') {
        if (typeof(data) === 'object')
            return JSON.stringify(data);
        return data;
    }
    return querystring.stringify(data);
}];
//</editor-fold>

const history = appHistoryObject;
ReactDOM.render((
    <Provider store={store}>
        <div>
            <Router history={history}>
                <Switch>
                    <Route exact path="/login" name="Login Page" component={Login}/>
                    <Route exact path="/register" name="Register Page" component={Register}/>
                    <Route exact path="/403" name="Page 403" component={Page403}/>
                    <Route exact path="/404" name="Page 404" component={Page404}/>
                    <Route exact path="/500" name="Page 500" component={Page500}/>
                    <Route path="/" name="Home" component={Full}/>
                </Switch>
            </Router>
            <DialogManager/>
        </div>
    </Provider>
), document.getElementById('app'));
