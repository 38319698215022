/**
 * Created by kimchangduk on 2018-03-05.
 */

import React from "react";
import PropTypes from "prop-types";
import { Consts } from "../../constants";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import DeckListEditor from "../MemboxContentsListEditor/DeckListEditor";
import CBTTestListEditor from "../MemboxContentsListEditor/CBTTestListEditor";
import * as Api from "../../Api";
import DialogManager from "../../dialogs/DialogManager";

class DeckBundleEditor extends React.Component {
  static propTypes = {
    deck: PropTypes.object,
    onUpdated: PropTypes.func,
  };

  state = {
    deckIds: "[]",
    testIds: "[]",
    waiting: false,
  };

  componentDidMount() {
    this.initState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.initState(nextProps);
  }

  initState(props) {
    const bundle = props.deck ? props.deck.bundle : null;
    const deckIds = bundle ? bundle.filter((a) => a.type === Consts.ItemTypes.DECK).map((a) => a.id) : [];
    const testIds = bundle ? bundle.filter((a) => a.type === Consts.ItemTypes.CBT_TEST).map((a) => a.id) : [];

    this.setState({
      deckIds: JSON.stringify(deckIds),
      testIds: JSON.stringify(testIds),
    });
  }

  onSubmit = () => {
    let deckIds, testIds;
    try {
      deckIds = JSON.parse(this.state.deckIds);
    } catch (e) {
      DialogManager.alert("Deck Ids값이 잘못됬습니다.");
      return;
    }

    try {
      testIds = JSON.parse(this.state.testIds);
    } catch (e) {
      DialogManager.alert("CBT Ids값이 잘못됬습니다.");
      return;
    }

    this.setState({ waiting: true });
    Api.setDeckBundleItems(this.props.deck.urlKey, deckIds, testIds)
      .then((response) => {
        this.setState({ waiting: false });
        if (this.props.onUpdated) {
          this.props.onUpdated();
        }
      })
      .catch((error) => {
        DialogManager.alert("번들 설정에 실패했습니다.");
        this.setState({ waiting: false });
      });
  };

  render() {
    return (
      <Card>
        <CardHeader>덱 번들 설정</CardHeader>
        <CardBody>
          <div className="margin-bottom-30">
            <strong>Deck</strong>
            <DeckListEditor
              value={this.state.deckIds}
              onChange={(jsonStr) => {
                this.setState({ deckIds: jsonStr });
              }}
            />
          </div>

          <div className="margin-bottom-20">
            <strong>CBT Test</strong>
            <CBTTestListEditor
              value={this.state.testIds}
              onChange={(jsonStr) => {
                this.setState({ testIds: jsonStr });
              }}
            />
          </div>
          <Button disabled={this.state.waiting} onClick={this.onSubmit} color="primary" block>
            수정
          </Button>
        </CardBody>
      </Card>
    );
  }
}

export default DeckBundleEditor;
