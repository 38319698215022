/**
 * Created by kimchangduk on 2017-12-30.
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Card, CardBody, CardHeader, Input } from "reactstrap";
import { Consts } from "../constants";
import { addHashtag, removeHashtag } from "../actions/hashtag";
import HashtagSearch from "./HashtagSearch";
import { hasChangedRequestToFailure, hasChangedRequestToSuccess } from "../utils";
import { getDeckDetail } from "../actions/deckDetail";
import DialogManager from "../dialogs/DialogManager";
import { getCBTDetail } from "../actions/cbt";
import { getMembership } from "../actions/memberships";

class HashtagEditor extends React.Component {
  static propTypes = {
    actions: PropTypes.object,
    hashtag: PropTypes.array,
    itemType: PropTypes.string,
    urlKey: PropTypes.string,
    id: PropTypes.number,
    post: PropTypes.object,
    delete: PropTypes.object,
  };

  static defaultProps = {
    itemType: Consts.ItemTypes.DECK,
  };

  static StateToProps = (state, ownProps) => {
    return {
      post: state.hashtag.post,
      delete: state.hashtag.delete,
    };
  };

  static DispatchToProps = (dispatch, ownProps) => {
    return {
      actions: {
        addHashtag: (tag) => {
          dispatch(addHashtag(ownProps.itemType, ownProps.urlKey, tag));
        },
        removeHashtag: (tag) => {
          dispatch(removeHashtag(ownProps.itemType, ownProps.urlKey, tag));
        },
        getDeckDetail: () => {
          dispatch(getDeckDetail(ownProps.urlKey));
        },
        getCBTDetail: () => {
          dispatch(getCBTDetail(ownProps.id));
        },
        getMembership: () => {
          dispatch(getMembership(ownProps.urlKey));
        },
      },
    };
  };

  state = {
    text: "",
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.post.state.itemType !== this.props.itemType || nextProps.post.state.urlKey !== this.props.urlKey) {
      return;
    }

    if (hasChangedRequestToSuccess(this.props.post.state.request, nextProps.post.state.request)) {
      this.requestDetail();
    }
    if (hasChangedRequestToFailure(this.props.post.state.request, nextProps.post.state.request)) {
      DialogManager.alert(nextProps.post.state.errorMessage);
    }
    if (hasChangedRequestToSuccess(this.props.delete.state.request, nextProps.delete.state.request)) {
      this.requestDetail();
    }
    if (hasChangedRequestToFailure(this.props.delete.state.request, nextProps.delete.state.request)) {
      DialogManager.alert(nextProps.post.state.errorMessage);
    }
  }

  requestDetail() {
    if (this.props.post.state.itemType === Consts.ItemTypes.DECK) {
      this.props.actions.getDeckDetail();
    } else if (this.props.post.state.itemType === Consts.ItemTypes.CBT_TEST) {
      this.props.actions.getCBTDetail();
    } else if (this.props.post.state.itemType === Consts.ItemTypes.MEMBERSHIP) {
      this.props.actions.getMembership();
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.props.actions.addHashtag(this.state.text);
  };

  render() {
    return (
      <Card>
        <CardHeader>태그 추가</CardHeader>
        <CardBody>
          <form onSubmit={this.onSubmit}>
            {this.props.hashtag
              ? this.props.hashtag.map((item, key) => {
                  return (
                    <div key={key} style={styles.hashtag}>
                      {item}{" "}
                      <span
                        style={styles.removeButton}
                        onClick={() => {
                          this.props.actions.removeHashtag(item);
                        }}
                      >
                        삭제
                      </span>
                    </div>
                  );
                })
              : undefined}
            <HashtagSearch
              value={this.state.text}
              onSelect={(item) => {
                this.setState({ text: item.tag });
              }}
              onChange={(v) => {
                this.setState({ text: v });
              }}
            />
            <Button style={styles.button} color="primary" block type="submit">
              추가
            </Button>
          </form>
        </CardBody>
      </Card>
    );
  }
}

const styles = {
  button: {
    marginTop: 10,
  },
  removeButton: {
    color: "#ff1744",
    cursor: "pointer",
  },
  hashtag: {
    display: "inline-block",
    marginTop: 10,
    marginBottom: 10,
    marginRight: 20,
  },
};

export default connect(HashtagEditor.StateToProps, HashtagEditor.DispatchToProps)(HashtagEditor);
