/**
 * Created by kimchangduk on 2017-08-22.
 */

import React from "react";
import { Consts } from "../../constants";
import update from "immutability-helper";
import PropTypes from "prop-types";
import { Button, Card, CardBody, CardHeader, Col, Input } from "reactstrap";
import ClassSelectField from "./ClassSelectField";
import SubjectSelectField from "./SubjectSelectField";
import DialogManager from "../../dialogs/DialogManager";
import * as Api from "../../Api";
import { getMessageFromResponse } from "../../utils";

class QuestionEditor extends React.Component {
  static propTypes = {
    onUpdated: PropTypes.func,
    style: PropTypes.object,
    editMode: PropTypes.string,
    test: PropTypes.object,
    question: PropTypes.object,
  };

  state = {
    id: "",

    classId: "",
    subjectId: "",

    content: "",
    fingerprint: "",

    example1: "",
    example2: "",
    example3: "",
    example4: "",

    answerId: "1",
    solution: "",
    advice: "",
    joinedStudying: "",
  };

  componentDidMount() {
    this.initializeStateWithCBT(this.props.question, this.props.test);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.question !== nextProps.question && nextProps.editMode === Consts.EditModes.MODIFY) {
      this.initializeStateWithCBT(nextProps.question, nextProps.test);
    } else if (this.props.editMode !== nextProps.editMode) {
      if (nextProps.editMode === Consts.EditModes.ADD) {
        this.initializeStateWithCBT(null, null);
      } else {
        this.initializeStateWithCBT(nextProps.question, nextProps.test);
      }
    }
  }

  initializeStateWithCBT(item, test) {
    const updateValue = {
      classId: "",
      subjectId: "",

      content: "",
      fingerprint: "",

      example1: "",
      example2: "",
      example3: "",
      example4: "",

      answerId: "",
      answerIds: "",
      solution: "",
      advice: "",
      joinedStudying: "",
    };
    if (item) {
      if (test && test.classSubjects) {
        const classSubject = test.classSubjects.find((a) => a.id === item.classSubjectId);
        if (classSubject) {
          updateValue.classId = classSubject.clazz ? classSubject.clazz.id.toString() : "0";
          updateValue.subjectId = classSubject.subject ? classSubject.subject.id.toString() : "0";
        }
      }

      updateValue.content = item.content ? item.content : "";
      updateValue.fingerprint = item.fingerprint ? item.fingerprint : "";

      const example = item.example;
      updateValue.example1 = example && example[0] ? item.example[0].content : "";
      updateValue.example2 = example && example[1] ? item.example[1].content : "";
      updateValue.example3 = example && example[2] ? item.example[2].content : "";
      updateValue.example4 = example && example[3] ? item.example[3].content : "";

      const answerIndex = example ? example.findIndex((a) => a.id === item.answerId) : null;
      updateValue.answerId = answerIndex >= 0 ? (answerIndex + 1).toString() : "";
      updateValue.answerIds = item.answerIds ? JSON.stringify(item.answerIds) : "";

      updateValue.solution = item.solution ? item.solution : "";
      updateValue.advice = item.advice ? item.advice : "";
      updateValue.joinedStudying = item.joinedStudying ? item.joinedStudying : "";
    }
    this.setState(updateValue);
  }

  onInputChange = (e) => {
    let newValue = e.target.value;
    this.setState({
      [e.target.name]: newValue,
    });
  };

  onSelectInputChange = (e, newId) => {
    this.setState({
      [e.target.name]: newId,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const parameter = {};

    try {
      parameter.classId = parseInt(this.state.classId);
    } catch (error) {
      DialogManager.alert("시험종목이 숫자 타입이 아닙니다");
      return;
    }

    try {
      parameter.subjectId = parseInt(this.state.subjectId);
    } catch (error) {
      DialogManager.alert("과목이 숫자 타입이 아닙니다");
      return;
    }

    parameter.content = this.state.content;
    parameter.fingerprint = this.state.fingerprint;

    if (!this.state.answerId && !this.state.answerIds) {
      DialogManager.alert("정답을 선택하셔야 합니다.");
      return;
    }
    if (this.state.answerId) {
      parameter.answerId = parseInt(this.state.answerId);
    }
    if (this.state.answerIds) {
      parameter.answerIds = this.state.answerIds;
    }

    parameter.solution = this.state.solution;
    parameter.advice = this.state.advice;
    parameter.joinedStudying = this.state.joinedStudying;

    parameter.exampleJson = JSON.stringify([
      { id: 1, content: this.state.example1 },
      { id: 2, content: this.state.example2 },
      { id: 3, content: this.state.example3 },
      { id: 4, content: this.state.example4 },
    ]);

    const api =
      this.props.editMode === Consts.EditModes.ADD
        ? Api.addCBTQuestion(this.props.test.id, parameter)
        : Api.editCBTQuestion(this.props.test.id, this.props.question.id, parameter);

    api
      .then(() => {
        if (this.props.onUpdated) {
          this.props.onUpdated();
        }
      })
      .catch((error) => {
        DialogManager.alert(getMessageFromResponse(error.response, `문제 ${this.props.editMode === Consts.EditModes.ADD ? "추가" : "수정"}에 실패했습니다.`));
      });
  };

  onDeleteClick = () => {
    DialogManager.confirm("정말로 삭제 하시겠습니까?", () => {
      Api.deleteCBTQuestion(this.props.test.id, this.props.question.id)
        .then((response) => {
          if (this.props.onUpdated) this.props.onUpdated();
        })
        .catch((error) => {
          DialogManager.alert(getMessageFromResponse(error.response, `문제 삭제에 실패했습니다.`));
        });
    });
  };

  render() {
    return (
      <Col md={12} style={this.props.style}>
        <Card>
          <CardHeader>문제 {this.props.editMode === Consts.EditModes.ADD ? "추가" : "수정"}</CardHeader>
          <CardBody className="editor">
            <form onSubmit={this.onSubmit}>
              {this.props.editMode === Consts.EditModes.MODIFY && this.props.question ? (
                <div className="data-row">
                  <div className="name">id</div>
                  <div className="value">{this.props.question.id}</div>
                </div>
              ) : undefined}

              <div className="data-row">
                <div className="name">시험종목</div>
                <div className="value">
                  <ClassSelectField name="classId" onChange={this.onSelectInputChange} value={this.state.classId} autoLoadDataSource={true} />
                </div>
              </div>
              <div className="data-row">
                <div className="name">과목</div>
                <div className="value">
                  <SubjectSelectField name="subjectId" onChange={this.onSelectInputChange} value={this.state.subjectId} autoLoadDataSource={true} />
                </div>
              </div>

              <div className="data-row">
                <div className="name">content</div>
                <div className="value">
                  <Input type="text" name="content" value={this.state.content} onChange={this.onInputChange} />
                </div>
              </div>

              <div className="data-row">
                <div className="name">지문</div>
                <div className="value">
                  <Input type="text" name="fingerprint" value={this.state.fingerprint} onChange={this.onInputChange} />
                </div>
              </div>

              <div className="data-row">
                <div className="name">보기1</div>
                <div className="value">
                  <Input type="text" name="example1" value={this.state.example1} onChange={this.onInputChange} />
                </div>
              </div>

              <div className="data-row">
                <div className="name">보기2</div>
                <div className="value">
                  <Input type="text" name="example2" value={this.state.example2} onChange={this.onInputChange} />
                </div>
              </div>

              <div className="data-row">
                <div className="name">보기3</div>
                <div className="value">
                  <Input type="text" name="example3" value={this.state.example3} onChange={this.onInputChange} />
                </div>
              </div>

              <div className="data-row">
                <div className="name">보기4</div>
                <div className="value">
                  <Input type="text" name="example4" value={this.state.example4} onChange={this.onInputChange} />
                </div>
              </div>

              <div className="data-row">
                <div className="name">정답</div>
                <div className="value">
                  <Input type="select" value={this.state.answerId} onChange={this.onInputChange} name="answerId">
                    <option>없음</option>
                    <option value="1">보기 1</option>
                    <option value="2">보기 2</option>
                    <option value="3">보기 3</option>
                    <option value="4">보기 4</option>
                  </Input>
                </div>
              </div>

              <div className="data-row">
                <div className="name">복수 정답</div>
                <div className="value">
                  <Input type="textarea" value={this.state.answerIds} onChange={this.onInputChange} name="answerIds" />
                </div>
              </div>

              <div className="data-row">
                <div className="name">solution</div>
                <div className="value">
                  <Input type="textarea" name="solution" value={this.state.solution} onChange={this.onInputChange} />
                </div>
              </div>

              <div className="data-row">
                <div className="name">advice</div>
                <div className="value">
                  <Input type="textarea" name="advice" value={this.state.advice} onChange={this.onInputChange} />
                </div>
              </div>

              <div className="data-row">
                <div className="name">joinedStudying</div>
                <div className="value">
                  <Input type="textarea" name="joinedStudying" value={this.state.joinedStudying} onChange={this.onInputChange} />
                </div>
              </div>

              <Button type="submit" color="primary" block>
                {this.props.editMode === Consts.EditModes.ADD ? "추가" : "수정"}
              </Button>
              {this.props.editMode === Consts.EditModes.MODIFY ? (
                <Button onClick={this.onDeleteClick} color="danger" block>
                  삭제
                </Button>
              ) : undefined}
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default QuestionEditor;
