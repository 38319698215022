/* eslint-disable react/prop-types,react/no-multi-comp */

import React from "react";
import { createUltimatePagination, ITEM_TYPES } from "react-ultimate-pagination";
import { Button as FlatButton } from "@material-ui/core";
import NavigationFirstPage from "@material-ui/icons/FirstPage";
import NavigationLastPage from "@material-ui/icons/LastPage";
import NavigationChevronLeft from "@material-ui/icons/ChevronLeft";
import NavigationChevronRight from "@material-ui/icons/ChevronRight";

const flatButtonStyle = {
  minWidth: 36,
  fontWeight: "bold",
  color: "rgba(0, 0, 0, 0.87)",
};

const activeFlatButtonStyle = {
  minWidth: 36,
  fontWeight: "bold",
  color: "rgb(0, 188, 212)",
};

const Page = ({ value, isActive, onClick }) => (
  <FlatButton style={isActive ? activeFlatButtonStyle : flatButtonStyle} onClick={onClick}>
    {value.toString()}
  </FlatButton>
);

const Ellipsis = ({ onClick }) => (
  <FlatButton style={flatButtonStyle} onClick={onClick}>
    ...
  </FlatButton>
);

const FirstPageLink = ({ isActive, onClick }) => (
  <FlatButton style={flatButtonStyle} onClick={onClick}>
    <NavigationFirstPage />
  </FlatButton>
);

const PreviousPageLink = ({ isActive, onClick }) => (
  <FlatButton style={flatButtonStyle} onClick={onClick}>
    <NavigationChevronLeft />
  </FlatButton>
);

const NextPageLink = ({ isActive, onClick }) => (
  <FlatButton style={flatButtonStyle} onClick={onClick}>
    <NavigationChevronRight />
  </FlatButton>
);

const LastPageLink = ({ isActive, onClick }) => (
  <FlatButton style={flatButtonStyle} onClick={onClick}>
    <NavigationLastPage />
  </FlatButton>
);

const itemTypeToComponent = {
  [ITEM_TYPES.PAGE]: Page,
  [ITEM_TYPES.ELLIPSIS]: Ellipsis,
  [ITEM_TYPES.FIRST_PAGE_LINK]: FirstPageLink,
  [ITEM_TYPES.PREVIOUS_PAGE_LINK]: PreviousPageLink,
  [ITEM_TYPES.NEXT_PAGE_LINK]: NextPageLink,
  [ITEM_TYPES.LAST_PAGE_LINK]: LastPageLink,
};

const UltimatePaginationMaterialUi = createUltimatePagination({ itemTypeToComponent });

export default UltimatePaginationMaterialUi;
