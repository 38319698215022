/**
 * Created by kimchangduk on 2017-08-13.
 */

import React from "react";
import update from "immutability-helper";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import { getDeckList } from "../../actions/deckList";
import Loader from "../Loader/Loader";
import { Consts } from "../../constants";
import DialogManager from "../../dialogs/DialogManager";
import ListSearch from "../../components/ListSearch/ListSearch";
import DeckEditor from "../../components/DeckEditor/DeckEditor";
import { getDeckDetail } from "../../actions/deckDetail";
import { getDeckCards } from "../../actions/deckCards";
import PackageEditor from "../../components/PackageEditor/PackageEditor";
import AddPackageOrLessonDialog from "../../dialogs/AddPackageOrLessonDialog";
import LessonEditor from "../../components/LessonEditor/LessonEditor";
import CardEditor from "../../components/CardEditor/CardEditor";
import { getMessageFromResponse } from "../../utils";
import DeckCardBulkInsert from "../../components/DeckEditor/DeckCardBulkInsert";
import { getTemplates } from "../../actions/template";
import * as Api from "../../Api";
import Move from "../../components/DeckEditor/Move";
import { Resizable } from "re-resizable";

class Decks extends React.Component {
  static propTypes = {
    actions: PropTypes.object,

    deckList: PropTypes.array,
    deckListRequest: PropTypes.string,
    deckListErrorMessage: PropTypes.string,

    deckDetailRequest: PropTypes.string,
    deckDetailErrorMessage: PropTypes.string,
    deckDetail: PropTypes.object,

    deckCardsRequest: PropTypes.string,
    deckCards: PropTypes.array,
  };

  static StateToProps = (state, ownProps) => {
    return {
      deckList: state.deckList.dataSource,
      deckListRequest: state.deckList.state.request,
      deckListErrorMessage: state.deckList.state.errorMessage,

      deckDetailRequest: state.deckDetail.state.request,
      deckDetailErrorMessage: state.deckDetail.state.errorMessage,
      deckDetail: state.deckDetail.dataSource,

      deckCardsRequest: state.deckCards.state.request,
      deckCards: state.deckCards.dataSource,
    };
  };

  static DispatchToProps = (dispatch, ownProps) => {
    return {
      actions: {
        getDeckList: () => {
          dispatch(getDeckList());
        },
        getDeckDetail: (urlKey) => {
          dispatch(getDeckDetail(urlKey));
        },
        getDeckCards: (urlKey, lessonId) => {
          dispatch(getDeckCards(urlKey, lessonId));
        },
        getTemplates: () => {
          dispatch(getTemplates());
        },
      },
    };
  };

  state = {
    selectedDeckId: -1,
    selectedPackages: [],
    selectedLessonId: null,
    selectedCardId: null,
    editType: EditTypes.NONE,
  };

  componentDidMount() {
    this.props.actions.getDeckList();
    this.props.actions.getTemplates();
  }

  getSelectedDeck() {
    if (this.props.deckList) {
      const deck = this.props.deckList.find((item) => item.id === this.state.selectedDeckId);
      return deck;
    }
    return null;
  }

  getSelectedDeckInfo = () => {
    let selectedDeck = this.getSelectedDeck();
    if (selectedDeck && this.props.deckDetail && selectedDeck.urlKey === this.props.deckDetail.urlKey) {
      selectedDeck = this.props.deckDetail;
    } else {
      selectedDeck = null;
    }
    return selectedDeck;
  };

  getPackageOfDepth = (depth, rootElement) => {
    const selectedDeck = this.getSelectedDeckInfo();
    if (!selectedDeck) {
      return undefined;
    }

    let _package = rootElement === undefined ? selectedDeck.element : rootElement;
    for (let i = 0; i < depth; i++) {
      _package = _package.packages[this.state.selectedPackages[i]];
    }
    return _package;
  };

  loadLessonCards = (lessonId) => {
    const deck = this.getSelectedDeck();
    this.props.actions.getDeckCards(deck.urlKey, lessonId);
  };

  onDeckSelect = (item) => {
    this.setState({
      selectedDeckId: item.id,
      editType: EditTypes.DECK_EDIT,
      selectedPackages: [],
      selectedLessonId: null,
      selectedCardId: null,
    });
    this.props.actions.getDeckDetail(item.urlKey);
  };

  onDeckAddClick = () => {
    this.setState({ editType: EditTypes.DECK_NEW });
  };

  onPackageOrLessonClick = (item, key, depth) => {
    if (item.type === PackageOrLesson.PACKAGE) {
      const subArray = this.state.selectedPackages.slice(0, depth);
      subArray.push(key);
      this.setState({
        selectedPackages: subArray,
        selectedLessonId: null,
        selectedCardId: null,
        editType: EditTypes.PACKAGE_EDIT,
      });
    } else {
      const subArray = this.state.selectedPackages.slice(0, depth);
      this.setState({
        selectedPackages: subArray,
        selectedLessonId: item.data.id,
        selectedCardId: null,
        editType: EditTypes.LESSON_EDIT,
      });
      this.loadLessonCards(item.data.id);
    }
  };

  renderElement = (element, depth, key) => {
    let menus = [];
    if (element) {
      if (element.packages) {
        for (const _package of element.packages) {
          menus.push({ type: PackageOrLesson.PACKAGE, data: _package });
        }
      }
      if (element.lessons) {
        for (const lesson of element.lessons) {
          menus.push({ type: PackageOrLesson.LESSON, data: lesson });
        }
      }
    }

    return (
      <div style={styles.resizableWrapper} key={key}>
        <Resizable defaultSize={styles.resizableSize}>
          <ListSearch
            wrapWithColumn={false}
            columnStyle={styles.listSearchColumn}
            title="패키지 & 레슨"
            dataSource={menus}
            reorderable={true}
            getName={(item, key) =>
              `(${item.type === PackageOrLesson.PACKAGE ? "패키지" : "레슨"}) ${item.type === PackageOrLesson.LESSON ? `id:${item.data.id}, ` : ""}${
                item.data.name
              }`
            }
            isSelectedItem={(item, key) => {
              if (item.type === PackageOrLesson.PACKAGE) {
                return key === this.state.selectedPackages[depth];
              } else {
                if (this.state.selectedPackages.length === depth) return item.data.id === this.state.selectedLessonId;
              }
              return false;
            }}
            onSelect={(item, key) => {
              this.onPackageOrLessonClick(item, key, depth);
            }}
            onReorderRequest={(newData) => {
              const selectedDeck = this.getSelectedDeckInfo();
              if (!selectedDeck) {
                return;
              }

              const rootElement = update(selectedDeck.element, {});
              let parentElement = this.getPackageOfDepth(this.state.selectedPackages.length, rootElement);
              if (!newData) {
                newData = [];
              }

              parentElement.packages = newData.filter((a) => a.type == PackageOrLesson.PACKAGE).map((a) => a.data);
              parentElement.lessons = newData.filter((a) => a.type == PackageOrLesson.LESSON).map((a) => a.data);

              Api.editDeckElement(selectedDeck.urlKey, rootElement)
                .then((response) => {
                  this.props.actions.getDeckDetail(selectedDeck.urlKey);
                })
                .catch((error) => {
                  DialogManager.alert(getMessageFromResponse(error.response, "순서수정에 실패했습니다."));
                });
            }}
            onAddClick={() => {
              const subArray = this.state.selectedPackages.slice(0, depth);
              DialogManager.push(AddPackageOrLessonDialog, {
                onSelectPackage: () => {
                  this.setState({
                    editType: EditTypes.PACKAGE_NEW,
                    selectedPackages: subArray,
                    selectedLessonId: null,
                    selectedCardId: null,
                  });
                },
                onSelectLesson: () => {
                  this.setState({
                    editType: EditTypes.LESSON_NEW,
                    selectedPackages: subArray,
                    selectedLessonId: null,
                    selectedCardId: null,
                  });
                },
              });
            }}
          />
          ;
        </Resizable>
      </div>
    );
  };

  renderCards = () => {
    if (this.props.deckCardsRequest === Consts.REQUEST_WAITING) {
      return (
        <span style={styles.cardLoader}>
          <Loader />
        </span>
      );
    }

    return (
      <div style={styles.resizableWrapper}>
        <Resizable defaultSize={styles.resizableSize}>
          <ListSearch
            wrapWithColumn={false}
            columnStyle={styles.listSearchColumn}
            title="카드 목록"
            dataSource={this.props.deckCards ? this.props.deckCards : []}
            getName={(item) => {
              let previewText = "";
              if (item.data && item.data.front) {
                previewText = item.data.front;
              } else if (item.data && item.data.question) {
                previewText = item.data.question;
              } else {
                previewText = JSON.stringify(item.data);
              }
              return `(${item.id}) ${previewText}`;
            }}
            onSelect={(item) => {
              this.setState({ selectedCardId: item.id, editType: EditTypes.CARD_EDIT });
            }}
            onAddClick={(item) => {
              this.setState({ editType: EditTypes.CARD_NEW });
            }}
            isSelectedItem={(item) => item.id === this.state.selectedCardId}
          />
        </Resizable>
      </div>
    );
  };

  renderEditComponent() {
    let component = undefined;
    const selectedDeck = this.getSelectedDeckInfo();

    switch (this.state.editType) {
      case EditTypes.DECK_NEW:
        component = <DeckEditor editorType={Consts.EditorTypes.NEW} />;
        break;

      case EditTypes.DECK_EDIT: {
        const deck = this.getSelectedDeck();
        if (deck) {
          component = (
            <div>
              <DeckEditor editorType={Consts.EditorTypes.EDIT} deck={deck} deckInfo={this.getSelectedDeckInfo()} />
              <DeckCardBulkInsert deck={deck} />
            </div>
          );
        }
        break;
      }

      case EditTypes.PACKAGE_EDIT: {
        if (selectedDeck) {
          component = (
            <div>
              <PackageEditor
                urlKey={selectedDeck.urlKey}
                editorType={Consts.EditorTypes.EDIT}
                package={this.getPackageOfDepth(this.state.selectedPackages.length)}
                element={selectedDeck.element}
                packagePathIndexs={this.state.selectedPackages}
                onDelete={() => {
                  let newSelectedPackages = update(this.state.selectedPackages, {});
                  if (newSelectedPackages && newSelectedPackages.length > 0) {
                    newSelectedPackages = newSelectedPackages.slice(0, newSelectedPackages.length - 1);
                  }
                  this.setState({ selectedPackages: newSelectedPackages });
                }}
              />
              <Move
                selected={this.state.selectedPackages}
                lessonId={this.state.selectedLessonId}
                deck={this.getSelectedDeckInfo()}
                onUpdate={(targetItemPath) => {
                  this.setState({
                    editType: EditTypes.PACKAGE_EDIT,
                    selectedPackages: targetItemPath,
                    selectedLessonId: null,
                    selectedCardId: null,
                  });
                  this.props.actions.getDeckDetail(this.getSelectedDeck().urlKey);
                }}
              />
            </div>
          );
        }
        break;
      }

      case EditTypes.PACKAGE_NEW: {
        if (selectedDeck) {
          component = (
            <PackageEditor
              urlKey={selectedDeck.urlKey}
              editorType={Consts.EditorTypes.NEW}
              element={selectedDeck.element}
              packagePathIndexs={this.state.selectedPackages}
            />
          );
        }
        break;
      }

      case EditTypes.LESSON_EDIT: {
        if (selectedDeck) {
          const _package = this.getPackageOfDepth(this.state.selectedPackages.length);
          const lesson = _package.lessons ? _package.lessons.find((item) => item.id === this.state.selectedLessonId) : null;
          component = (
            <div>
              <LessonEditor
                urlKey={selectedDeck.urlKey}
                editorType={Consts.EditorTypes.EDIT}
                lesson={lesson}
                element={selectedDeck.element}
                packagePathIndexs={this.state.selectedPackages}
                onDelete={() => {
                  this.setState({ selectedLessonId: null, lessonCards: null });
                }}
              />
              <Move
                selected={this.state.selectedPackages}
                lessonId={this.state.selectedLessonId}
                deck={this.getSelectedDeckInfo()}
                onUpdate={(targetItemPath) => {
                  this.setState({
                    editType: EditTypes.PACKAGE_EDIT,
                    selectedPackages: targetItemPath,
                    selectedLessonId: null,
                    selectedCardId: null,
                  });
                  this.props.actions.getDeckDetail(this.getSelectedDeck().urlKey);
                }}
              />
            </div>
          );
        }
        break;
      }

      case EditTypes.LESSON_NEW: {
        if (selectedDeck) {
          component = (
            <LessonEditor
              urlKey={selectedDeck.urlKey}
              editorType={Consts.EditorTypes.NEW}
              element={selectedDeck.element}
              packagePathIndexs={this.state.selectedPackages}
            />
          );
        }
        break;
      }

      case EditTypes.CARD_NEW: {
        if (selectedDeck) {
          component = (
            <CardEditor
              deck={selectedDeck}
              packagePathIndexs={this.state.selectedPackages}
              lessonId={this.state.selectedLessonId}
              editorType={Consts.EditorTypes.NEW}
              deckUrlKey={selectedDeck.urlKey}
            />
          );
        }
        break;
      }

      case EditTypes.CARD_EDIT: {
        if (selectedDeck && this.props.deckCards) {
          const selectedCard = this.props.deckCards.find((item) => item.id === this.state.selectedCardId);
          component = (
            <CardEditor
              editorType={Consts.EditorTypes.EDIT}
              lessonId={this.state.selectedLessonId}
              card={selectedCard}
              deckUrlKey={selectedDeck.urlKey}
              onDeleteCard={() => {
                this.setState({ selectedCardId: null, editType: EditTypes.LESSON_EDIT });
              }}
            />
          );
        }
        break;
      }
    }
    return component;
  }

  render() {
    let component = this.renderEditComponent();
    const selectedDeck = this.getSelectedDeckInfo();

    return (
      <div className="animated fadeIn">
        {this.props.deckListRequest === Consts.REQUEST_WAITING ? <Loader /> : undefined}
        {this.props.deckList && this.props.deckList ? (
          <Row style={styles.deckRow}>
            <div style={styles.resizableWrapper}>
              <Resizable defaultSize={styles.resizableSize}>
                <ListSearch
                  wrapWithColumn={false}
                  columnStyle={styles.listSearchColumn}
                  title="덱"
                  isSelectedItem={(v) => this.state.selectedDeckId === v.id}
                  onSelect={this.onDeckSelect}
                  dataSource={this.props.deckList}
                  onAddClick={this.onDeckAddClick}
                  getName={(a) => (a.state === DeckStates.DELETED ? "(삭제됨)" : "") + a.name}
                  getAvatar={(a) => a.coverImage}
                />
              </Resizable>
            </div>
            {selectedDeck ? this.renderElement(selectedDeck.element, 0) : undefined}
            {selectedDeck && selectedDeck.element
              ? this.state.selectedPackages.map((id, key) => {
                  const _package = this.getPackageOfDepth(key + 1);
                  return this.renderElement(_package, key + 1, key);
                })
              : undefined}
            {selectedDeck && selectedDeck.element && this.state.selectedLessonId !== null ? this.renderCards() : undefined}
          </Row>
        ) : undefined}
        <Row>
          <div style={styles.width100}>{component}</div>
        </Row>
      </div>
    );
  }
}

const EditTypes = {
  NONE: "NONE",
  DECK_EDIT: "DECK_EDIT",
  DECK_NEW: "DECK_NEW",
  PACKAGE_EDIT: "PACKAGE_EDIT",
  PACKAGE_NEW: "PACKAGE_NEW",
  LESSON_EDIT: "LESSON_EDIT",
  LESSON_NEW: "LESSON_NEW",
  CARD_EDIT: "CARD_EDIT",
  CARD_NEW: "CARD_NEW",
};

const DeckStates = {
  NORMAL: "NORMAL",
  DELETED: "DELETED",
};

const PackageOrLesson = {
  PACKAGE: "PACKAGE",
  LESSON: "LESSON",
};

const styles = {
  deckRow: { height: 406, display: "block", whiteSpace: "nowrap", overflowX: "auto", overflowY: "hidden" },
  width100: { width: "100%" },
  cardLoader: { verticalAlign: "top" },
  listSearchColumn: {
    maxWidth: 250,
  },
  resizableWrapper: {
    display: "inline-block",
    paddingRight: 20,
    position: "relative",
    verticalAlign: "top",
  },
  resizableSize: {
    width: 220,
    height: "auto",
  },
};

export default connect(Decks.StateToProps, Decks.DispatchToProps)(Decks);
