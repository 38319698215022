/**
 * Created by kimchangduk on 2018-02-08.
 */

import React from "react";
import Radium from "radium";
import PropTypes from "prop-types";
import { Consts, Strings } from "../../constants";
import { Card, CardBody, CardHeader, Button, ButtonGroup, Input, Table } from "reactstrap";
import * as Api from "../../Api";
import LoaderBlock from "../../views/Loader/LoaderBlock";
import { getMessageFromResponse } from "../../utils";
import DialogManager from "../../dialogs/DialogManager";
import Snackbar from "@material-ui/core/Snackbar";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Cancel";
import update from "immutability-helper";
import ContentsAutoCompleteInput from "../AutoCompletes/ContentsAutoCompleteInput";

class CouponEditor extends React.Component {
  static propTypes = {
    style: PropTypes.object,
    coupon: PropTypes.object,
    editorType: PropTypes.oneOf([Consts.EditorTypes.NEW, Consts.EditorTypes.EDIT]),
    onChange: PropTypes.func,
  };

  static defaultProps = {
    editorType: Consts.EditorTypes.NEW,
  };

  state = Object.assign(
    {
      waiting: false,
      snackbarOpen: false,
      snackbarMessage: "",
    },
    defaultState
  );

  componentDidMount() {
    this.setInputStates(this.props.editorType, this.props.coupon);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.editorType !== nextProps.editorType || this.props.coupon !== nextProps.coupon) {
      this.setInputStates(nextProps.editorType, nextProps.coupon);
    }
  }

  setInputStates(editorType, coupon) {
    if (editorType === Consts.EditorTypes.EDIT && coupon) {
      this.setState(this.convertCouponDataToStateObject(coupon));
    } else {
      this.setState(defaultState);
    }
  }

  convertCouponDataToStateObject = (coupon) => {
    return {
      code: coupon.code ? coupon.code : "",
      codes: "",
      items:
        coupon.type && coupon.type.items
          ? coupon.type.items
              .filter((a) => a)
              .map((a) => {
                return { type: a.type, name: a.name, id: a.id };
              })
          : [],
      publisherType: coupon.publisher,
      quantity: coupon.quantity ? coupon.quantity : 0,
      limitDate: coupon.limitDate ? new Date(coupon.limitDate).toFormat("YYYY-MM-DD") : "",
      comment: coupon.comment ? coupon.comment : "",
      insertMultiple: false,
    };
  };

  openSnackbar = (message) => {
    this.setState({
      snackbarOpen: true,
      snackbarMessage: message,
    });
  };

  onInputChange = (e) => {
    let newValue = e.target.value;
    if (e.target.type === "number") {
      newValue = parseInt(newValue);
      newValue = newValue ? newValue : 0;
    }

    this.setState({
      [e.target.name]: newValue,
    });
  };

  onSubmit = () => {
    const currentState = this.state;
    if (!currentState.items || currentState.items.length <= 0) {
      DialogManager.alert(`Deck, CBT, Membership 모두 설정된 값이 없습니다.`);
      return;
    }

    const currentDeckIds = currentState.items.filter((a) => a.type === Consts.ItemTypes.DECK).map((a) => a.id);
    const currentTestIds = currentState.items.filter((a) => a.type === Consts.ItemTypes.CBT_TEST).map((a) => a.id);
    const currentMembershipId = currentState.items.filter((a) => a.type === Consts.ItemTypes.MEMBERSHIP).map((a) => a.id);

    let params;

    if (this.props.editorType === Consts.EditorTypes.EDIT) {
      const originalData = this.convertCouponDataToStateObject(this.props.coupon);
      params = { code: originalData.code };

      if (currentDeckIds.length > 0) {
        params.deckIds = currentDeckIds;
      }

      if (currentTestIds.length > 0) {
        params.testIds = currentTestIds;
      }
      if (currentMembershipId.length > 0) {
        if (currentMembershipId.length > 1) {
          DialogManager.alert(MULTIPLE_MEMBERSHIP_ERROR);
          return;
        }
        params.membershipId = currentMembershipId[0];
      }

      params.publisherType = currentState.publisherType;
      params.quantity = currentState.quantity;

      if (currentState.limitDate !== originalData.limitDate) {
        params.limitDate = currentState.limitDate;
      }

      if (currentState.comment !== originalData.comment) {
        params.comment = currentState.comment;
      }

      this.setState({ waiting: true });
      Api.editCoupon(params)
        .then((response) => {
          if (this.props.onChange) {
            this.props.onChange(response.data);
          }
          this.openSnackbar("수정 성공");
          this.setState({ waiting: false });
        })
        .catch((error) => {
          DialogManager.alert(getMessageFromResponse(error.response), "쿠폰 수정에 실패했습니다.");
          this.setState({ waiting: false });
        });
    } else {
      const params = {};
      if (!this.state.insertMultiple) {
        params.code = this.state.code;
      }

      if (currentDeckIds.length > 0) {
        params.deckIds = currentDeckIds;
      }

      if (currentTestIds.length > 0) {
        params.testIds = currentTestIds;
      }
      if (currentMembershipId.length > 0) {
        if (currentMembershipId.length > 1) {
          DialogManager.alert(MULTIPLE_MEMBERSHIP_ERROR);
          return;
        }
        params.membershipId = currentMembershipId[0];
      }

      params.publisherType = this.state.publisherType;
      params.quantity = this.state.quantity;

      if (this.state.limitDate) params.limitDate = this.state.limitDate;
      if (this.state.comment) params.comment = this.state.comment;

      if (!this.state.insertMultiple) {
        this.setState({ waiting: true });
        Api.addCoupon(params)
          .then((response) => {
            this.setState({ waiting: false });
            this.openSnackbar("쿠폰을 추가했습니다.");
            if (this.props.onChange) {
              this.props.onChange(response.data);
            }
          })
          .catch((error) => {
            this.setState({ waiting: false });
            DialogManager.alert(getMessageFromResponse(error.response, "쿠폰 추가에 실패했습니다."));
          });
      } else {
        this.setState({ waiting: true });
        Api.bulkInsertCoupon(
          this.state.codes
            .split("\n")
            .map((a) => a.trim())
            .filter((a) => a),
          params
        )
          .then((response) => {
            this.setState({ waiting: false });
            this.openSnackbar("쿠폰 추가 요청에 성공 했습니다.");
            if (this.props.onChange) {
              this.props.onChange(response.data);
            }
          })
          .catch((error) => {
            this.setState({ waiting: false });
            DialogManager.alert(getMessageFromResponse(error.response, "쿠폰 추가 요청에 실패했습니다."));
          });
      }
    }
  };

  onItemDeleteClick = (item) => {
    const deleteIndex = this.state.items.findIndex((a) => a.id === item.id && a.type === item.type);
    if (deleteIndex >= 0) {
      this.setState({
        items: update(this.state.items, { $splice: [[deleteIndex, 1]] }),
      });
    }
  };

  onCouponItemAddRequest = (item) => {
    if (this.state.items.indexOf((a) => a.id === item.id && a.type === item.type) < 0) {
      this.setState({
        items: update(this.state.items, { $push: [{ id: item.id, type: item.type, name: item.name }] }),
      });
    } else {
      DialogManager.alert("이미 추가된 아이템입니다.");
    }
  };

  render() {
    return (
      <div style={[styles.container, this.props.style]}>
        <Card>
          <CardHeader>{this.props.editorType === Consts.EditorTypes.EDIT ? "쿠폰 수정" : "쿠폰 추가"}</CardHeader>
          <CardBody className="editor">
            {this.props.editorType === Consts.EditorTypes.NEW ? (
              <div className="text-align-center margin-bottom-10">
                <ButtonGroup>
                  <Button color={this.state.insertMultiple ? "secondary" : "primary"} onClick={() => this.setState({ insertMultiple: false })}>
                    1개
                  </Button>
                  <Button color={this.state.insertMultiple ? "primary" : "secondary"} onClick={() => this.setState({ insertMultiple: true })}>
                    여러개
                  </Button>
                </ButtonGroup>
              </div>
            ) : undefined}
            {this.props.editorType === Consts.EditorTypes.EDIT || !this.state.insertMultiple ? (
              <div className="data-row">
                <div className="name">code</div>
                <div className="value user-select-all">
                  {this.props.editorType === Consts.EditorTypes.EDIT ? (
                    this.state.code
                  ) : (
                    <input type="text" className="full-width" name="code" value={this.state.code} onChange={this.onInputChange} />
                  )}
                </div>
              </div>
            ) : (
              <div className="data-row">
                <div className="name">codes</div>
                <div className="value">
                  <textarea
                    style={styles.textarea}
                    type="text"
                    className="full-width"
                    name="codes"
                    value={this.state.codes}
                    placeholder={Strings.SPLIT_WITH_NEW_LINE}
                    onChange={this.onInputChange}
                  />
                </div>
              </div>
            )}
            <div className="data-row">
              <div className="name">items</div>
              <div className="value">
                {this.state.items.map((item, key) => {
                  return (
                    <p key={key} style={styles.couponItemP}>
                      <span style={styles.couponItemName}>
                        ({item.type}) {item.name}
                      </span>
                      <CloseIcon
                        style={styles.deleteIcon}
                        onClick={() => {
                          this.onItemDeleteClick(item);
                        }}
                      />
                    </p>
                  );
                })}
                <ContentsAutoCompleteInput onItemSelect={this.onCouponItemAddRequest} />
              </div>
            </div>
            <div className="data-row">
              <div className="name">publisherType</div>
              <div className="value">
                <Input type="select" className="full-width" name="publisherType" value={this.state.publisherType} onChange={this.onInputChange}>
                  <option value={Consts.CouponPublisherTypes.BOOK}>{Consts.CouponPublisherTypes.BOOK}</option>
                  <option value={Consts.CouponPublisherTypes.TEST}>{Consts.CouponPublisherTypes.TEST}</option>
                  <option value={Consts.CouponPublisherTypes.ADMIN}>{Consts.CouponPublisherTypes.ADMIN}</option>
                </Input>
              </div>
            </div>
            <div className="data-row">
              <div className="name">quantity</div>
              <div className="value">
                <input type="number" className="full-width" name="quantity" value={this.state.quantity} onChange={this.onInputChange} />
              </div>
            </div>
            <div className="data-row">
              <div className="name">limitDate</div>
              <div className="value">
                <input
                  type="text"
                  className="full-width"
                  name="limitDate"
                  value={this.state.limitDate}
                  onChange={this.onInputChange}
                  placeholder="YYYY-MM-DD"
                />
              </div>
            </div>
            <div className="data-row">
              <div className="name">comment</div>
              <div className="value">
                <input type="text" className="full-width" name="comment" value={this.state.comment} onChange={this.onInputChange} />
              </div>
            </div>
            {this.props.coupon && this.props.editorType === Consts.EditorTypes.EDIT && this.props.coupon.logs ? (
              <div className="data-row">
                <div className="name">사용 기록</div>
                <div className="value">
                  {this.props.coupon.logs && this.props.coupon.logs.length > 0 ? (
                    <Table style={{ border: "1px solid #cfd8dc" }}>
                      <tr>
                        <th style={styles.couponHistorytd}>id</th>
                        <th style={styles.couponHistorytd}>email</th>
                        <th style={styles.couponHistorytd}>nickname</th>
                        <th style={styles.couponHistorytd}>사용 일시</th>
                      </tr>
                      {this.props.coupon.logs.map((item, key) => {
                        return (
                          <tr key={key}>
                            <td style={styles.couponHistorytd}>{item.user ? item.user.id : ""}</td>
                            <td style={styles.couponHistorytd}>{item.user ? item.user.email : ""}</td>
                            <td style={styles.couponHistorytd}>{item.user ? item.user.nickname : ""}</td>
                            <td style={styles.couponHistorytd}>{Strings.buildDateString(item.registeredDate)}</td>
                          </tr>
                        );
                      })}
                    </Table>
                  ) : undefined}
                </div>
              </div>
            ) : undefined}
            <Button onClick={this.onSubmit} color="primary" block>
              {this.props.editorType === Consts.EditorTypes.EDIT ? "수정" : "추가"}
            </Button>
          </CardBody>
        </Card>
        {this.state.waiting ? <LoaderBlock /> : undefined}
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={1000}
          onClose={() => {
            this.setState({ snackbarOpen: false });
          }}
          SnackbarContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{this.state.snackbarMessage}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => {
                this.setState({ snackbarOpen: false });
              }}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </div>
    );
  }
}

const styles = {
  textarea: {
    minHeight: 80,
  },
  container: {
    position: "relative",
  },
  couponItemP: {
    borderBottom: "1px solid #cfd8dc",
  },
  couponItemName: {
    verticalAlign: "middle",
  },
  deleteIcon: {
    verticalAlign: "middle",
    cursor: "pointer",
    color: "#F44336",
    marginTop: -2,
  },
  couponHistorytd: {
    padding: "0px 10px",
  },
};

const defaultState = {
  code: "",
  codes: "",
  items: [],
  publisherType: Consts.CouponPublisherTypes.BOOK,
  quantity: 1,
  limitDate: "",
  comment: "",
  insertMultiple: false,
};

const MULTIPLE_MEMBERSHIP_ERROR = "Membership는 하나만 설정가능합니다.";

export default Radium(CouponEditor);
