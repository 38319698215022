/**
 * Created by kimchangduk on 2017-07-27.
 */

import * as Api from '../Api';
import {processHttpError} from './utils';
import {ActionTypes, Consts, Strings} from '../constants';

export function getDeckCards(urlKey, lessonId) {
    return (dispatch) => {
        dispatch({type: ActionTypes.GET_DECK_CARDS_REQUEST, urlKey, lessonId});
        Api.getDeckCards(urlKey, lessonId).then(response => {
            dispatch({type: ActionTypes.GET_DECK_CARDS_SUCCESS, urlKey, lessonId, data: response.data});
        }).catch(error => {
            processHttpError(dispatch, error);
            dispatch({type: ActionTypes.GET_DECK_CARDS_FAILURE, errorMessage: Strings.FAILED_TO_LOAD_DATA, urlKey, lessonId});
        });
    };
}