/**
 * Created by kimchangduk on 2017-08-29.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {Consts} from '../constants';
import {Button, Input, Table} from "reactstrap";
import DialogManager from '../dialogs/DialogManager';
import PurchaseLogDialog from '../dialogs/PurchaseLogDialog';
import {getMessageFromResponse, numberWithCommas} from "../utils";
import * as Api from "../Api";

class UserEditor extends React.Component {
    static propTypes = {
        user: PropTypes.object,
        onRemoveAuthorityRequest: PropTypes.func,
        onAddAuthorityRequest: PropTypes.func,
    };

    state = {
        addingAuthority: Consts.Authorities.ROLE_MANAGER
    };

    onAddingAuthorityChange = (e) => {
        this.setState({
            addingAuthority: e.target.value
        });
    };

    onPurchaseLogClick = (item) => {
        DialogManager.push(PurchaseLogDialog, {dataSource: item});
    };

    onUserLoginClick = () => {
        Api.loginWithUserId(this.props.user.id).then(response => {
            window.open(Consts.API_URL.replace('api.', ''));
        }).catch(error => {
            DialogManager.alert(getMessageFromResponse(error.response), '로그인에 실패했습니다.');
        });
    };

    render() {
        const user = this.props.user;
        return (<div style={styles.container}>
            <div style={styles.row}>
                <span style={styles.key}>id</span>
                <span style={styles.value}>{user.id}</span>
            </div>
            <div style={styles.row}>
                <span style={styles.key}>Email</span>
                <span style={styles.value}>{user.email}</span>
            </div>
            <div style={styles.row}>
                <span style={styles.key}>Email 인증</span>
                <span style={styles.value}>{user.emailVerify ? 'True' : 'False'}</span>
            </div>
            <div style={styles.row}>
                <span style={styles.key}>이름</span>
                <span style={styles.value}>{user.name}</span>
            </div>
            <div style={styles.row}>
                <span style={styles.key}>닉네임</span>
                <span style={styles.value}>{user.nickname}</span>
            </div>
            <div style={styles.row}>
                <span style={styles.key}>알림수신</span>
                <span style={styles.value}>{user.notify ? 'True' : 'False'}</span>
            </div>
            <div style={styles.row}>
                <span style={styles.key}>성별</span>
                <span style={styles.value}>{user.sex}</span>
            </div>
            <div style={styles.row}>
                <span style={styles.key}>생일</span>
                <span style={styles.value}>{user.birthday}</span>
            </div>
            <div style={styles.row}>
                <span style={styles.key}>메일링 동의</span>
                <span style={styles.value}>{user.agreeMail ? 'True' : 'False'}</span>
            </div>
            <div style={styles.row}>
                <span style={styles.key}>권한</span>
                <div style={styles.authorityValue}>
                    {
                        user.authorities.map((item, key) => {
                            return <div key={key}>{item}</div>;
                        })
                    }
                    <hr/>
                    <Input type="select" value={this.state.addingAuthority} onChange={this.onAddingAuthorityChange}>
                        {
                            [Consts.Authorities.ROLE_MANAGER, Consts.Authorities.ROLE_TESTER, Consts.Authorities.ROLE_API_VIEWER, Consts.Authorities.ROLE_API_TESTER].map((item, key) => (<option key={key} value={item}>{item}</option>))
                        }
                    </Input>
                    <button style={styles.addButton} type="submit" className="btn btn-primary btn-block" onClick={() => {
                        if (this.props.onAddAuthorityRequest) this.props.onAddAuthorityRequest(this.state.addingAuthority);
                    }}>
                        추가
                    </button>
                    <button style={styles.addButton} type="submit" className="btn btn-danger btn-block" onClick={() => {
                        if (this.props.onRemoveAuthorityRequest) this.props.onRemoveAuthorityRequest();
                    }}>
                        권한 초기화
                    </button>
                </div>
            </div>
            <div style={styles.row}>
                <span style={styles.key}>구매 내역</span>
                <div style={styles.value}>
                    {
                        user.purchaseLogs && user.purchaseLogs.length > 0 ? <Table>
                            <tbody>
                            <tr>
                                <th style={styles.td}>날짜</th>
                                <th style={styles.td}>구매 유형</th>
                                <th style={styles.td}>내용</th>
                                <th style={styles.td}>상세보기</th>
                            </tr>
                            {
                                user.purchaseLogs.map((item, key) => {
                                    let buyType = '';
                                    let buyContent = '';
                                    if (item.coupon) {
                                        buyType = '쿠폰';
                                        buyContent = item.coupon.code;
                                    } else if (item.imp) {
                                        buyType = '유료 결제';
                                        buyContent = `${numberWithCommas(item.imp ? item.imp.amount : 0)}원 ${item.imp.payMethod}`;
                                    }

                                    return <tr key={key}>
                                        <td style={styles.td}>{new Date(item.registeredDate).toFormat('YYYY-MM-DD HH:MI:SS')}</td>
                                        <td style={styles.td}>{buyType}</td>
                                        <td style={styles.td}>{buyContent}</td>
                                        <td style={styles.td}><span style={styles.link} onClick={() => {
                                            this.onPurchaseLogClick(item);
                                        }}>클릭</span></td>
                                    </tr>;
                                })
                            }
                            </tbody>
                        </Table> : undefined
                    }
                </div>
            </div>
            <div style={styles.row}>
                <span style={styles.key}>보유 컨텐츠</span>
                <div style={styles.value}>
                    {
                        (user.decks && user.decks.length > 0) ||
                        (user.memberships && user.memberships.length > 0) ||
                        (user.tests && user.tests.length > 0) ?
                            <Table>
                                <tbody>
                                <tr>
                                    <th style={styles.td}>유형</th>
                                    <th style={styles.td}>이름</th>
                                </tr>
                                {
                                    user.decks ? user.decks.map((item, key) => {
                                        return <tr key={'deck' + key}>
                                            <td style={styles.td}>{item.type}</td>
                                            <td style={styles.td}>{item.name}</td>
                                        </tr>;
                                    }) : undefined
                                }
                                {
                                    user.memberships ? user.memberships.map((item, key) => {
                                        return <tr key={'membership' + key}>
                                            <td style={styles.td}>{item.type}</td>
                                            <td style={styles.td}>{item.name}</td>
                                        </tr>;
                                    }) : undefined
                                }
                                {
                                    user.tests ? user.tests.map((item, key) => {
                                        return <tr key={'test' + key}>
                                            <td style={styles.td}>{item.type}</td>
                                            <td style={styles.td}>{item.name}</td>
                                        </tr>;
                                    }) : undefined
                                }
                                </tbody>
                            </Table> : undefined
                    }
                </div>
            </div>
            <Button block color="primary" onClick={this.onUserLoginClick}>해당 회원으로 로그인</Button>
        </div>);
    }
}

const styles = {
    container: {
        backgroundColor: '#FFFFFF',
        border: '1px solid rgba(0,0,0,0.15)',
        marginTop: 10,
        padding: 5
    },
    key: {
        width: 120,
        marginRight: 10,
        textAlign: 'right',
        display: 'inline-block',
        fontWeight: 'bold'
    },
    authorityValue: {
        width: 'calc(100% - 140px)',
        display: 'inline-block',
        verticalAlign: 'top'
    },
    value: {
        width: 'calc(100% - 130px)',
        display: 'inline-block',
        verticalAlign: 'top'
    },
    row: {
        margin: '5px 0px',
        padding: 5,
        transition: 'border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s'
    },
    deleteButton: {
        color: '#20a8d8',
        cursor: 'pointer',
        textDecoration: 'underline',
        userSelect: 'none'
    },
    addButton: {
        marginTop: 5
    },
    td: {
        paddingTop: 3,
        paddingBottom: 3,
        textAlign: 'center'
    },
    link: {
        color: '#64B5F6',
        textDecoration: 'underline',
        cursor: 'pointer'
    }
};

export default UserEditor;