/**
 * Created by kimchangduk on 2018-01-21.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

class QuillEditor extends React.Component {
    static propTypes = {
        style: PropTypes.object,
        actions: PropTypes.object,
        value: PropTypes.string,
        onChange: PropTypes.func,
        modules: PropTypes.object
    };

    static StateToProps = (state, ownProps) => {
        return {};
    };

    static DispatchToProps = (dispatch, ownProps) => {
        return {
            actions: {}
        };
    };

    render() {
        return (<ReactQuill
            style={this.props.style}
            value={this.props.value}
            onChange={this.props.onChange}
            modules={this.props.modules ? this.props.modules : modules}
            formats={formats}
            theme={"snow"}
        />);
    }
}

const modules = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{'list': 'ordered'}, {'list': 'bullet'}],
        [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
        [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
        [{'direction': 'rtl'}],                         // text direction

        [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
        [{'header': [1, 2, 3, 4, 5, 6, false]}],

        [{'color': []}, {'background': []}],          // dropdown with defaults from theme
        [{'font': []}],
        [{'align': []}],

        ['clean']                                         // remove formatting button
    ]
};

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'color', 'background', 'script', 'sub'
];

export default connect(QuillEditor.StateToProps, QuillEditor.DispatchToProps)(QuillEditor);