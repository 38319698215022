import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {login} from '../../../actions/user';
import {hasChangedRequestToSuccess, hasChangedRequestToFailure} from '../../../utils';

class Login extends Component {
    static propTypes = {
        loginRequest: PropTypes.string,
        loginErrorMessage: PropTypes.string,
        history: PropTypes.object,
        actions: PropTypes.object
    };

    static StateToProps = (state) => {
        return {
            loginRequest: state.user.state.loginRequest,
            loginErrorMessage: state.user.state.loginErrorMessage
        };
    };

    static DispatchToProps = (dispatch) => {
        return {
            actions: {
                login: (email, password) => {
                    dispatch(login(email, password));
                }
            }
        };
    };

    state = {
        email: '',
        password: ''
    };

    componentDidUpdate(prevProps) {
        if (hasChangedRequestToSuccess(prevProps.loginRequest, this.props.loginRequest)) {
            this.props.history.push('/');
        }
        if (hasChangedRequestToFailure(prevProps.loginRequest, this.props.loginRequest)) {
            window.alert(this.props.loginErrorMessage);
        }
    }

    onMemboxLinkClick = () => {
        window.open('https://membox.co.kr');
    };

    onInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.props.actions.login(this.state.email, this.state.password);
    };

    render() {
        return (
            <div className="app flex-row align-items-center">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="card-group mb-0">
                                <div className="card p-4">
                                    <div className="card-block">
                                        <h1>Login</h1>
                                        <p className="text-muted">Sign In to your account</p>
                                        <form onSubmit={this.onSubmit}>
                                            <div className="input-group mb-3">
                                                <span className="input-group-addon"><i className="icon-user"/></span>
                                                <input type="email" name="email" onChange={this.onInputChange} className="form-control" placeholder="Email"/>
                                            </div>
                                            <div className="input-group mb-4">
                                                <span className="input-group-addon"><i className="icon-lock"/></span>
                                                <input type="password" name="password" onChange={this.onInputChange} className="form-control" placeholder="Password"/>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <button type="submit" className="btn btn-primary px-4">Login</button>
                                                </div>
                                                <div className="col-6 text-right">
                                                    <button type="button" className="btn btn-link px-0" onClick={this.onMemboxLinkClick}>기억상자 바로가기</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(Login.StateToProps, Login.DispatchToProps)(Login);
