/**
 * Created by kimchangduk on 2017-10-29.
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Alert, Button, Card, CardBody, CardHeader, Col } from "reactstrap";
import DialogManager from "../../dialogs/DialogManager";
import * as Api from "../../Api";
import { Consts } from "../../constants";
import { getMessageFromResponse } from "../../utils";

class QuestionCSVBulkInsert extends React.Component {
  static propTypes = {
    onUpdated: PropTypes.func,
    actions: PropTypes.object,
    test: PropTypes.object,
  };

  static StateToProps = (state, ownProps) => {
    return {};
  };

  static DispatchToProps = (dispatch, ownProps) => {
    return {
      actions: {},
    };
  };

  constructor(props) {
    super(props);
    if (props.test) {
      this.state = {
        imageUri: props.test ? `https://memorybox.s3.ap-northeast-2.amazonaws.com/images/cbtImages/${props.test.id}/` : "",
        file: null,
        fileName: "",
        csvConvertRequest: Consts.REQUEST_NONE,
        csvConvertData: null,
        csvConvertErrorMessage: "",
      };
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.test !== nextProps.test) {
      this.initializeTest(nextProps.test);
    }
  }

  initializeTest = (test) => {
    this.setState({
      imageUri: test ? `https://memorybox.s3.ap-northeast-2.amazonaws.com/images/cbtImages/${test.id}/` : "",
      file: null,
      fileName: "",
    });
  };

  onFileInputClick = () => {
    this.refs["csv-input"].click();
  };

  onFileChange = (e) => {
    const newFile = e.target.files && e.target.files[0] ? e.target.files[0] : null;
    this.setState({
      file: newFile,
      fileName: e.target.value,
    });
    if (newFile) {
      Api.convertCBTCsvToJson(this.state.imageUri, newFile)
        .then((response) => {
          this.setState({
            csvConvertRequest: Consts.REQUEST_SUCCESS,
            csvConvertData: response.data,
          });
        })
        .catch((error) => {
          this.setState({
            csvConvertRequest: Consts.REQUEST_FAILURE,
            csvConvertErrorMessage: getMessageFromResponse(error.response, "CSV를 CBT JSON 변환에 실패했습니다."),
          });
        });
      this.setState({
        csvConvertRequest: Consts.REQUEST_WAITING,
        csvConvertData: null,
        csvConvertErrorMessage: "",
      });
    } else {
      this.setState({
        csvConvertRequest: Consts.REQUEST_NONE,
        csvConvertData: null,
        csvConvertErrorMessage: "",
      });
    }
  };

  onInsertClick = () => {
    if (!this.state.file) {
      DialogManager.alert("선택된 파일이 없습니다.");
      return;
    } else {
      Api.addCBTQuestionBulk(this.props.test.id, this.state.csvConvertData)
        .then((response) => {
          if (this.props.onUpdated) this.props.onUpdated();
        })
        .catch((error) => {
          DialogManager.alert(getMessageFromResponse(error.response, "카드 BULK 추가에 실패했습니다."));
        });
    }
  };

  onInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    return (
      <Card>
        <CardHeader>CSV 문제 추가</CardHeader>
        <CardBody className="editor">
          <div className="data-row">
            <div className="name">imageUri</div>
            <div className="value">
              <input type="text" value={this.state.imageUri} onChange={this.onInputChange} name="imageUri" className="full-width" accept=".csv,.CSV" />
            </div>
          </div>

          <div className="data-row">
            <div className="name">CSV 파일</div>
            <div className="value">
              {this.state.fileName ? <p>{this.state.fileName}</p> : undefined}
              <Button onClick={this.onFileInputClick}>파일 선택</Button>
              <input type="file" style={styles.inputFile} ref="csv-input" onChange={this.onFileChange} />
            </div>
          </div>
          {this.state.csvConvertRequest === Consts.REQUEST_FAILURE ? <p style={styles.errorMessage}>{this.state.csvConvertErrorMessage}</p> : undefined}
          {this.state.csvConvertRequest === Consts.REQUEST_SUCCESS ? (
            <Button color="primary" block onClick={this.onInsertClick}>
              삽입
            </Button>
          ) : undefined}
        </CardBody>
      </Card>
    );
  }
}

const styles = {
  inputFile: {
    display: "none",
  },
  marginBottom5: {
    marginBottom: 5,
  },
  errorMessage: {
    color: "#721c24",
    backgroundColor: "#f8d7da",
    borderColor: "#f5c6cb",
    padding: ".75rem 1.25rem",
    marginBottom: "1rem",
    border: "1px solid transparent",
    borderRadius: ".25rem",
  },
};

export default connect(QuestionCSVBulkInsert.StateToProps, QuestionCSVBulkInsert.DispatchToProps)(QuestionCSVBulkInsert);
