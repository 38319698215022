/**
 * Created by kimchangduk on 2017-07-23.
 */
import { createBrowserHistory } from "history";

const appHistory = createBrowserHistory();
export const appHistoryObject = appHistory;

export default {
  move: (url) => {
    appHistory.push(url);
  },
  goBack: (url) => {
    if (appHistory.length > 1) {
      appHistory.goBack();
    } else {
      appHistory.push(url);
    }
  },
  moveLoginPage: (history = undefined) => {
    if (history === undefined) {
      history = appHistory;
    }

    if (history) {
      history.push("/login");
    } else {
      window.location.ref = "/login";
    }
  },
};
