/**
 * Created by kimchangduk on 2018-03-04.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getDeckList} from "../../actions/deckList";
import {Consts} from "../../constants";
import DeckAutoCompleteInput from "../AutoCompletes/DeckAutoCompleteInput";
import {Input, Table} from "reactstrap";
import DragSortableList from 'react-drag-sortable';

class DeckListEditor extends React.Component {
    static propTypes = {
        actions: PropTypes.object,
        dataSource: PropTypes.array,
        deckListState: PropTypes.object,
        value: PropTypes.string, // Json Array String
        onChange: PropTypes.func
    };

    static defaultProps = {
        initialLoad: true
    };

    static StateToProps = (state, ownProps) => {
        return {
            dataSource: state.deckList.dataSource,
            deckListState: state.deckList.state,
        };
    };

    static DispatchToProps = (dispatch, ownProps) => {
        return {
            actions: {
                getDataSource: () => {
                    dispatch(getDeckList());
                }
            }
        };
    };

    componentDidMount() {
        const {deckListState, dataSource} = this.props;
        if (
            deckListState.request !== Consts.REQUEST_WAITING &&
            (
                dataSource ||
                deckListState.request !== Consts.REQUEST_SUCCESS ||
                (deckListState.lastRequestTime - new Date()) > 1000
            )
        ) {
            this.props.actions.getDataSource();
        }
    }

    onDeleteClick = (id) => {
        let ids;
        try {
            ids = JSON.parse(this.props.value);
        } catch (error) {
            ids = [];
        }

        const indexOfId = ids.indexOf(id);
        if (indexOfId >= 0) {
            ids.splice(indexOfId, 1);
        }

        if (this.props.onChange) {
            this.props.onChange(JSON.stringify(ids));
        }
    };

    onSort = (sortedList, dropEvent) => {
        if (sortedList) {
            const newValue = sortedList.filter(a => a && a.itemId !== null && a.itemId !== undefined).map(a => a.itemId);
            if (this.props.onChange) {
                this.props.onChange(JSON.stringify(newValue));
            }
        }
    };

    render() {
        let ids;
        try {
            ids = JSON.parse(this.props.value);
        } catch (error) {
            ids = [];
        }

        return (<div>
            <div className="small-table membox-admin-contents-list">
                <div className="table-head">
                    <div className="th"/>
                    <div className="th">name</div>
                    <div className="th">urlKey</div>
                    <div className="th">id</div>
                    <div className="th"/>
                </div>
                <DragSortableList
                    onSort={this.onSort}
                    items={
                        ids.map((item, key) => {
                            const currentItem = this.props.dataSource ? this.props.dataSource.find(a => a.id === item) : null;
                            if (!currentItem) {
                                return {
                                    content: <div key={key} className="table-row">
                                        <div className="td"/>
                                        <div className="td"/>
                                        <div className="td"/>
                                        <div className="td">{item}</div>
                                        <div className="td delete-button" onClick={() => this.onDeleteClick(item)}>
                                            삭제
                                        </div>
                                    </div>
                                };
                            }

                            return {
                                itemId: currentItem.id,
                                content: <div key={key} className="table-row">
                                    <div className="td">
                                        {currentItem.coverImage ? <img src={currentItem.coverImage} className="thumbnail"/> : ''}
                                    </div>
                                    <div className="td">{currentItem.name}</div>
                                    <div className="td">{currentItem.urlKey}</div>
                                    <div className="td">{currentItem.id}</div>
                                    <div className="td delete-button" onClick={() => this.onDeleteClick(item)}>
                                        삭제
                                    </div>
                                </div>
                            };
                        })
                    } type="vertical"/>
            </div>
            <DeckAutoCompleteInput
                initialLoad={false}
                onSelect={item => {
                    let ids;
                    try {
                        ids = JSON.parse(this.props.value);
                    } catch (error) {
                        ids = [];
                    }
                    if (ids.indexOf(item.id) < 0) {
                        ids.push(item.id);
                    }

                    if (this.props.onChange) {
                        this.props.onChange(JSON.stringify(ids));
                    }
                }}
            />
        </div>);
    }
}

export default connect(DeckListEditor.StateToProps, DeckListEditor.DispatchToProps)(DeckListEditor);