/**
 * Created by kimchangduk on 2017-08-22.
 */

import React from 'react';
import ClassEditor from "../../components/CBT/ClassEditor";
import SubjectEditor from "../../components/CBT/SubjectEditor";

class CBTSubject extends React.Component {

    render() {
        return (<div>
            <ClassEditor/>
            <SubjectEditor/>
        </div>);
    }
}

export default CBTSubject;