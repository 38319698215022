/**
 * Created by kimchangduk on 2017-08-14.
 */

import React from "react";
import update from "immutability-helper";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getDeckDetail } from "../../actions/deckDetail";
import * as Api from "../../Api";
import { Consts } from "../../constants";
import { Button, Card, CardBody, CardHeader, Input } from "reactstrap";
import { getMessageFromResponse } from "../../utils";
import DialogManager from "../../dialogs/DialogManager";

class LessonEditor extends React.Component {
  static propTypes = {
    cardsRequest: PropTypes.string,
    onDelete: PropTypes.func,
    urlKey: PropTypes.string,
    editorType: PropTypes.string,
    lesson: PropTypes.object,
    element: PropTypes.object,
    packagePathIndexs: PropTypes.array,
    actions: PropTypes.object,
    cards: PropTypes.array,
  };

  static defaultProps = {
    editorType: Consts.EditorTypes.EDIT,
  };

  static StateToProps = (state, ownProps) => {
    const lesson = ownProps.lesson;
    return {
      cardsRequest: state.deckCards.state.request,
      cards: lesson && state.deckCards.state.lessonId === lesson.id && ownProps.urlKey === state.deckCards.state.urlKey ? state.deckCards.dataSource : null,
    };
  };

  static DispatchToProps = (dispatch, ownProps) => {
    return {
      actions: {
        getDeckDetail: () => {
          dispatch(getDeckDetail(ownProps.urlKey));
        },
      },
    };
  };

  state = {
    id: "",
    name: "",
    free: "",
    cards: [],
  };

  componentDidMount() {
    this.initStatesWithLesson(this.props.editorType === Consts.EditorTypes.EDIT ? this.props.lesson : null);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.urlKey !== nextProps.urlKey ||
      this.props.editorType !== nextProps.editorType ||
      this.props.lesson !== nextProps.lesson ||
      this.props.element !== nextProps.element ||
      this.props.packagePathIndexs !== nextProps.packagePathIndexs
    ) {
      this.initStatesWithLesson(nextProps.editorType === Consts.EditorTypes.EDIT ? nextProps.lesson : null);
    }
  }

  initStatesWithLesson = (lesson) => {
    if (lesson) {
      this.setState({
        id: lesson.id !== null && lesson.id !== undefined ? lesson.id.toString() : "",
        name: lesson.name,
        free: lesson.free ? "true" : "false",
        cards: JSON.stringify(lesson.cards),
      });
    } else {
      if (this.state.name && this.state.name.match(/핵심 (\d\d\d)~(\d\d\d)/i)) {
        const params = /핵심 (\d\d\d)~(\d\d\d)/i.exec(this.state.name);
        const newStartIndex = parseInt(params[1]) + 10;
        const newEndIndex = parseInt(params[2]) + 10;
        this.setState({
          name: "핵심 " + (newStartIndex < 100 ? "0" + newStartIndex : newStartIndex) + "~" + (newEndIndex < 100 ? "0" + newEndIndex : newEndIndex),
          free: "false",
          cards: "",
        });
      } else {
        this.setState({
          name: "",
          free: "false",
          cards: "",
        });
      }
    }
  };

  getUpdateElement = (element, depth = undefined) => {
    if (depth === undefined) {
      depth = this.props.packagePathIndexs.length;
    }

    if (!element) {
      return null;
    }
    let updateElement = element;
    for (let i = 0; i < depth; i++) {
      updateElement = updateElement.packages[this.props.packagePathIndexs[i]];
    }
    return updateElement;
  };

  getMaxLessonId = (element, maxIndex = 0) => {
    if (!element) return 0;
    if (element.lessons)
      for (let lesson of element.lessons) {
        maxIndex = Math.max(lesson.id, maxIndex);
      }

    if (element.packages)
      for (let _package of element.packages) {
        maxIndex = Math.max(this.getMaxLessonId(_package), maxIndex);
      }

    return maxIndex;
  };

  onInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const rootElement = update(this.props.element, {});
    let element = rootElement;
    if (!element) {
      element = { lessons: null, packages: [] };
    }
    const updateElement = this.getUpdateElement(element);
    let cards = [];
    //<editor-fold desc="Cards Parsing">
    try {
      if (this.state.cards) {
        cards = JSON.parse(this.state.cards);
      }
      if (!Array.isArray(cards)) {
        throw new Error("배열 파싱 실패");
      }
    } catch (ex) {
      DialogManager.alert(
        <div>
          Cards를 파싱할 수 없습니다.
          <br />
          [1,2,3 ... ] 형식으로 입력해주세요.
        </div>
      );
      return;
    }
    //</editor-fold>

    if (this.props.editorType === Consts.EditorTypes.EDIT) {
      const lesson = updateElement.lessons.find((item) => item.id === this.props.lesson.id);
      lesson.id = parseInt(this.state.id);
      lesson.name = this.state.name;
      lesson.free = this.state.free === "true";
      lesson.cards = cards;
      if (isNaN(lesson.id)) {
        alert("id가 숫자형식이 아닙니다.");
        return;
      }
    } else {
      if (!updateElement.lessons) {
        updateElement.lessons = [];
      }
      let insertId = (insertId = this.getMaxLessonId(rootElement) + 1);
      updateElement.lessons.push({ name: this.state.name, cards: cards, id: insertId });
    }

    Api.editDeckElement(this.props.urlKey, element)
      .then((response) => {
        this.props.actions.getDeckDetail();
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        DialogManager.alert(getMessageFromResponse(error.response, "요청에 실패했습니다."));
      });
  };

  onDeleteClick = () => {
    DialogManager.confirm(`'${this.props.lesson.name}' 레슨을 삭제하시겠습니까?`, () => {
      const element = update(this.props.element, {});
      const updateElement = this.getUpdateElement(element, this.props.packagePathIndexs.length);
      const deleteIndex = updateElement.lessons.findIndex((item) => item.id === this.props.lesson.id);
      updateElement.lessons.splice(deleteIndex, 1);
      Api.editDeckElement(this.props.urlKey, element).then((response) => {
        if (this.props.onDelete) {
          this.props.onDelete(this);
        }
        this.props.actions.getDeckDetail();
      });
    });
  };

  onRemoveCardIdsClick = () => {
    const deckCards = this.props.cards ? this.props.cards : [];
    let cards;
    try {
      cards = JSON.parse(this.state.cards);
    } catch (ex) {
      DialogManager.alert("Cards가 json형태가 아닙니다.");
      return;
    }

    if (!Array.isArray(cards)) {
      DialogManager.alert("Array가 아닙니다.");
      return;
    }

    const newCards = JSON.stringify(cards.filter((a) => deckCards.findIndex((b) => b.id === a) >= 0));
    this.setState({
      cards: newCards,
    });
  };

  getMissMatchCardIds = () => {
    const cards = this.props.cards ? this.props.cards : [];
    if (this.props.cardsRequest === Consts.REQUEST_WAITING || !this.props.cards || cards.length === this.props.lesson.cards.length) {
      return null;
    }

    return this.props.lesson.cards.filter((a) => cards.findIndex((b) => b.id === a) < 0);
  };

  render() {
    let title = "";
    let element = this.props.element;
    if (element) {
      for (let i = 0; i < this.props.packagePathIndexs.length; i++) {
        element = element.packages[this.props.packagePathIndexs[i]];
        if (element) {
          title += element.name + " > ";
        } else {
          title += "??? > ";
        }
      }
    }

    if (this.props.editorType === Consts.EditorTypes.EDIT) {
      title += "레슨 수정";
    } else {
      title += "레슨 추가";
    }
    const missMatchCardIds = this.getMissMatchCardIds();

    return (
      <Card>
        <CardHeader>{title}</CardHeader>
        <CardBody className="editor">
          <form onSubmit={this.onSubmit}>
            {this.props.editorType === Consts.EditorTypes.EDIT ? (
              <div className="data-row">
                <div className="name">id</div>
                <div className="value">
                  <Input type="text" name="id" value={this.state.id} onChange={this.onInputChange} />
                  <p style={styles.warning}>
                    <u>한 덱에서</u> 중복된 lesson id가 발생하지 않도록 주의해 주세요.
                  </p>
                </div>
              </div>
            ) : undefined}
            <div className="data-row">
              <div className="name">이름</div>
              <div className="value">
                <Input type="text" name="name" value={this.state.name} onChange={this.onInputChange} />
              </div>
            </div>
            <div className="data-row">
              <div className="name">무료</div>
              <div className="value">
                <Input type="select" name="free" value={this.state.free} onChange={this.onInputChange}>
                  <option value="false">false</option>
                  <option value="true">true</option>
                </Input>
              </div>
            </div>
            <div className="data-row">
              <div className="name">Cards</div>
              <div className="value">
                <Input type="text" name="cards" value={this.state.cards} onChange={this.onInputChange} />
                {missMatchCardIds && missMatchCardIds.length > 0 ? (
                  <div>
                    <p style={styles.warning}>
                      {JSON.stringify(missMatchCardIds)} 카드들이 존재하지 않습니다.{" "}
                      <span onClick={this.onRemoveCardIdsClick} style={styles.removeCardIdsButton}>
                        존재하지 않는 카드 id 제거
                      </span>
                    </p>
                  </div>
                ) : undefined}
              </div>
            </div>
            <div>
              <Button type="submit" size="sm" color="primary" block>
                {this.props.editorType === Consts.EditorTypes.EDIT ? "수정" : "추가"}
              </Button>
              {this.props.editorType === Consts.EditorTypes.EDIT ? (
                <Button type="button" size="sm" color="danger" onClick={this.onDeleteClick} block>
                  삭제
                </Button>
              ) : undefined}
            </div>
          </form>
        </CardBody>
      </Card>
    );
  }
}

const styles = {
  name: { width: 100, display: "inline-block", textAlign: "right", fontWeight: "bold", paddingRight: 10 },
  value: { width: "calc(100% - 100px)", display: "inline-block", paddingLeft: 10 },
  marginBottom10: {
    marginBottom: 10,
  },
  warning: {
    color: "red",
    margin: 0,
  },
  removeCardIdsButton: {
    whiteSpace: "nowrap",
    textDecoration: "underline",
    cursor: "pointer",
    marginLeft: 10,
    color: "#2196F3",
  },
};

export default connect(LessonEditor.StateToProps, LessonEditor.DispatchToProps)(LessonEditor);
