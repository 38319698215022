/**
 * Created by kimchangduk on 2017-11-30.
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Consts } from "../../constants";
import { combineStrings, getMessageFromResponse } from "../../utils";
import { Button, Card, CardBody, CardHeader, Input } from "reactstrap";
import { addMembership, editMembership, getMembership } from "../../actions/memberships";
import HashtagEditor from "../HashtagEditor";
import QuillEditor from "../QuillEditor";
import PriceEditor from "../PriceEditor";
import * as Api from "../../Api";
import DialogManager from "../../dialogs/DialogManager";
import DeckListEditor from "../MemboxContentsListEditor/DeckListEditor";
import CBTTestListEditor from "../MemboxContentsListEditor/CBTTestListEditor";

class MembershipEditor extends React.Component {
  static propTypes = {
    isWaiting: PropTypes.bool,
    actions: PropTypes.object,
    editMode: PropTypes.string,
    data: PropTypes.object,
    membershipDetail: PropTypes.object,
  };

  static StateToProps = (state, ownProps) => {
    return {
      isWaiting:
        state.memberships.put.state.request === Consts.REQUEST_WAITING ||
        state.memberships.post.state.request === Consts.REQUEST_WAITING ||
        state.memberships.getList.state.request === Consts.REQUEST_WAITING ||
        state.memberships.get.state.request === Consts.REQUEST_WAITING,
      membershipDetail: state.memberships.get.dataSource,
    };
  };

  static DispatchToProps = (dispatch, ownProps) => {
    return {
      actions: {
        addMembership: (name, description, coverImage) => {
          dispatch(addMembership(name, description, coverImage));
        },
        editMembership: (urlKey, name, description, visibility, coverImage, deckIds, testIds) => {
          dispatch(editMembership(urlKey, name, description, visibility, coverImage, deckIds, testIds));
        },
        getMembership: (urlKey) => {
          dispatch(getMembership(urlKey));
        },
      },
    };
  };

  state = {
    name: "",
    description: "",
    visibility: Consts.DeckVisibility.NONE,
    coverImage: null,
    coverImageName: null,
    deckIds: "",
    testIds: "",
    sale: false,
    discountPrice: null,
    salesLabel: "",
    showDashboard: true,
    price: "",
  };

  componentDidMount() {
    this.initStates(this.props.editMode === Consts.EditModes.MODIFY ? this.props.data : null);
    if (this.props.editMode === Consts.EditModes.MODIFY && this.props.data) {
      this.props.actions.getMembership(this.props.data.urlKey);
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (this.props.data !== nextProps.data || this.props.editMode !== nextProps.editMode) {
      this.initStates(nextProps.editMode === Consts.EditModes.MODIFY ? nextProps.data : null);
      if (nextProps.editMode === Consts.EditModes.MODIFY && nextProps.data) {
        this.props.actions.getMembership(nextProps.data.urlKey);
      }
    }
  };

  initStates = (data) => {
    if (!data) {
      this.setState({
        name: "",
        description: "",
        visibility: Consts.DeckVisibility.NONE,
        coverImage: null,
        coverImageName: "",
        deckIds: "",
        testIds: "",
      });
      return;
    }
    this.setState({
      name: data.name ? data.name : "",
      description: data.description ? data.description.replace(/<br\/>/gi, "\n") : "",
      visibility: data.visibility,
      coverImage: null,
      coverImageName: "",

      deckIds: data.deckIds ? JSON.stringify(data.deckIds) : "[]",
      testIds: data.testIds ? JSON.stringify(data.testIds) : "[]",

      price: data.price !== null ? data.price.toString() : "",
      sale: data.price !== null,
      discountPrice: data.discountPrice !== null && data.discountPrice !== undefined ? data.discountPrice.toString() : null,
      salesLabel: data.salesLabel ? data.salesLabel : "",
      showDashboard: data.showDashboard,
    });
  };

  onInputChange = (e) => {
    switch (e.target.name) {
      case "showDashboard":
        this.setState({
          [e.target.name]: e.target.checked,
        });
        break;
      default:
        this.setState({
          [e.target.name]: e.target.value,
        });
        break;
    }
  };

  onCoverImageChange = (e) => {
    const file = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
    this.setState({
      coverImage: file,
      coverImageName: file ? file.name : "",
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    let deckIds, testIds;
    try {
      deckIds = JSON.parse(this.state.deckIds);
      testIds = JSON.parse(this.state.testIds);
    } catch (error) {
      deckIds = [];
      testIds = [];
    }

    if (this.props.editMode === Consts.EditModes.ADD) {
      this.props.actions.addMembership(this.state.name, this.state.description, this.state.coverImage);
    } else {
      Api.setShowDashboard(Consts.ItemTypes.MEMBERSHIP, this.props.data.urlKey, this.state.showDashboard)
        .then((response) => {
          this.props.actions.editMembership(
            this.props.data.urlKey,
            this.state.name,
            this.state.description.replace(/\n/gi, "<br/>"),
            this.state.visibility,
            this.state.coverImage,
            deckIds,
            testIds
          );
        })
        .catch((error) => {
          alert("대시보드 노출 변경에 실패했습니다.");
        });
    }
  };

  onPriceSubmit = (e) => {
    // TODO: cbt,deck,membership 가격수정 요청 코드 하나로 묶기
    e.preventDefault();
    const parameter = {};
    let sale = this.state.sale;
    if (typeof sale === "string") {
      sale = sale === "true";
    }
    parameter.sale = sale;

    if (sale) {
      let price = this.state.price;
      if (price === "") {
        price = 0;
      }
      if (typeof price === "string") {
        price = parseInt(price);
        if (isNaN(price)) {
          DialogManager.alert("판매가가 숫자형식이 아닙니다");
          return;
        }
      }
      parameter.price = price;

      let discountPrice = this.state.discountPrice;
      if (discountPrice === "") {
        discountPrice = null;
      }
      if (typeof discountPrice === "string") {
        discountPrice = parseInt(discountPrice);
        if (isNaN(discountPrice)) {
          DialogManager.alert("할인가가 숫자형식이 아닙니다");
          return;
        }
      }
      parameter.discountPrice = discountPrice;
    }

    const priceEditRequest = () => {
      Api.editMembershipPrice(this.props.data.urlKey, parameter)
        .then((response) => {
          this.props.actions.getMembership(this.props.data.urlKey);
        })
        .catch((error) => {
          DialogManager.alert(getMessageFromResponse(error.response, "멤버쉽 가격 수정에 실패했습니다."));
        });
    };

    if (this.props.data.salesLabel !== this.state.salesLabel) {
      Api.setSalesLabel(Consts.ItemTypes.MEMBERSHIP, this.props.data.urlKey, this.state.salesLabel)
        .then((response) => {
          priceEditRequest();
        })
        .catch((error) => {
          DialogManager.alert(getMessageFromResponse(error.response, "판매문구 수정에 실패했습니다."));
        });
    } else {
      priceEditRequest();
    }
  };

  render() {
    return (
      <div>
        <Card>
          <CardHeader>{this.props.editMode === Consts.EditModes.MODIFY ? "멤버쉽 수정" : "멤버쉽 추가"}</CardHeader>
          <CardBody className="editor">
            <form onSubmit={this.onSubmit}>
              {this.props.editMode === Consts.EditModes.MODIFY ? (
                <div>
                  <div className="data-row">
                    <div className="name">UrlKey</div>
                    <div className="value">{this.props.data.urlKey}</div>
                  </div>
                  <div className="data-row">
                    <div className="name">id</div>
                    <div className="value">{this.props.data.id}</div>
                  </div>
                </div>
              ) : undefined}
              <div className="data-row">
                <div className="name">name</div>
                <div className="value">
                  <Input type="text" name="name" value={this.state.name} onChange={this.onInputChange} />
                </div>
              </div>
              <div className="data-row">
                <div className="name">description</div>
                <div className="value">
                  <QuillEditor
                    value={this.state.description}
                    onChange={(value) => {
                      this.setState({ description: value });
                    }}
                  />
                </div>
              </div>
              {this.props.editMode === Consts.EditModes.MODIFY ? (
                <div className="data-row">
                  <div className="name">visibility</div>
                  <div className="value">
                    <Input type="select" name="visibility" value={this.state.visibility} onChange={this.onInputChange}>
                      <option value={Consts.DeckVisibility.PUBLIC}>PUBLIC</option>
                      <option value={Consts.DeckVisibility.PRIVATE}>PRIVATE</option>
                    </Input>
                  </div>
                </div>
              ) : undefined}
              {this.props.editMode === Consts.EditModes.MODIFY ? (
                <div className="data-row">
                  <div className="name">대시보드 노출</div>
                  <div className="value">
                    <input type="checkbox" name="showDashboard" checked={this.state.showDashboard} onChange={this.onInputChange} />
                  </div>
                </div>
              ) : undefined}
              <div className="data-row">
                <div className="name">coverImage</div>
                <div className="value">
                  {this.props.editMode === Consts.EditModes.MODIFY && this.props.data && this.props.data.coverImage && !this.state.coverImage ? (
                    <img style={{ maxWidth: 100 }} src={this.props.data.coverImage} />
                  ) : (
                    this.state.coverImageName
                  )}
                  <Input type="file" name="coverImage" value="" onChange={this.onCoverImageChange} />
                </div>
              </div>
              {this.props.editMode === Consts.EditModes.MODIFY ? (
                <div>
                  <div className="data-row margin-bottom-30">
                    <div className="name">deckIds</div>
                    <div className="value">
                      <DeckListEditor
                        value={this.state.deckIds}
                        onChange={(str) => {
                          this.setState({ deckIds: str });
                        }}
                      />
                    </div>
                  </div>
                  <div className="data-row margin-bottom-30">
                    <div className="name">testIds</div>
                    <div className="value">
                      <CBTTestListEditor
                        value={this.state.testIds}
                        onChange={(str) => {
                          this.setState({ testIds: str });
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : undefined}
              <Button disabled={this.props.isWaiting} type="submit" size="sm" color="primary" block>
                {this.props.editMode === Consts.EditModes.ADD ? "추가" : "수정"}
              </Button>
            </form>
          </CardBody>
        </Card>
        {this.props.editMode === Consts.EditModes.MODIFY &&
        this.props.data &&
        this.props.membershipDetail &&
        this.props.data.urlKey === this.props.membershipDetail.urlKey ? (
          <HashtagEditor
            urlKey={this.props.data.urlKey}
            itemType={Consts.ItemTypes.MEMBERSHIP}
            hashtag={this.props.membershipDetail.hashtag}
            id={this.props.data.id}
          />
        ) : undefined}
        {this.state.editMode === Consts.EditModes.TEST_MODIFY ? (
          <PriceEditor
            title="멤버쉽 가격 수정"
            onInputChange={this.onInputChange}
            onPriceSubmit={this.onPriceSubmit}
            sale={this.state.sale}
            price={this.state.price}
            discountPrice={this.state.discountPrice}
            salesLabel={this.state.salesLabel}
          />
        ) : undefined}
      </div>
    );
  }
}

export default connect(MembershipEditor.StateToProps, MembershipEditor.DispatchToProps)(MembershipEditor);
