/**
 * Created by kimchangduk on 2017-08-15.
 */

import React from 'react';
import PropTypes from 'prop-types';
import DefaultDialog from "./DefaultDialog";
import {Input} from "reactstrap";
import DialogManager from "./DialogManager";

class ConfirmDialog extends React.Component {
    static propTypes = {
        title: PropTypes.string,
        content: PropTypes.string,
        onClose: PropTypes.func.isRequired,
        onPrimaryButtonClick: PropTypes.func.isRequired,
        onSecondaryButtonClick: PropTypes.func.isRequired
    };

    onInputChange = (e) => {
        this.setState({urlKeyInput: e.target.value});
    };

    onClickYes = () => {
        if (this.props.onPrimaryButtonClick) {
            this.props.onPrimaryButtonClick();
        }
        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    onClickNo = () => {
        if (this.props.onSecondaryButtonClick) {
            this.props.onSecondaryButtonClick();
        }
        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    render() {
        return (<DefaultDialog
            modal={true}
            title={this.props.title}
            content={this.props.content}
            onClose={this.props.onClose}
            buttons={
                [
                    {
                        text: '예',
                        color: 'primary',
                        onClick: this.onClickYes,
                        raised: true
                    },
                    {
                        text: '아니오',
                        color: 'default',
                        onClick: this.onClickNo
                    }
                ]
            }
        />);
    }
}

export default ConfirmDialog;