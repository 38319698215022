import React from "react";
import PropTypes from "prop-types";
import DefaultDialog from "./DefaultDialog";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import { IconButton } from "@material-ui/core";

class ListReorderDialog extends React.Component {
  static propTypes = {
    onReorderRequest: PropTypes.func,
    onClose: PropTypes.func,
  };

  reorderRequest = (change) => {
    if (this.props.onReorderRequest) {
      this.props.onReorderRequest(change);
    }
    if (this.props.onClose) this.props.onClose();
  };

  render() {
    return (
      <DefaultDialog
        onClose={this.props.onClose}
        content={
          <div style={{ textAlign: "center" }}>
            <IconButton onClick={() => this.reorderRequest(-1)}>
              <KeyboardArrowUp />
            </IconButton>
            <IconButton onClick={() => this.reorderRequest(1)}>
              <KeyboardArrowDown />
            </IconButton>
          </div>
        }
      />
    );
  }
}

export default ListReorderDialog;
