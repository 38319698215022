/**
 * Created by kimchangduk on 2017-11-29.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Col, Row} from "reactstrap";
import ListSearch from "../components/ListSearch/ListSearch";
import {getMembership, getMembershipList} from "../actions/memberships";
import {Consts} from "../constants";
import MembershipEditor from "../components/Memberships/MembershipEditor";

class Memberships extends React.Component {
    static propTypes = {
        actions: PropTypes.object,
        membershipList: PropTypes.array
    };

    static StateToProps = (state, ownProps) => {
        return {
            membershipList: state.memberships.getList.dataSource
        };
    };

    static DispatchToProps = (dispatch, ownProps) => {
        return {
            actions: {
                getMembershipList: () => {
                    dispatch(getMembershipList());
                },
                getMembership: (urlKey) => {
                    dispatch(getMembership(urlKey));
                }
            }
        };
    };

    state = {
        selectedId: -1,
        editMode: Consts.EditModes.NONE
    };

    componentDidMount() {
        this.props.actions.getMembershipList();
    }

    getSelectedItem = () => {
        if (!this.props.membershipList) return null;
        return this.props.membershipList.find(a => a.id === this.state.selectedId);
    };

    render() {
        const selectedItem = this.getSelectedItem();

        return (<div>
            <Row>
                <Col md={this.state.editMode === Consts.EditModes.NONE}>
                    <ListSearch
                        title="멤버쉽 리스트"
                        wrapWithColumn={false}
                        dataSource={this.props.membershipList}
                        reorderable={false}
                        getAvatar={item => item.coverImage}
                        getName={(a) => {
                            return `${a.id}. ${a.name}`;
                        }}
                        onSelect={(a) => {
                            this.setState({selectedId: a.id, editMode: Consts.EditModes.MODIFY});
                        }}
                        isSelectedItem={a => a.id === this.state.selectedId}
                        onAddClick={() => {
                            this.setState({editMode: Consts.EditModes.ADD, selectedId: -1});
                        }}
                    />
                </Col>
                {
                    this.state.editMode !== Consts.EditModes.NONE ?
                        <MembershipEditor editMode={this.state.editMode} data={selectedItem}/> :
                        undefined
                }
            </Row>
        </div>);
    }
}

export default connect(Memberships.StateToProps, Memberships.DispatchToProps)(Memberships);