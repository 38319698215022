/**
 * Created by kimchangduk on 2018-02-21.
 */

import React from 'react';
import PropTypes from 'prop-types';
import DefaultDialog from "./DefaultDialog";
import {numberWithCommas} from "../utils";

class PurchaseLogDialog extends React.Component {
    static propTypes = {
        onClose: PropTypes.func,
        dataSource: PropTypes.object.isRequired
    };

    render() {
        const dataSource = this.props.dataSource;
        return (<DefaultDialog onClose={this.props.onClose} content={<div>
            {
                dataSource.coupon ? <div>
                    <h4 style={styles.title}>쿠폰 등록</h4>
                    <div style={styles.row}>
                        <div style={styles.name}>코드</div>
                        <div style={styles.value}>{dataSource.coupon.code}</div>
                    </div>
                    <div style={styles.row}>
                        <div style={styles.name}>limitDate</div>
                        <div style={styles.value}>{dataSource.coupon.limitDate ? new Date(dataSource.coupon.limitDate).toFormat('YYYY-MM-DD HH:MI:SS') : ''}</div>
                    </div>
                    <div style={styles.row}>
                        <div style={styles.name}>쿠폰 생성일</div>
                        <div style={styles.value}>{new Date(dataSource.coupon.registeredDate).toFormat('YYYY-MM-DD HH:MI:SS')}</div>
                    </div>
                </div> : undefined
            }
            {
                dataSource.imp ? <div>
                    <h4 style={styles.title}>유료 결제</h4>
                    {
                        Object.keys(dataSource.imp)
                            .sort((a, b) => {
                                return (impKeyOrder[b] ? impKeyOrder[b] : 0) - (impKeyOrder[a] ? impKeyOrder[a] : 0);
                            }).map((item, key) => {
                            let name = item;
                            let value = dataSource.imp[item];

                            switch (name) {
                                case 'payMethod':
                                    name = '결제 수단';
                                    break;

                                case 'cardName':
                                    name = '카드명';
                                    break;

                                case 'amount':
                                    name = '금액';
                                    value = numberWithCommas(value);
                                    break;

                                case 'paidAt':
                                    name = '결제 시각';
                                    value = value ? new Date(value).toFormat('YYYY-MM-DD HH:MI:SS') : '';
                                    break;

                                case 'name':
                                    name = '구매 컨텐츠';
                                    break;

                                case 'receiptUrl':
                                    name = '영수증 주소';
                            }

                            return <div style={styles.row} key={key}>
                                <div style={styles.name}>{name}</div>
                                <div style={styles.value}>{
                                    item === 'receiptUrl' ?
                                        <a href={value}>{value}</a> : value
                                }</div>
                            </div>;
                        })
                    }
                </div> : undefined
            }
            {
                dataSource.items ?
                    <div style={styles.row}>
                        <div style={styles.name}>items</div>
                        <div style={styles.value}>
                            {
                                dataSource.items.map((item, key) => (<div key={key}>
                                    [{item.type}] {item.urlKey} {item.name}
                                </div>))
                            }
                        </div>
                    </div> : undefined
            }
        </div>}/>);
    }
}

const impKeyOrder = {
    name: 1,
    payMethod: 2,
    cardName: 3,
    amount: 4,
    paidAt: 5,
    receiptUrl: -1
};

const styles = {
    title: {
        textAlign: 'center'
    },
    row: {
        fontSize: 13,
        position: 'relative'
    },
    name: {fontWeight: 'bold', display: 'inline-block', width: 100, verticalAlign: 'top'},
    value: {display: 'inline-block', width: 'calc(100% - 100px)', verticalAlign: 'top', overflow: 'hidden', wordBreak: 'break-all'}
};

export default PurchaseLogDialog;