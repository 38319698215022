/**
 * Created by kimchangduk on 2017-08-15.
 */

import React from 'react';
import PropTypes from 'prop-types';
import DefaultDialog from "./DefaultDialog";
import {Input} from "reactstrap";
import DialogManager from "./DialogManager";

class SafeDeleteDialog extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        checkUrlKey: PropTypes.string.isRequired,
        onClose: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired
    };

    state = {
        urlKeyInput: ''
    };

    onInputChange = (e) => {
        this.setState({urlKeyInput: e.target.value});
    };

    onDeleteClick = () => {
        if (this.state.urlKeyInput === this.props.checkUrlKey) {
            if (this.props.onDelete) {
                this.props.onDelete(this);
            }
        } else {
            DialogManager.alert('UrlKey가 일치하지 않습니다.');
        }
    };

    render() {
        return (<DefaultDialog
            title={`${this.props.name} 삭제`}
            content={<Input type="text" value={this.state.urlKeyInput} onChange={this.onInputChange} placeholder="UrlKey를 입력하세요."/>}
            onClose={this.props.onClose}
            buttons={
                [
                    {
                        text: '삭제',
                        color: 'accent',
                        onClick: this.onDeleteClick
                    },
                    {
                        text: '취소',
                        color: 'default',
                        onClick: this.props.onClose
                    }
                ]
            }
        />);
    }
}

export default SafeDeleteDialog;