/**
 * Created by kimchangduk on 2017-10-27.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Input} from "reactstrap";
import * as Consts from "../../constants/Consts";
import {getCBTClassList} from "../../actions/cbt";

class ClassSelectField extends React.Component {
    static propTypes = {
        actions: PropTypes.object,
        name: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        onChange: PropTypes.func,
        classList: PropTypes.array,
        autoLoadDataSource: PropTypes.bool
    };

    static defaultProps = {
        autoLoadDataSource: false
    };

    static StateToProps = (state, ownProps) => {
        return {classList: state.cbt.classList.dataSource};
    };

    static DispatchToProps = (dispatch, ownProps) => {
        return {
            actions: {
                getClassList: () => {
                    dispatch(getCBTClassList());
                }
            }
        };
    };

    componentDidMount() {
        if (this.props.autoLoadDataSource) {
            this.props.actions.getClassList();
        }
    }

    onInputChange = (e) => {
        let newId = e.target.value;
        if (typeof(newId) === 'string') {
            newId = parseInt(newId);
        }

        if (this.props.onChange) {
            this.props.onChange(e, newId);
        }
    };

    render() {
        let value = this.props.value;
        if (typeof(value) === 'string') {
            value = parseInt(value);
        }
        if (isNaN(value)) {
            value = '';
        }

        return (<Input type="select" name={this.props.name} value={value} onChange={this.onInputChange}>
            <option>선택</option>
            {
                this.props.classList ? this.props.classList.map((item, key) => (<option key={key} value={item.id}>{item.name}</option>)) : undefined
            }
        </Input>);
    }
}

export default connect(ClassSelectField.StateToProps, ClassSelectField.DispatchToProps)(ClassSelectField);