/**
 * Created by kimchangduk on 2017-7-25.
 */

import { ActionTypes, Consts } from "../constants";
import update from "immutability-helper";

const initialState = {
  state: {
    request: Consts.REQUEST_NONE,
    urlKey: "",
    lessonId: -1,
    errorMessage: "",
  },
  dataSource: null,
};

export default function deckCardsReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_DECK_CARDS_REQUEST:
      return update(state, {
        state: {
          request: { $set: Consts.REQUEST_WAITING },
          urlKey: { $set: action.urlKey },
          lessonId: { $set: action.lessonId },
        },
        dataSource: { $set: null },
      });

    case ActionTypes.GET_DECK_CARDS_SUCCESS: {
      if (state.state.urlKey !== action.urlKey || state.state.lessonId !== action.lessonId) {
        return state;
      }
      const data = action.data;
      return update(state, {
        state: {
          request: { $set: Consts.REQUEST_SUCCESS },
          errorMessage: { $set: "" },
        },
        dataSource: { $set: data },
      });
    }

    case ActionTypes.GET_DECK_CARDS_FAILURE:
      if (state.state.urlKey !== action.urlKey || state.state.lessonId !== action.lessonId) {
        return state;
      }

      return update(state, {
        state: {
          request: { $set: Consts.REQUEST_FAILURE },
          errorMessage: { $set: action.errorMessage },
        },
        dataSource: { $set: null },
      });

    default:
      return state;
  }
}
