import * as ActionTypes from "../constants/ActionTypes";
import * as Api from "../Api";
import {processHttpError} from "./utils";
import * as Strings from "../constants/Strings";

export function getCBTClassList() {
    return (dispatch) => {
        dispatch({type: ActionTypes.GET_CBT_CLASS_LIST_REQUEST});
        Api.getCBTClassList().then(response => {
            dispatch({type: ActionTypes.GET_CBT_CLASS_LIST_SUCCESS, data: response.data});
        }).catch(error => {
            processHttpError(dispatch, error);
            dispatch({type: ActionTypes.GET_CBT_CLASS_LIST_FAILURE, errorMessage: "CBT 시험종목 불러오기에 실패했습니다."});
        });
    };
}

export function getCBTSubjectList() {
    return (dispatch) => {
        dispatch({type: ActionTypes.GET_CBT_SUBJECT_LIST_REQUEST});
        Api.getCBTSubjectList().then(response => {
            dispatch({type: ActionTypes.GET_CBT_SUBJECT_LIST_SUCCESS, data: response.data});
        }).catch(error => {
            processHttpError(dispatch, error);
            dispatch({type: ActionTypes.GET_CBT_SUBJECT_LIST_FAILURE, errorMessage: "CBT 과목 불러오기에 실패했습니다."});
        });
    };
}

export function getCBTTestList() {
    return (dispatch) => {
        dispatch({type: ActionTypes.GET_CBT_TEST_LIST_REQUEST});
        Api.getCBTList().then(response => {
            dispatch({type: ActionTypes.GET_CBT_TEST_LIST_SUCCESS, data: response.data});
        }).catch(error => {
            processHttpError(dispatch, error);
            dispatch({type: ActionTypes.GET_CBT_TEST_LIST_FAILURE, errorMessage: "CBT 테스트 리스트 불러오기에 실패했습니다."});
        });
    };
}

export function getCBTDetail(id) {
    return (dispatch) => {
        dispatch({type: ActionTypes.GET_CBT_DETAIL_REQUEST, id});
        Api.getCBTDetail(id).then(response => {
            dispatch({type: ActionTypes.GET_CBT_DETAIL_SUCCESS, data: response.data, id});
        }).catch(error => {
            processHttpError(dispatch, error);
            dispatch({type: ActionTypes.GET_CBT_DETAIL_FAILURE, errorMessage: "CBT 테스트 리스트 불러오기에 실패했습니다.", id});
        });
    };
}