/**
 * Created by kimchangduk on 2017-10-27.
 */

import React from 'react';
import PropTypes from 'prop-types';

class Api extends React.Component {
    render() {
        return (<div>
            <iframe src="https://test.api.membox.co.kr/swagger-ui.html" frameBorder={0} style={styles.iframe}/>
        </div>);
    }
}

const styles = {
    iframe: {width: '100%', height: 1000}
};

export default Api;