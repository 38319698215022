/**
 * Created by kimchangduk on 2017-07-23.
 */
import {logout} from './user';

export function processHttpError(dispatch, error) {
    if (error && error.response && error.response.status === 401) {
        logout('/login')(dispatch);
    }
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(error);
}