/**
 * Created by kimchangduk on 2017-08-06.
 */

import { ActionTypes, Consts } from "../constants";
import update from "immutability-helper";

const initialState = {
  boardList: {
    get: {
      state: {
        request: Consts.REQUEST_NONE,
        errorMessage: "",
        page: 0,
        last: false,
      },
      dataSource: null,
    },
  },
  boardItem: {
    get: { state: { request: Consts.REQUEST_NONE, errorMessage: "" }, dataSource: null },
    post: { state: { request: Consts.REQUEST_NONE, errorMessage: "" }, dataSource: null },
    delete: { state: { request: Consts.REQUEST_NONE, errorMessage: "" } },
    edit: { state: { request: Consts.REQUEST_NONE, errorMessage: "" } },
  },
  comment: {
    post: { state: { request: Consts.REQUEST_NONE, errorMessage: "" } },
    delete: { state: { request: Consts.REQUEST_NONE, errorMessage: "" } },
  },
};

export default function boardReducer(state = initialState, action) {
  switch (action.type) {
    //<editor-fold desc="게시글 리스트 가져오기">
    case ActionTypes.GET_BOARD_LIST_REQUEST:
      return update(state, {
        boardList: {
          get: {
            state: {
              request: { $set: Consts.REQUEST_WAITING },
              page: { $set: action.page },
              errorMessage: { $set: "" },
            },
          },
        },
      });
    case ActionTypes.GET_BOARD_LIST_SUCCESS: {
      const data = action.data;
      let newContent;
      if (action.page !== 0 && state.boardList.get.dataSource) {
        newContent = state.boardList.get.dataSource.concat(data.content);
      } else {
        newContent = data.content;
      }

      return update(state, {
        boardList: {
          get: {
            state: {
              request: { $set: Consts.REQUEST_SUCCESS },
              last: { $set: data.last },
            },
            dataSource: { $set: newContent },
          },
        },
      });
    }
    case ActionTypes.GET_BOARD_LIST_FAILURE:
      return update(state, {
        boardList: {
          get: {
            state: {
              request: { $set: Consts.REQUEST_FAILURE },
              errorMessage: { $set: action.errorMessage },
            },
            dataSource: { $set: null },
          },
        },
      });
    //</editor-fold>

    //<editor-fold desc="게시글 가져오기">
    case ActionTypes.GET_BOARD_ITEM_VIEW_REQUEST:
      return update(state, { boardItem: { get: { state: { request: { $set: Consts.REQUEST_WAITING }, errorMessage: { $set: "" } } } } });
    case ActionTypes.GET_BOARD_ITEM_VIEW_SUCCESS:
      return update(state, { boardItem: { get: { state: { request: { $set: Consts.REQUEST_SUCCESS } }, dataSource: { $set: action.data } } } });
    case ActionTypes.GET_BOARD_ITEM_VIEW_FAILURE:
      return update(state, { boardItem: { get: { state: { request: { $set: Consts.REQUEST_FAILURE }, errorMessage: { $set: action.errorMessage } } } } });
    //</editor-fold>

    //<editor-fold desc="게시글 등록">
    case ActionTypes.POST_BOARD_ITEM_REQUEST:
      return update(state, { boardItem: { post: { state: { request: { $set: Consts.REQUEST_WAITING }, errorMessage: { $set: "" } } } } });
    case ActionTypes.POST_BOARD_ITEM_SUCCESS:
      return update(state, { boardItem: { post: { state: { request: { $set: Consts.REQUEST_SUCCESS } }, dataSource: { $set: action.data } } } });
    case ActionTypes.POST_BOARD_ITEM_FAILURE:
      return update(state, {
        boardItem: { post: { state: { request: { $set: Consts.REQUEST_FAILURE }, errorMessage: { $set: action.errorMessage } } }, dataSource: { $set: null } },
      });
    //</editor-fold>

    //<editor-fold desc="게시글 수정">
    case ActionTypes.EDIT_BOARD_ITEM_REQUEST:
      return update(state, { boardItem: { edit: { state: { request: { $set: Consts.REQUEST_WAITING }, errorMessage: { $set: "" } } } } });
    case ActionTypes.EDIT_BOARD_ITEM_SUCCESS:
      return update(state, { boardItem: { edit: { state: { request: { $set: Consts.REQUEST_SUCCESS } } } } });
    case ActionTypes.EDIT_BOARD_ITEM_FAILURE:
      return update(state, { boardItem: { edit: { state: { request: { $set: Consts.REQUEST_FAILURE }, errorMessage: { $set: action.errorMessage } } } } });
    //</editor-fold>

    //<editor-fold desc="게시글 삭제">
    case ActionTypes.DELETE_BOARD_ITEM_REQUEST:
      return update(state, { boardItem: { delete: { state: { request: { $set: Consts.REQUEST_WAITING }, errorMessage: { $set: "" } } } } });
    case ActionTypes.DELETE_BOARD_ITEM_SUCCESS:
      return update(state, { boardItem: { delete: { state: { request: { $set: Consts.REQUEST_SUCCESS } } } } });
    case ActionTypes.DELETE_BOARD_ITEM_FAILURE:
      return update(state, { boardItem: { delete: { state: { request: { $set: Consts.REQUEST_FAILURE }, errorMessage: { $set: action.errorMessage } } } } });
    //</editor-fold>

    //<editor-fold desc="댓글 등록">
    case ActionTypes.POST_BOARD_ITEM_COMMENT_REQUEST:
      return update(state, { comment: { post: { state: { request: { $set: Consts.REQUEST_WAITING }, errorMessage: { $set: "" } } } } });
    case ActionTypes.POST_BOARD_ITEM_COMMENT_SUCCESS:
      return update(state, { comment: { post: { state: { request: { $set: Consts.REQUEST_SUCCESS } } } } });
    case ActionTypes.POST_BOARD_ITEM_COMMENT_FAILURE:
      return update(state, { comment: { post: { state: { request: { $set: Consts.REQUEST_FAILURE }, errorMessage: { $set: action.errorMessage } } } } });
    //</editor-fold>

    //<editor-fold desc="댓글 삭제">
    case ActionTypes.DELETE_BOARD_ITEM_COMMENT_REQUEST:
      return update(state, { comment: { delete: { state: { request: { $set: Consts.REQUEST_WAITING }, errorMessage: { $set: "" } } } } });
    case ActionTypes.DELETE_BOARD_ITEM_COMMENT_SUCCESS:
      return update(state, { comment: { delete: { state: { request: { $set: Consts.REQUEST_SUCCESS } } } } });
    case ActionTypes.DELETE_BOARD_ITEM_COMMENT_FAILURE:
      return update(state, { comment: { delete: { state: { request: { $set: Consts.REQUEST_FAILURE }, errorMessage: { $set: action.errorMessage } } } } });
    //</editor-fold>

    default:
      return state;
  }
}
