import React, {Component} from 'react';
import AppHistory from '../../../history';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {logout} from '../../../actions/user';
import {hasChangedRequestToSuccess} from '../../../utils';

class Page403 extends Component {
    static propTypes = {
        actions: PropTypes.object,
        logoutRequest: PropTypes.string
    };

    static StateToProps = (state) => {
        return {
            logoutRequest: state.user.state.logoutRequest
        };
    };

    static DispatchToProps = (dispatch) => {
        return {
            actions: {
                logout: () => {
                    dispatch(logout());
                }
            }
        };
    };

    componentWillReceiveProps(nextProps) {
        if (hasChangedRequestToSuccess(this.props.logoutRequest, nextProps.logoutRequest)) {
            AppHistory.moveLoginPage();
        }
    }

    onLogoutClick = () => {
        this.props.actions.logout();
        // 메인페이지로 이동시키게 수정
    };

    render() {
        return (
            <div className="app flex-row align-items-center">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="clearfix">
                                <h1 className="float-left display-3 mr-4">403</h1>
                                <h4 className="pt-3">Forbiden</h4>
                                <p className="text-muted">You are not administrator.</p>
                            </div>
                            <div style={{textAlign: 'center'}}>
                                <button className="btn btn-info" type="button" onClick={this.onLogoutClick}>logout</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(Page403.StateToProps, Page403.DispatchToProps)(Page403);