import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {logout} from '../../actions/user';
import {Dropdown, DropdownMenu, DropdownItem} from 'reactstrap';
import PersonIcon from '../../assets/img/avatars/person.png';

class Header extends Component {
    static propTypes = {
        actions: PropTypes.object,
        user: PropTypes.object
    };

    static StateToProps = (state) => {
        return {
            user: state.user.dataSource
        };
    };

    static DispatchToProps = (dispatch) => {
        return {
            actions: {
                logout: () => {
                    dispatch(logout());
                }
            }
        };
    };

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false
        };
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    sidebarToggle(e) {
        e.preventDefault();
        document.body.classList.toggle('sidebar-hidden');
    }

    mobileSidebarToggle(e) {
        e.preventDefault();
        document.body.classList.toggle('sidebar-mobile-show');
    }

    onLogoutClick = () => {
        this.props.actions.logout();
    };

    render() {
        return (
            <header className="app-header navbar">
                <button className="navbar-toggler mobile-sidebar-toggler d-lg-none" type="button" onClick={this.mobileSidebarToggle}>&#9776;</button>
                <a className="navbar-brand" href="#"/>
                <ul className="nav navbar-nav d-md-down-none">
                    <li className="nav-item">
                        <button className="nav-link navbar-toggler sidebar-toggler" type="button" onClick={this.sidebarToggle}>&#9776;</button>
                    </li>
                </ul>
                <ul className="nav navbar-nav ml-auto">
                    <li className="nav-item">
                        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                            <button onClick={this.toggle} className="nav-link dropdown-toggle" data-toggle="dropdown" type="button" aria-haspopup="true" aria-expanded={this.state.dropdownOpen}>
                                <img src={PersonIcon} className="img-avatar" alt="admin@bootstrapmaster.com"/>
                                <span className="d-md-down-none">{this.props.user ? this.props.user.email : ''}</span>
                            </button>
                            <DropdownMenu className="dropdown-menu-right">
                                <DropdownItem onClick={this.onLogoutClick}><i className="fa fa-lock"/> Logout</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </li>
                </ul>
            </header>
        );
    }
}

export default connect(Header.StateToProps, Header.DispatchToProps)(Header);
