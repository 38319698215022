/**
 * Created by kimchangduk on 2017-7-25.
 */

import { ActionTypes, Consts } from "../constants";
import update from "immutability-helper";

const initialState = {
  classList: {
    state: {
      request: Consts.REQUEST_NONE,
      errorMessage: "",
    },
    dataSource: [],
  },
  subjectList: {
    state: {
      request: Consts.REQUEST_NONE,
      errorMessage: "",
    },
    dataSource: [],
  },
  testList: {
    state: {
      request: Consts.REQUEST_NONE,
      errorMessage: "",
      lastRequestTime: 0,
    },
    dataSource: [],
  },
  testDetail: {
    state: {
      id: -1,
      request: Consts.REQUEST_NONE,
    },
    dataSource: null,
  },
};

export default function deckCardsReducer(state = initialState, action) {
  switch (action.type) {
    //<editor-fold desc="Get CBT Class List">
    case ActionTypes.GET_CBT_CLASS_LIST_REQUEST:
      return update(state, {
        classList: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            errorMessage: { $set: "" },
          },
        },
      });

    case ActionTypes.GET_CBT_CLASS_LIST_SUCCESS: {
      return update(state, {
        classList: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
            errorMessage: { $set: "" },
          },
          dataSource: { $set: action.data },
        },
      });
    }

    case ActionTypes.GET_CBT_CLASS_LIST_FAILURE:
      return update(state, {
        classList: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            errorMessage: { $set: action.errorMessage },
          },
          dataSource: { $set: [] },
        },
      });
    //</editor-fold>

    //<editor-fold desc="Get CBT Subject List">
    case ActionTypes.GET_CBT_SUBJECT_LIST_REQUEST:
      return update(state, {
        subjectList: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            errorMessage: { $set: "" },
          },
        },
      });

    case ActionTypes.GET_CBT_SUBJECT_LIST_SUCCESS: {
      return update(state, {
        subjectList: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
            errorMessage: { $set: "" },
          },
          dataSource: { $set: action.data },
        },
      });
    }

    case ActionTypes.GET_CBT_SUBJECT_LIST_FAILURE:
      return update(state, {
        subjectList: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            errorMessage: { $set: action.errorMessage },
          },
          dataSource: { $set: [] },
        },
      });
    //</editor-fold>

    //<editor-fold desc="Get CBT Test List">
    case ActionTypes.GET_CBT_TEST_LIST_REQUEST:
      return update(state, {
        testList: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            errorMessage: { $set: "" },
            lastRequestTime: { $set: new Date() },
          },
        },
      });

    case ActionTypes.GET_CBT_TEST_LIST_SUCCESS: {
      return update(state, {
        testList: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
            errorMessage: { $set: "" },
          },
          dataSource: { $set: action.data },
        },
      });
    }

    case ActionTypes.GET_CBT_TEST_LIST_FAILURE:
      return update(state, {
        testList: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            errorMessage: { $set: action.errorMessage },
          },
          dataSource: { $set: [] },
        },
      });
    //</editor-fold>

    case ActionTypes.GET_CBT_DETAIL_REQUEST:
      return update(state, {
        testDetail: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            id: { $set: action.id },
          },
          dataSource: { $set: null },
        },
      });

    case ActionTypes.GET_CBT_DETAIL_SUCCESS: {
      if (state.testDetail.state.id !== action.id) {
        return;
      }
      return update(state, {
        testDetail: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
          },
          dataSource: { $set: action.data },
        },
      });
    }

    case ActionTypes.GET_CBT_DETAIL_FAILURE: {
      if (state.testDetail.state.id !== action.id) {
        return;
      }
      return update(state, {
        testDetail: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
          },
          dataSource: { $set: null },
        },
      });
    }

    default:
      return state;
  }
}
