/**
 * Created by kimchangduk on 2018-02-21.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {NavLink} from "react-router-dom";

class MenuGroup extends React.Component {
    static propTypes = {
        actions: PropTypes.object,
        iconClassName: PropTypes.string,
        label: PropTypes.string,
        subLinks: PropTypes.array
    };

    state = {
        open: true
    };

    toggleOpen = () => {
        this.setState({open: !this.state.open});
    };

    render() {
        const liArray = [<li className={"nav-item"} key={0}>
            <a style={styles.pointerCursor} className={`nav-link`} onClick={this.toggleOpen}>
                <i className={this.props.iconClassName}/> {this.props.label}
                <i style={styles.plusIcon} className={this.state.open ? "icon-minus" : "icon-plus"}/>
            </a>
        </li>];

        if (this.state.open && this.props.subLinks) {
            let key = 1;
            for (let subLink of this.props.subLinks) {
                liArray.push(<li className="nav-item" key={key++}>
                    <NavLink exact={subLink.exact} to={subLink.link} className="nav-link" activeClassName="active" style={styles.subLinkIcon}>
                        {subLink.iconClassName ? <i className={subLink.iconClassName}/> : '└'} {subLink.label}
                    </NavLink>
                </li>);
            }
        }

        return liArray;
    }
}

const styles = {
    pointerCursor: {
        cursor: 'pointer',
        userSelect: 'none'
    },
    subLinkIcon: {
        paddingLeft: 35
    },
    plusIcon: {
        float: 'right',
        marginTop: 3
    }
};

export default MenuGroup;