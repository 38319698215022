/**
 * Created by kimchangduk on 2017-11-23.
 */

import React from "react";
import update from "immutability-helper";
import PropTypes from "prop-types";
import { Card, CardBody, CardHeader, Input } from "reactstrap";
import Radium from "radium";
import "./Move.scss";
import DialogManager from "../../dialogs/DialogManager";
import * as Api from "../../Api";
import { getMessageFromResponse } from "../../utils";
import { Avatar, IconButton, List, ListItem, ListItemText } from "@material-ui/core";

class Move extends React.Component {
  static propTypes = {
    selected: PropTypes.array,
    deck: PropTypes.object,
    lessonId: PropTypes.number,
    onUpdate: PropTypes.func,
    multipleLessons: PropTypes.bool,
  };

  static defaultProps = {
    multipleLessons: false,
  };

  state = {
    targetItemPath: null,
    lessons: "",
  };

  equalsPath = (array1, array2) => {
    if (!array1 || !array2 || array1.length !== array2.length) {
      return false;
    }

    for (let i in array1) {
      if (array1[i] != array2[i]) {
        return false;
      }
    }
    return true;
  };

  getArray(element, array, depth = 0, path = [], includeLesson = false) {
    if (element.packages) {
      for (let packageId in element.packages) {
        const _package = element.packages[packageId];
        array.push({ item: _package, type: "package", depth: depth + 1, path: update(path, { $push: [packageId] }) });
        this.getArray(_package, array, depth + 1, update(path, { $push: [packageId] }), includeLesson);
      }
    }
    if (includeLesson && element.lessons) {
      for (let lesson of element.lessons) {
        array.push({ item: lesson, type: "lesson", depth: depth + 1, path });
      }
    }
    return array;
  }

  getAllLessons = (root = this.props.deck.element, array = []) => {
    if (root.packages) {
      for (let _package of root.packages) {
        this.getAllLessons(_package, array);
      }
    }

    if (root.lessons) {
      for (let lesson of root.lessons) {
        array.push(lesson);
      }
    }
    return array;
  };

  onTargetSelect = (path) => {
    if (!this.equalsPath(path, this.state.targetItemPath)) {
      this.setState({ targetItemPath: path });
    } else {
      this.setState({ targetItemPath: null });
    }
  };

  renderElements = (element) => {
    const array = [];
    this.getArray(element, array, 0);
    return (
      <div>
        {this.renderItem({ item: this.props.deck, type: "root", depth: 0, path: [] })}
        {array.map((item, key) => this.renderItem(item, key))}
      </div>
    );
  };

  renderItem = (item, key) => {
    const isSource = this.equalsPath(this.props.selected, item.path);
    const isTarget = this.equalsPath(this.state.targetItemPath, item.path);
    return (
      <div
        key={key}
        className={`item ${isSource ? "selected" : ""} ${isTarget ? "target-selected" : ""}`}
        onClick={() => {
          this.onTargetSelect(item.path);
        }}
        style={{ paddingLeft: item.depth * 25 + 10 }}
      >
        {item.item.name}
        {item.type === "root" ? " (root)" : ""} {isSource ? <span className="source-label">(source)</span> : undefined}{" "}
        {isTarget ? <span className="target-label">(target)</span> : undefined}
      </div>
    );
  };

  removeAndGetLesson = (id, element) => {
    if (element.lessons) {
      const lessonIndex = element.lessons.findIndex((a) => a.id === id);
      if (lessonIndex >= 0) {
        const lesson = element.lessons[lessonIndex];
        element.lessons.splice(lessonIndex, 1);
        return lesson;
      }
    }
    if (element.packages) {
      for (let _package of element.packages) {
        let lesson = this.removeAndGetLesson(id, _package);
        if (lesson) {
          return lesson;
        }
      }
    }
    return null;
  };

  callEditNewElement = (element) => {
    Api.editDeckElement(this.props.deck.urlKey, element)
      .then((response) => {
        if (this.props.onUpdate) {
          this.props.onUpdate(this.state.targetItemPath);
        }
      })
      .catch((error) => {
        DialogManager.alert(getMessageFromResponse(error.response, "이동에 실패했습니다."));
      });
  };

  moveSourceToTarget = () => {
    if (this.props.lessonId === undefined || this.props.lessonId === null) {
      if (this.equalsPath(this.props.selected, this.state.targetItemPath)) {
        DialogManager.alert("자신의 자식으로 이동할 수 없습니다.");
        return;
      } else if (this.equalsPath(this.props.selected.slice(0, this.props.selected.length - 1), this.state.targetItemPath)) {
        DialogManager.alert("현재 위치와 동일합니다.");
        return;
      }
    } else {
      if (this.equalsPath(this.props.selected, this.state.targetItemPath)) {
        DialogManager.alert("현재 위치와 동일합니다.");
        return;
      }
    }

    const updateValue = update(this.props.deck.element, {});
    let source = updateValue;
    for (let path of this.props.selected) {
      source = source.packages[path];
    }

    //<editor-fold desc="Source 제거 + Source가 Lesson이면 Source를 Lesson 으로 설정">
    if (this.props.lessonId !== null && this.props.lessonId !== undefined) {
      const sourceIndex = source.lessons.findIndex((a) => a.id === this.props.lessonId);
      if (sourceIndex >= 0) {
        let lesson = source.lessons[sourceIndex];
        source.lessons.splice(sourceIndex, 1);
        source = lesson;
      } else {
        DialogManager.alert("source lesson을 찾을 수 없습니다.");
        return;
      }
    } else {
      let parent = updateValue;
      for (let i = 0; i < this.props.selected.length - 1; i++) {
        parent = parent.packages[this.props.selected[i]];
      }
      const sourceIndex = parent.packages.findIndex((a) => a === source);
      if (sourceIndex >= 0) {
        parent.packages.splice(sourceIndex, 1);
      } else {
        DialogManager.alert("source package를 찾을 수 없습니다.");
        return;
      }
    }
    //</editor-fold>

    let target = updateValue;
    for (let path of this.state.targetItemPath) {
      target = target.packages[path];
    }
    if (this.props.lessonId !== null && this.props.lessonId !== undefined) {
      if (target === updateValue) {
        DialogManager.alert("lesson을 root에 추가할 수 없습니다. package를 target으로 설정해주세요.");
        return;
      }
      if (!target.lessons) {
        target.lessons = [];
      }
      target.lessons.push(source);
    } else {
      if (!target.packages) {
        target.packages = [];
      }
      target.packages.push(source);
    }
    this.callEditNewElement(updateValue);
  };

  moveLessonsToTarget = () => {
    if (this.state.targetItemPath.length <= 0) {
      DialogManager.alert("lesson을 root에 추가할 수 없습니다. package를 target으로 설정해주세요.");
      return;
    }

    const idStrings = this.state.lessons.split("\n");
    let lessons = [];
    const updateValue = update(this.props.deck.element, {});

    for (let idString of idStrings) {
      if (!idString) {
        continue;
      }

      const id = parseInt(idString);
      if (isNaN(id)) {
        DialogManager.alert(`${idString}은 숫자가 아닙니다.`);
        return;
      }
      const lesson = this.removeAndGetLesson(id, updateValue);
      if (!lesson) {
        DialogManager.alert(`${idString}은 숫자가 아닙니다.`);
        return;
      }
      lessons.push(lesson);
    }

    let target = updateValue;
    for (let path of this.state.targetItemPath) {
      target = target.packages[path];
    }
    if (!target.lessons) {
      target.lessons = [];
    }
    for (let lesson of lessons) {
      target.lessons.push(lesson);
    }
    this.callEditNewElement(updateValue);
  };

  onMoveClick = () => {
    if (!this.state.targetItemPath) {
      DialogManager.alert("이동할 대상을 선택해주세요.");
      return;
    }

    if (this.props.multipleLessons) {
      this.moveLessonsToTarget();
    } else {
      this.moveSourceToTarget();
    }
  };

  onInputChange = (e) => {
    this.setState({ lessons: e.target.value });
  };

  onLessonClick = (item) => {
    let str = this.state.lessons;
    str = str.trim();
    if (str) {
      str += "\n";
    }
    str += item.id;
    this.setState({ lessons: str });
  };

  render() {
    return (
      <Card className="move-component">
        <CardHeader>이동</CardHeader>
        <CardBody>
          {this.props.multipleLessons ? (
            <div style={{ marginBottom: 20 }}>
              <p>옮길 레슨 id들을 입력하세요.</p>
              <div className="half-div">
                <div className="lesson-list">
                  <List padding={0}>
                    {this.getArray(this.props.deck.element, [], 0, [], true).map((item, key) => {
                      return (
                        <ListItem
                          className="list-item"
                          key={key}
                          onClick={() => {
                            if (item.type === "lesson") this.onLessonClick(item.item);
                          }}
                        >
                          <p style={{ paddingLeft: item.depth * 20 }}>{`(${item.type}) ${item.type === "lesson" ? item.item.id + ". " : ""} ${
                            item.item.name
                          }`}</p>
                        </ListItem>
                      );
                    })}
                  </List>
                </div>
              </div>
              <div className="half-div">
                <Input className="lessons-input" type="textarea" value={this.state.lessons} onChange={this.onInputChange} />
              </div>
            </div>
          ) : undefined}
          <div style={{ marginBottom: 20 }}>
            <p>옮길 대상 패키지를 선택하세요.</p>
            <div className="list">{this.renderElements(this.props.deck.element)}</div>
          </div>
          <button type="button" className="btn btn-block btn-primary" onClick={this.onMoveClick}>
            이동
          </button>
        </CardBody>
      </Card>
    );
  }
}

export default Radium(Move);
