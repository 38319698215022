/**
 * Created by kimchangduk on 2017-7-25.
 */

import { ActionTypes, Consts } from "../constants";
import update from "immutability-helper";

const initialState = {
  delete: {
    state: {
      itemType: "",
      urlKey: "",
      request: Consts.REQUEST_NONE,
      errorMessage: "",
    },
    dataSource: null,
  },
  post: {
    state: {
      itemType: "",
      urlKey: "",
      request: Consts.REQUEST_NONE,
      errorMessage: "",
    },
    dataSource: null,
  },
};

export default function hashtagReducer(state = initialState, action) {
  switch (action.type) {
    //<editor-fold desc="Post Hashtag">
    case ActionTypes.ADD_HASHTAG_REQUEST:
      return update(state, {
        post: {
          state: {
            itemType: { $set: action.itemType },
            urlKey: { $set: action.urlKey },
            request: { $set: Consts.REQUEST_WAITING },
            errorMessage: { $set: "" },
          },
        },
      });

    case ActionTypes.ADD_HASHTAG_SUCCESS: {
      const data = action.data;
      if (state.post.state.itemType !== action.itemType || state.post.state.urlKey !== action.urlKey) {
        return state;
      }

      return update(state, {
        post: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
          },
          dataSource: { $set: data },
        },
      });
    }

    case ActionTypes.ADD_HASHTAG_FAILURE:
      if (state.post.state.itemType !== action.itemType || state.post.state.urlKey !== action.urlKey) {
        return state;
      }

      return update(state, {
        post: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            errorMessage: { $set: action.errorMessage },
          },
        },
      });
    //</editor-fold>

    //<editor-fold desc="delete Hashtag">
    case ActionTypes.REMOVE_HASHTAG_REQUEST:
      return update(state, {
        post: {
          state: {
            itemType: { $set: action.itemType },
            urlKey: { $set: action.urlKey },
            request: { $set: Consts.REQUEST_WAITING },
            errorMessage: { $set: "" },
          },
        },
      });

    case ActionTypes.REMOVE_HASHTAG_SUCCESS: {
      const data = action.data;
      if (state.post.state.itemType !== action.itemType || state.post.state.urlKey !== action.urlKey) {
        return state;
      }

      return update(state, {
        post: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
          },
          dataSource: { $set: data },
        },
      });
    }

    case ActionTypes.REMOVE_HASHTAG_FAILURE:
      if (state.post.state.itemType !== action.itemType || state.post.state.urlKey !== action.urlKey) {
        return state;
      }

      return update(state, {
        post: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            errorMessage: { $set: action.errorMessage },
          },
        },
      });
    //</editor-fold>

    default:
      return state;
  }
}
