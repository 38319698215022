/**
 * Created by kimchangduk on 2017-08-13.
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";

class Loader extends React.Component {
  static propTypes = {
    actions: PropTypes.object,
    style: PropTypes.object,
  };

  static StateToProps = (state, ownProps) => {
    return {};
  };

  static DispatchToProps = (dispatch, ownProps) => {
    return {
      actions: {},
    };
  };

  render() {
    return <CircularProgress style={this.props.style} />;
  }
}

export default connect(Loader.StateToProps, Loader.DispatchToProps)(Loader);
