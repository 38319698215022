/**
 * Created by kimchangduk on 2017-12-30.
 */

import React from "react";
import ReactDOM from "react-dom";
import Radium from "radium";
import PropTypes from "prop-types";
import { Input } from "reactstrap";
import * as Api from "../Api";
import { scrollIntoView } from "../utils";

class HashtagSearch extends React.Component {
  static propTypes = {
    onSelect: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.string,
  };

  state = {
    visibleList: false,
    searchResults: [],
    selectionIndex: -1,
  };

  searchTimeout = null;

  onKeyDown = (e) => {
    switch (e.keyCode) {
      // 방향 위
      case 38: {
        const newIndex = Math.max(-1, this.state.selectionIndex - 1);
        this.setState({ selectionIndex: newIndex });
        this.scrollSearchResultsTo(newIndex);
        break;
      }

      // 방향 아래
      case 40: {
        const newIndex = Math.min(this.state.searchResults.length - 1, this.state.selectionIndex + 1);
        this.setState({ selectionIndex: newIndex });
        this.scrollSearchResultsTo(newIndex);
        break;
      }

      case 13: {
        const searchResult = this.state.searchResults[this.state.selectionIndex];
        if (searchResult && this.props.onSelect) {
          this.props.onSelect(searchResult);
        }

        const inputComponent = this.refs.input;
        if (inputComponent) {
          const element = ReactDOM.findDOMNode(inputComponent);
          if (element) {
            element.blur();
          }
        }
        break;
      }
    }
  };

  scrollSearchResultsTo = (index) => {
    const container = this.refs["search-results"];
    const item = this.refs[`search-result-${index}`];
    if (!item || !container) {
      return;
    }
    scrollIntoView(item, container);
  };

  onChange = (e) => {
    const searchText = e.target.value;
    if (this.props.onChange) {
      this.props.onChange(searchText);
    }

    if (this.searchTimeout) {
      window.clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = window.setTimeout(() => {
      this.search(searchText);
    }, 300);
  };

  search = (text) => {
    if (!text) {
      this.setState({ searchResults: [], selectionIndex: -1 });
      return;
    }

    Api.searchHashtag(text)
      .then((response) => {
        this.setState({ searchResults: response.data, selectionIndex: -1 });
      })
      .catch((error) => {
        this.setState({ searchResults: [], selectionIndex: -1 });
      });
  };

  onFocus = () => {
    this.setState({ visibleList: true });
  };

  onBlur = () => {
    setTimeout(() => {
      this.setState({ visibleList: false });
    }, 300);
  };

  render() {
    return (
      <div style={styles.container}>
        <Input
          ref="input"
          type="text"
          placeholder="Search Hashtag"
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          value={this.props.value}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          style={styles.input}
        />
        {this.state.visibleList && this.state.searchResults.length > 0 ? (
          <div ref="search-results" style={styles.searchResults}>
            {this.state.searchResults.map((item, key) => {
              return (
                <div
                  onClick={() => {
                    if (this.props.onSelect) {
                      this.props.onSelect(item);
                    }
                  }}
                  ref={`search-result-${key}`}
                  key={key}
                  style={[styles.searchResult, this.state.selectionIndex === key ? styles.selectedSearchResult : false]}
                >
                  {item.tag}
                </div>
              );
            })}
          </div>
        ) : undefined}
      </div>
    );
  }
}

const styles = {
  container: {
    position: "relative",
  },
  input: {},
  searchResults: {
    position: "absolute",
    left: 0,
    right: 0,
    top: "100%",
    maxHeight: 300,
    overflowY: "auto",
    background: "#FFFFFF",
    border: "1px solid #0D47A1",
  },
  searchResult: {
    padding: 5,
    cursor: "pointer",
  },
  selectedSearchResult: {
    background: "#81D4FA",
  },
};

export default Radium(HashtagSearch);
