/**
 * Created by kimchangduk on 2017-10-20.
 */

import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Input} from "reactstrap";
import {Consts} from '../constants';
import {getTemplates} from '../actions/template';

class CardTemplateSelectField extends React.Component {
    static propTypes = {
        value: PropTypes.number,
        name: PropTypes.string,
        onChange: PropTypes.func,
        templates: PropTypes.array,
        autoReloadTemplates: PropTypes.bool,
        actions: PropTypes.object
    };

    static defaultProps = {
        autoReloadTemplates: false
    };

    static StateToProps = (state) => {
        return {
            templates: state.template.dataSource,
        };
    };

    static DispatchToProps = (dispatch) => {
        return {
            actions: {
                getTemplates: () => {
                    dispatch(getTemplates());
                }
            }
        };
    };

    state = {};

    componentDidMount() {
        if (this.props.autoReloadTemplates && !this.props.templates)
            this.props.actions.getTemplates();
    }

    onTemplateChange = (e) => {
        let newId = e.target.value;
        if (typeof(newId) === 'string') {
            newId = parseInt(newId);
        }

        const template = this.props.templates.find(template => template.id === newId);
        if (this.props.onChange) {
            this.props.onChange(e, template);
        }
    };

    render() {
        return (<Input type="select" name={this.props.name} value={this.props.value} onChange={this.onTemplateChange}>
            {
                this.props.templates ?
                    this.props.templates.filter(a => a.type === 'DECK').map((item, key) =>
                        (<option key={key} value={item.id}>{item.id}. {item.name}</option>))
                    : undefined
            }
        </Input>);
    }
}

export default connect(CardTemplateSelectField.StateToProps, CardTemplateSelectField.DispatchToProps)(CardTemplateSelectField);