/**
 * Created by kimchangduk on 2017-10-22.
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Card, CardBody, CardHeader, Col, Input, Row } from "reactstrap";
import DialogManager from "../dialogs/DialogManager";
import * as Api from "../Api";
import ListSearch from "../components/ListSearch/ListSearch";
import { getMessageFromResponse } from "../utils";

class Templates extends React.Component {
  static propTypes = {
    actions: PropTypes.object,
  };

  static StateToProps = (state, ownProps) => {
    return {};
  };

  static DispatchToProps = (dispatch, ownProps) => {
    return {
      actions: {},
    };
  };

  state = {
    templates: null,
    editMode: EditTypes.NONE,
    selectedItem: null,
    id: "",
    name: "",
    content: "",
    rows: "",
    specifications: "",
  };

  componentDidMount() {
    this.loadTemplateLists();
  }

  loadTemplateLists() {
    Api.getTemplateLists()
      .then((response) => {
        this.setState({ templates: response.data });
      })
      .catch((error) => {
        DialogManager.alert("템플릿 리스트를 가져올 수 없습니다.");
      });
  }

  rowsToString(rows) {
    if (!rows || rows.length <= 0) {
      return "";
    }
    let result = "";
    for (let i = 0; i < rows.length; i++) {
      if (i > 0) {
        result += "\n";
      }
      result += rows[i];
    }
    return result;
  }

  onAddClick = () => {
    this.setState({
      editMode: EditTypes.ADD,
      id: "",
      name: "",
      content: "",
      rows: "",
      specifications: "",
    });
  };

  onSelect = (item) => {
    this.setState({
      selectedItem: item,
      editMode: EditTypes.MODIFY,
      id: item.id.toString(),
      type: item.type ? item.type : "",
      name: item.name,
      content: item.content ? item.content : "",
      rows: this.rowsToString(item.rows),
      specifications: item.specifications ? JSON.stringify(item.specifications, null, 4) : "",
    });
  };

  onSubmit = () => {
    let specifications;
    if (this.state.specifications) {
      try {
        specifications = JSON.parse(this.state.specifications);
      } catch (error) {
        DialogManager.alert("specifications 데이터가 json 형식이 아닙니다");
        return;
      }
    } else {
      specifications = null;
    }

    const rows = this.stringToRowArray();
    if (this.state.editMode === EditTypes.ADD) {
      if (this.state.id === "" || this.state.id === undefined || this.state.id === null) {
        DialogManager.alert("id를 입력해주세요.");
        return;
      }
      Api.addTemplate(this.state.id, this.state.name, this.state.type, this.state.content, rows, specifications)
        .then((response) => {
          this.loadTemplateLists();
        })
        .catch((error) => {
          DialogManager.alert(getMessageFromResponse(error.response, "추가에 실패했습니다."));
        });
    } else {
      Api.editTemplate(this.state.selectedItem.id, this.state.id, this.state.name, this.state.type, this.state.content, rows, specifications)
        .then((response) => {
          this.loadTemplateLists();
        })
        .catch((error) => {
          DialogManager.alert(getMessageFromResponse(error.response, "수정에 실패했습니다."));
        });
    }
  };

  stringToRowArray = () => {
    const rows = this.state.rows;
    return rows.split("\n").filter((item) => item);
  };

  onInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    return (
      <div>
        <Row>
          <ListSearch
            xs={12}
            md={this.state.editMode === EditTypes.NONE ? 12 : 6}
            dataSource={this.state.templates}
            getName={(item) => `${item.id} ${item.name}`}
            isSelectedItem={(item) => item === this.state.selectedItem}
            onSelect={(item) => {
              this.onSelect(item);
            }}
            onAddClick={() => {
              this.onAddClick();
            }}
            title={"템플릿 리스트"}
          />
          {this.state.editMode === EditTypes.NONE ? undefined : (
            <Col md={6} xs={12}>
              <Card>
                <CardHeader>템플릿 {this.state.editMode === EditTypes.ADD ? "추가" : "수정"}</CardHeader>
                <CardBody className="editor">
                  <div className="data-row">
                    <div className="name">id</div>
                    <div className="value">
                      <Input type="text" name="id" value={this.state.id} onChange={this.onInputChange} />
                    </div>
                  </div>

                  <div className="data-row">
                    <div className="name">type</div>
                    <div className="value">
                      <Input type="select" name="type" value={this.state.type} onChange={this.onInputChange}>
                        <option value="DECK">DECK</option>
                        <option value="CBT_TEST">CBT_TEST</option>
                      </Input>
                    </div>
                  </div>

                  <div className="data-row">
                    <div className="name">name</div>
                    <div className="value">
                      <Input onChange={this.onInputChange} name="name" type="text" className="full" value={this.state.name} />
                    </div>
                  </div>

                  <div className="data-row">
                    <div className="name">content</div>
                    <div className="value">
                      <textarea onChange={this.onInputChange} name="content" className="full-width" value={this.state.content} />
                    </div>
                  </div>

                  <div className="data-row">
                    <div className="name">rows</div>
                    <div className="value">
                      <textarea onChange={this.onInputChange} name="rows" className="full-width" value={this.state.rows} />
                    </div>
                  </div>

                  <div className="data-row">
                    <div className="name">specifications</div>
                    <div className="value">
                      <textarea onChange={this.onInputChange} name="specifications" className="full-width" value={this.state.specifications} />
                    </div>
                  </div>
                  <Button block onClick={this.onSubmit}>
                    {this.state.editMode === EditTypes.ADD ? "추가" : "수정"}
                  </Button>
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

const EditTypes = { NONE: "NONE", ADD: "ADD", MODIFY: "MODIFY" };

export default connect(Templates.StateToProps, Templates.DispatchToProps)(Templates);
