import *  as Api from '../Api';
import {Consts, ActionTypes} from '../constants';
import {getMessageFromResponse} from "../utils";

export function addHashtag(itemType, urlKey, tag) {
    return dispatch => {
        dispatch({type: ActionTypes.ADD_HASHTAG_REQUEST, itemType, urlKey});
        Api.addHashtag(itemType, urlKey, tag).then(response => {
            dispatch({type: ActionTypes.ADD_HASHTAG_SUCCESS, itemType, urlKey, tag, data: response.data});
        }).catch(error => {
            dispatch({type: ActionTypes.ADD_HASHTAG_FAILURE, itemType, urlKey, tag, errorMessage: getMessageFromResponse(error.response, '해시태그 추가에 실패했습니다.')});
        });
    };
}

export function removeHashtag(itemType, urlKey, tag) {
    return dispatch => {
        dispatch({type: ActionTypes.REMOVE_HASHTAG_REQUEST, itemType, urlKey});
        Api.removeHashtag(itemType, urlKey, tag).then(response => {
            dispatch({type: ActionTypes.REMOVE_HASHTAG_SUCCESS, itemType, urlKey, tag, data: response.data});
        }).catch(error => {
            dispatch({type: ActionTypes.REMOVE_HASHTAG_FAILURE, itemType, urlKey, tag, errorMessage: getMessageFromResponse(error.response, '해시태그 삭제에 실패했습니다.')});
        });
    };
}