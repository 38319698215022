/* eslint-disable no-useless-escape */
/**
 * Created by kimchangduk on 2017-04-13.
 */

export const COOKIE_KEY_FCM_TOKEN = "fcm_token";
export const COOKIE_KEY_HOW_TO_USE_NEVER_SHOW = "HOW_TO_USE_NEVER_SHOW";

export const GA_ID = "UA-57633567-1";

export const API_URL = "";

export const REQUEST_NONE = "REQUEST_NONE";
export const REQUEST_WAITING = "REQUEST_WAITING";
export const REQUEST_SUCCESS = "REQUEST_SUCCESS";
export const REQUEST_FAILURE = "REQUEST_FAILURE";

export const SEX_MALE = "MALE";
export const SEX_FEMALE = "FEMALE";

export const MEMORIZE_STATE_READY = "READY";
export const MEMORIZE_STATE_WAIT = "WAIT";

export const Authorities = {
  ROLE_SUPERUSER: "ROLE_SUPERUSER",
  ROLE_SUPERTESTER: "ROLE_SUPERTESTER",
  ROLE_MANAGER: "ROLE_MANAGER",
  ROLE_API_VIEWER: "ROLE_API_VIEWER",
  ROLE_API_TESTER: "ROLE_API_TESTER",
  ROLE_TESTER: "ROLE_TESTER",
};

export const LearnLevels = {
  CHECKING: "CHECKING",
  CHECKED: "CHECKED",
  TIMEOVER: "TIMEOVER",
};

export const DefaultDialogViewButtonTypes = { DEFAULT: "DEFAULT", SUCCESS: "SUCCESS", ERROR: "ERROR" };
export const ConfirmDialogViewButtonTypes = { YES_OR_NO: "YES_OR_NO", CONFIRM_OR_CANCEL: "CONFIRM_OR_CANCEL" };

export const DeckVisibility = {
  NONE: "NONE",
  PUBLIC: "PUBLIC",
  PRIVATE: "PRIVATE",
};

export const ItemTypes = {
  DECK: "DECK",
  CBT_TEST: "CBT_TEST",
  MEMBERSHIP: "MEMBERSHIP",
};

export const EditorTypes = {
  EDIT: "EDIT",
  NEW: "NEW",
};

export const Templates = [
  {
    name: "1",
    id: 1,
    defaultData: {
      front: "",
      back: "",
      example: "",
      voice: "",
    },
  },
  {
    name: "2",
    id: 2,
    defaultData: {
      question: "",
      example: "",
      answer: "",
    },
  },
  {
    name: "3",
    id: 3,
    defaultData: {
      front: "",
      back: "",
    },
  },
  {
    name: "4",
    id: 4,
    defaultData: {
      front: "",
      back: "",
      class: "",
      ex: "",
      ex_kor: "",
      ex_desc: "",
    },
  },
  {
    name: "5",
    id: 5,
    defaultData: {
      front: "",
      frontVoice: "",
      back: ".",
      voice: "",
      playVoice: "",
    },
  },
  {
    name: "6",
    id: 6,
    defaultData: {
      front: "",
      frontVoice: "",
      back: ".",
      voice: "",
      playVoice: "",
    },
  },
  {
    name: "7",
    id: 7,
    defaultData: {
      front: "",
      back: "",
    },
  },
  {
    name: "8 (앞면: 문제+문제지문, 뒷면: 정답+해설)",
    id: 8,
    defaultData: {
      rightTitle: "",
      history: "",
      question: "",
      questionPassage: "",
      answer: "",
      solution: "",
      solutionPassage: "",
      oxQuiz: false,
    },
    convertSpecification: {
      oxQuiz: {
        type: "BOOL",
      },
      question: {
        options: ["CRLF_TO_BR"],
      },
      questionPassage: {
        options: ["CRLF_TO_BR"],
      },
      answer: {
        options: ["CRLF_TO_BR"],
      },
      solution: {
        options: ["CRLF_TO_BR"],
      },
      solutionPassage: {
        options: ["CRLF_TO_BR"],
      },
    },
  },
];
export const TemplateRichTextKeys = {
  [8]: ["question", "questionPassage", "answer", "solution", "solutionPassage"],
  [9]: ["question", "questionPassage", "answer", "solution", "solutionPassage"],
  [10]: ["audios"],
};

export const EditModes = {
  NONE: "NONE",
  ADD: "ADD",
  MODIFY: "MODIFY",
};

export const OrderMode = {
  ORDERED: "ORDERED",
  RANDOM: "RANDOM",
};

export const CouponPublisherTypes = {
  BOOK: "BOOK",
  TEST: "TEST",
  ADMIN: "ADMIN",
};
