/**
 * Created by kimchangduk on 2017-08-08.
 */
import Avatars1 from './1.jpg';
import Avatars2 from './2.jpg';
import Avatars3 from './3.jpg';
import Avatars4 from './4.jpg';
import Avatars5 from './5.jpg';
import Avatars6 from './6.jpg';
import Avatars7 from './7.jpg';
import Avatars8 from './8.jpg';

export default  {
    Avatars1,
    Avatars2,
    Avatars3,
    Avatars4,
    Avatars5,
    Avatars6,
    Avatars7,
    Avatars8,
};