/**
 * Created by kimchangduk on 2017-10-29.
 */

import React from "react";
import PropTypes from "prop-types";
import { Button, Card, CardBody, CardHeader, Col, Input } from "reactstrap";
import * as Api from "../../Api";
import { getMessageFromResponse } from "../../utils";
import DialogManager from "../../dialogs/DialogManager";

class TestPageEditor extends React.Component {
  static propTypes = {
    test: PropTypes.object,
    questions: PropTypes.array,
    onUpdated: PropTypes.func,
  };

  state = {
    questionPage: "",
  };

  componentDidMount() {
    this.initializeState(this.props.test);
  }

  componentWillReceiveProps = (nextProps) => {
    if (this.props.test !== nextProps.test) {
      this.initializeState(nextProps.test);
    }
  };

  initializeState = (item) => {
    if (item) {
      this.setState({
        questionPage: item && item.questionPage ? JSON.stringify(item.questionPage, null, 2) : "",
      });
    } else {
      this.setState({
        questionPage: "",
      });
    }
  };

  onInputChange = (e) => {
    this.setState({
      questionPage: e.target.value,
    });
  };

  onEditClick = () => {
    let questionPageObj = null;
    try {
      questionPageObj = JSON.parse(this.state.questionPage);
    } catch (error) {
      DialogManager.alert("JSON 형식이 아닙니다.");
      return;
    }

    Api.editCBTQuestionPage(this.props.test.id, questionPageObj)
      .then((response) => {
        if (this.props.onUpdated) this.props.onUpdated();
      })
      .catch((error) => {
        DialogManager.alert(getMessageFromResponse(error.response, "페이지 구성 변경에 실패했습니다."));
      });
  };

  onAutoGenClick = (n) => {
    if (this.props.test && this.props.questions) {
      let array = [];
      let pageArray;
      for (let i = 0; i < this.props.questions.length; i++) {
        const question = this.props.questions[i];
        if (i % n === 0) {
          pageArray = [];
          array.push(pageArray);
        }
        pageArray.push(question.id);
      }
      this.setState({ questionPage: JSON.stringify(array, null, 2) });
    }
  };

  render() {
    return (
      <Card>
        <CardHeader>테스트 페이지 구성 설정</CardHeader>
        <CardBody className="editor">
          <div style={styles.marginBottom5}>
            <Input type="textarea" value={this.state.questionPage} onChange={this.onInputChange} style={styles.input} />
          </div>
          <div style={styles.marginBottom5}>
            {[1, 2, 3, 4, 5, 6].map((item, key) => {
              return (
                <Button
                  key={key}
                  style={styles.marginRight5}
                  onClick={() => {
                    this.onAutoGenClick(item);
                  }}
                >
                  {item} 문제씩 구분
                </Button>
              );
            })}
          </div>
          <div style={styles.marginBottom5}>
            <Button color="primary" block onClick={this.onEditClick}>
              수정
            </Button>
          </div>
        </CardBody>
      </Card>
    );
  }
}

const styles = {
  input: { minHeight: 200 },
  marginBottom5: {
    marginBottom: 5,
  },
  marginRight5: {
    marginRight: 5,
  },
};

export default TestPageEditor;
