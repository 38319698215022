/**
 * Created by kimchangduk on 2017-08-13.
 */

import React from "react";
import PropTypes from "prop-types";
import { Avatar, IconButton } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { Card, CardHeader, Col, Input } from "reactstrap";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import DialogManager from "../../dialogs/DialogManager";
import ListReorderDialog from "../../dialogs/ListReorderDialog";
import update from "immutability-helper";

class ListSearch extends React.Component {
  static propTypes = {
    onReorderRequest: PropTypes.func,
    style: PropTypes.object,
    onSelect: PropTypes.func,
    title: PropTypes.string,
    isSelectedItem: PropTypes.func,
    dataSource: PropTypes.array,
    getName: PropTypes.func,
    getAvatar: PropTypes.func,
    onAddClick: PropTypes.func,
    xs: PropTypes.number,
    md: PropTypes.number,
    wrapWithColumn: PropTypes.bool,
    columnStyle: PropTypes.object,
    reorderable: PropTypes.bool,
  };

  static defaultProps = {
    xs: 12,
    md: 3,
    wrapWithColumn: true,
    reorderable: false,
    columnStyle: null,
  };

  state = {
    searchString: "",
  };

  onSearchChange = (e) => {
    this.setState({ searchString: e.target.value });
  };

  onReorderClick = (key) => {
    DialogManager.push(ListReorderDialog, {
      onReorderRequest: (change) => {
        const dump = update(this.props.dataSource, {});
        if (!dump || !dump[key] || !dump[key + change]) return;
        const temp = dump[key];
        dump[key] = dump[key + change];
        dump[key + change] = temp;
        if (this.props.onReorderRequest) {
          this.props.onReorderRequest(dump);
        }
      },
    });
  };

  render() {
    const content = (
      <Card style={this.props.style}>
        <CardHeader>
          <strong>{this.props.title}</strong>
          <i className="fa fa-plus-square-o" onClick={this.props.onAddClick} style={styles.plusIcon} />
        </CardHeader>
        <Input value={this.state.searchString} onChange={this.onSearchChange} type="text" placeholder="Search.." style={styles.input} />
        <div style={styles.listContainer}>
          <List>
            {this.props.dataSource
              ? this.props.dataSource.map((value, key) =>
                  !this.state.searchString || this.props.getName(value, key).toLowerCase().includes(this.state.searchString.toLowerCase()) ? (
                    <ListItem
                      dense
                      button
                      key={key}
                      style={this.props.isSelectedItem && this.props.isSelectedItem(value, key) ? styles.selectedListItem : undefined}
                      onClick={() => {
                        if (this.props.onSelect) this.props.onSelect(value, key);
                      }}
                    >
                      {this.props.getAvatar ? <Avatar alt="Remy Sharp" src={this.props.getAvatar(value)} /> : undefined}
                      <ListItemText primary={this.props.getName(value, key)} />
                      {this.props.reorderable ? (
                        <ListItemSecondaryAction>
                          <IconButton
                            aria-label="Reorder"
                            onClick={() => {
                              this.onReorderClick(key);
                            }}
                          >
                            <SwapVertIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      ) : undefined}
                    </ListItem>
                  ) : undefined
                )
              : undefined}
          </List>
        </div>
      </Card>
    );
    if (this.props.wrapWithColumn) {
      let style = styles.col;
      if (this.props.columnStyle) {
        style = update(style, {});
        for (let key of Object.keys(this.props.columnStyle)) {
          style[key] = this.props.columnStyle[key];
        }
      }
      return (
        <Col xs={this.props.xs} md={this.props.md} style={style}>
          {content}
        </Col>
      );
    }

    return content;
  }
}

const styles = {
  col: { display: "inline-block", verticalAlign: "top" },
  input: { borderLeft: "none", borderRight: "none", borderTop: "none" },
  listContainer: { height: 300, overflow: "auto" },
  selectedListItem: { backgroundColor: "#badaff" },
  plusIcon: {
    position: "absolute",
    right: 0,
    top: 11,
    fontSize: 25,
    cursor: "pointer",
  },
};

export default ListSearch;
