/**
 * Created by kimchangduk on 2017-11-12.
 */

import React from "react";
import PropTypes from "prop-types";
import { Button, Card, CardBody, CardHeader, Input, Label } from "reactstrap";

class PriceEditor extends React.Component {
  static propTypes = {
    onInputChange: PropTypes.func,
    onPriceSubmit: PropTypes.func,
    title: PropTypes.string,
    sale: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    salesLabel: PropTypes.string,
    price: PropTypes.string,
    discountPrice: PropTypes.string,
  };

  render() {
    const discountPriceChecked = this.props.discountPrice !== null && this.props.discountPrice !== undefined;

    return (
      <Card>
        <CardHeader>{this.props.title}</CardHeader>
        <CardBody className="editor">
          <form onSubmit={this.props.onPriceSubmit}>
            <div className="data-row">
              <div className="name">판매문구</div>
              <div className="value">
                <Input type="text" name="salesLabel" value={this.props.salesLabel} onChange={this.props.onInputChange} />
              </div>
            </div>
            <div className="data-row">
              <div className="name">판매여부</div>
              <div className="value">
                <Input type="select" name="sale" value={this.props.sale} onChange={this.props.onInputChange}>
                  <option value={true}>판매</option>
                  <option value={false}>쿠폰전용</option>
                </Input>
              </div>
            </div>
            {(typeof this.props.sale === "string" && this.props.sale === "true") || (typeof this.props.sale === "boolean" && this.props.sale) ? (
              <div>
                <div className="data-row">
                  <div className="name">판매가</div>
                  <div className="value">
                    <Input type="text" name="price" value={this.props.price} onChange={this.props.onInputChange} />
                  </div>
                </div>
                <div className="data-row">
                  <div className="name">
                    <Label>
                      <Input
                        checked={discountPriceChecked}
                        type="checkbox"
                        onChange={(e) => {
                          const newChecked = e.target.checked;
                          if (this.props.onInputChange) {
                            this.props.onInputChange({
                              target: {
                                name: "discountPrice",
                                value: newChecked ? "0" : null,
                              },
                            });
                          }
                        }}
                      />{" "}
                      할인가
                    </Label>
                  </div>
                  <div className="value">
                    {discountPriceChecked ? (
                      <Input type="text" name="discountPrice" value={this.props.discountPrice} onChange={this.props.onInputChange} />
                    ) : undefined}
                  </div>
                </div>
              </div>
            ) : undefined}
            <Button type="submit" size="sm" color="primary" block>
              수정
            </Button>
          </form>
        </CardBody>
      </Card>
    );
  }
}

const styles = {};

export default PriceEditor;
