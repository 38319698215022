/**
 * Created by kimchangduk on 2017-08-14.
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getDeckList } from "../../actions/deckList";
import * as Api from "../../Api";
import { Consts, Strings } from "../../constants";
import { Button, Card, CardBody, CardHeader, Input } from "reactstrap";
import { getMessageFromResponse } from "../../utils";
import DialogManager from "../../dialogs/DialogManager";
import SafeDeleteDialog from "../../dialogs/SafeDeleteDialog";
import PriceEditor from "../PriceEditor";
import Move from "./Move";
import { getDeckDetail } from "../../actions/deckDetail";
import HashtagEditor from "../HashtagEditor";
import QuillEditor from "../QuillEditor";
import Loader from "../../views/Loader/Loader";
import DeckBundleEditor from "./DeckBundleEditor";

class DeckEditor extends React.Component {
  static propTypes = {
    editorType: PropTypes.string,
    deck: PropTypes.object,
    deckInfo: PropTypes.object,
    actions: PropTypes.object,
  };

  static defaultProps = {
    editorType: Consts.EditorTypes.EDIT,
  };

  static StateToProps = (state) => {
    return {};
  };

  static DispatchToProps = (dispatch) => {
    return {
      actions: {
        getDeckList: () => {
          dispatch(getDeckList());
        },
        getDeckDetail: (urlKey) => {
          dispatch(getDeckDetail(urlKey));
        },
      },
    };
  };

  state = {
    visibility: Consts.DeckVisibility.PUBLIC,
    showDashboard: true,
    coverImage: "",
    name: "",
    description: "",
    dataJson: "",
    sale: false,
    price: "0",
    discountPrice: null,
    salesLabel: null,
    orderMode: "",
    waiting: false,
  };

  componentDidMount() {
    this.initStatesWithDeck(this.props.editorType === Consts.EditorTypes.EDIT ? this.props.deck : null);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.deck !== nextProps.deck || this.props.editorType !== nextProps.editorType) {
      this.initStatesWithDeck(nextProps.editorType === Consts.EditorTypes.EDIT ? nextProps.deck : null);
    }
  }

  initStatesWithDeck = (deck) => {
    if (deck) {
      this.setState({
        name: deck.name,
        visibility: deck.visibility,
        showDashboard: deck.showDashboard,
        coverImage: deck.coverImage,
        description: deck.description ? deck.description.replace(/<br\/>/gi, "\n") : "",
        dataJson: JSON.stringify(deck.data),
        sale: deck.price !== null,
        price: deck.price ? deck.price.toString() : "0",
        discountPrice: deck.discountPrice !== null && deck.discountPrice !== undefined ? deck.discountPrice.toString() : null,
        salesLabel: deck.salesLabel ? deck.salesLabel : "",
        orderMode: deck.orderMode,
      });
    } else {
      this.setState({
        name: "",
        visibility: Consts.DeckVisibility.PRIVATE,
        showDashboard: true,
        coverImage: null,
        description: "",
        dataJson: "{}",
        sale: false,
        price: "0",
        discountPrice: "0",
        salesLabel: "",
        orderMode: "",
      });
    }
  };

  onInputChange = (e) => {
    switch (e.target.name) {
      case "showDashboard":
        this.setState({
          [e.target.name]: e.target.checked,
        });
        break;
      default:
        this.setState({
          [e.target.name]: e.target.value,
        });
        break;
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    const deck = this.props.deck;
    if (this.props.editorType === Consts.EditorTypes.EDIT) {
      if (deck.showDashboard !== this.state.showDashboard) {
        this.setState({ waiting: true });
        Api.setShowDashboard(Consts.ItemTypes.DECK, deck.urlKey, this.state.showDashboard)
          .then((response) => {
            this.callEditDeck();
          })
          .catch((error) => {
            alert("대시보드 노출 변경에 실패했습니다.");
            this.setState({ waiting: false });
          });
      } else {
        this.callEditDeck();
      }
    } else {
      this.setState({ waiting: true });
      Api.addDeck(this.state.visibility, this.state.name, this.state.description ? this.state.description.replace(/\n/gi, "<br/>") : "", this.state.dataJson)
        .then((response) => {
          if (this.state.coverImage) {
            const responseData = response.data;
            Api.editDeckImage(responseData.urlKey, this.state.coverImage)
              .then((response) => {
                this.props.actions.getDeckList();
                this.setState({ waiting: false });
              })
              .catch((error) => {
                alert("덱 추가에는 성공했으나 덱 이미지 설정에 실패했습니다.");
                this.props.actions.getDeckList();
                this.setState({ waiting: false });
              });
          } else {
            this.props.actions.getDeckList();
            this.setState({ waiting: false });
          }
        })
        .catch((error) => {
          this.setState({ waiting: false });
          DialogManager.alert(getMessageFromResponse(error.response, "덱 추가에 실패했습니다."));
        });
    }
  };

  callEditDeck = () => {
    const deck = this.props.deck;
    this.setState({ waiting: true });
    Api.editDeck(
      deck.urlKey,
      this.state.visibility,
      this.state.name,
      this.state.description ? this.state.description.replace(/\n/gi, "<br/>") : "",
      this.state.dataJson,
      this.state.orderMode
    )
      .then((response) => {
        if (deck.coverImage !== this.state.coverImage) {
          Api.editDeckImage(this.props.deck.urlKey, this.state.coverImage)
            .then((response) => {
              this.props.actions.getDeckList();
              this.setState({ waiting: false });
            })
            .catch((error) => {
              alert("덱 이미지 변경에 실패했습니다.");
              this.props.actions.getDeckList();
              this.setState({ waiting: false });
            });
        } else {
          this.props.actions.getDeckList();
          this.setState({ waiting: false });
        }
      })
      .catch((error) => {
        this.setState({ waiting: false });
        DialogManager.alert(getMessageFromResponse(error.response, "덱 수정에 실패했습니다."));
      });
  };

  onPriceSubmit = (e) => {
    e.preventDefault();
    const parameter = {};
    let sale = this.state.sale;
    if (typeof sale === "string") {
      sale = sale === "true";
    }
    parameter.sale = sale;

    if (sale) {
      let price = this.state.price;
      if (price === "") {
        price = 0;
      }
      if (typeof price === "string") {
        price = parseInt(price);
        if (isNaN(price)) {
          DialogManager.alert("판매가가 숫자형식이 아닙니다");
          return;
        }
      }
      parameter.price = price;

      let discountPrice = this.state.discountPrice;
      if (discountPrice === "") {
        discountPrice = null;
      }
      if (typeof discountPrice === "string") {
        discountPrice = parseInt(discountPrice);
        if (isNaN(discountPrice)) {
          DialogManager.alert("할인가가 숫자형식이 아닙니다");
          return;
        }
      }
      parameter.discountPrice = discountPrice;
    }

    const priceEditRequest = () => {
      Api.editDeckPrice(this.props.deck.urlKey, parameter)
        .then((response) => {
          this.props.actions.getDeckList();
        })
        .catch((error) => {
          DialogManager.alert(getMessageFromResponse(error.response, "덱 가격 수정에 실패했습니다."));
        });
    };
    if (this.props.deck.salesLabel !== this.state.salesLabel) {
      Api.setSalesLabel(Consts.ItemTypes.DECK, this.props.deck.urlKey, this.state.salesLabel)
        .then((response) => {
          priceEditRequest();
        })
        .catch((error) => {
          DialogManager.alert(getMessageFromResponse(error.response, "판매문구 수정에 실패했습니다."));
        });
    } else {
      priceEditRequest();
    }
  };

  onDeleteClick = () => {
    DialogManager.push(SafeDeleteDialog, {
      name: this.props.deck.name,
      checkUrlKey: this.props.deck.urlKey,
      onDelete: (dialog) => {
        Api.deleteDeck(this.props.deck.urlKey)
          .then((response) => {
            this.props.actions.getDeckList();
            if (dialog.props.onClose) {
              dialog.props.onClose();
            }
          })
          .catch((error) => {
            DialogManager.push(getMessageFromResponse(error.response, "덱 삭제에 실패했습니다."));
          });
      },
    });
  };

  onCoverImageChange = (e) => {
    const file = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
    this.setState({
      coverImage: file,
    });
  };

  onImageFileSelectorClick = () => {
    this.refs.imageFileSelector.click();
  };

  render() {
    return (
      <div>
        <Card>
          <CardHeader>{this.props.editorType === Consts.EditorTypes.EDIT ? "덱 수정" : "덱 추가"}</CardHeader>
          <CardBody className="editor">
            <form onSubmit={this.onSubmit}>
              {this.props.editorType === Consts.EditorTypes.EDIT ? (
                <div>
                  <div className="data-row">
                    <div className="name">id</div>
                    <div className="value">{this.props.deck.id}</div>
                  </div>
                  <div className="data-row">
                    <div className="name">UrlKey</div>
                    <div className="value user-select-all">{this.props.deck.urlKey}</div>
                  </div>
                </div>
              ) : undefined}
              <div className="data-row">
                <div className="name">Visibility</div>
                <div className="value">
                  <Input type="select" name="visibility" value={this.state.visibility} onChange={this.onInputChange}>
                    <option value={Consts.DeckVisibility.PUBLIC}>{Consts.DeckVisibility.PUBLIC}</option>
                    <option value={Consts.DeckVisibility.PRIVATE}>{Consts.DeckVisibility.PRIVATE}</option>
                  </Input>
                </div>
              </div>
              {this.props.editorType === Consts.EditorTypes.EDIT ? (
                <div className="data-row">
                  <div className="name">대시보드 노출</div>
                  <div className="value">
                    <input type="checkbox" name="showDashboard" checked={this.state.showDashboard} onChange={this.onInputChange} />
                  </div>
                </div>
              ) : undefined}
              <div className="data-row">
                <div className="name">이름</div>
                <div className="value">
                  <Input type="text" name="name" value={this.state.name} onChange={this.onInputChange} />
                </div>
              </div>
              <div className="data-row">
                <div className="name">설명</div>
                <div className="value">
                  <QuillEditor
                    value={this.state.description}
                    onChange={(value) => {
                      this.setState({ description: value });
                    }}
                  />
                </div>
              </div>
              <div className="data-row">
                <div className="name">OrderMode</div>
                <div className="value">
                  <Input type="select" name="orderMode" value={this.state.orderMode ? this.state.orderMode : ""} onChange={this.onInputChange}>
                    <option value={""}>NONE</option>
                    <option value={Consts.OrderMode.ORDERED}>{Consts.OrderMode.ORDERED}</option>
                    <option value={Consts.OrderMode.RANDOM}>{Consts.OrderMode.RANDOM}</option>
                  </Input>
                </div>
              </div>
              <div className="data-row">
                <div className="name">Data JSON</div>
                <div className="value">
                  <Input type="text" name="dataJson" value={this.state.dataJson} onChange={this.onInputChange} />
                </div>
              </div>
              <div className="data-row">
                <div className="name">덱 이미지</div>
                <div className="value">
                  {this.state.coverImage ? (
                    typeof this.state.coverImage === "string" ? (
                      <p style={styles.margin0}>{this.state.coverImage}</p>
                    ) : (
                      <p style={styles.margin0}>{this.state.coverImage.name}</p>
                    )
                  ) : undefined}
                  <Button type="button" onClick={this.onImageFileSelectorClick}>
                    파일 선택
                  </Button>
                  <input ref="imageFileSelector" type="file" name="coverImage" value="" onChange={this.onCoverImageChange} style={styles.hidden} />
                </div>
              </div>
              <div>
                {this.state.waiting ? (
                  <div className="text-align-center">
                    <Loader />
                  </div>
                ) : undefined}
                <Button type="submit" size="sm" color="primary" disabled={this.state.waiting} block>
                  {this.props.editorType === Consts.EditorTypes.EDIT ? "수정" : "추가"}
                </Button>
                {this.props.editorType === Consts.EditorTypes.EDIT ? (
                  <Button type="button" size="sm" color="danger" onClick={this.onDeleteClick} block>
                    삭제
                  </Button>
                ) : undefined}
                {this.props.editorType === Consts.EditorTypes.EDIT ? (
                  <Button
                    type="button"
                    size="sm"
                    color="info"
                    block
                    onClick={() => {
                      window.open(`${Consts.API_URL}/v1/manage/deck/${this.props.deck.urlKey}/download/csv`);
                    }}
                  >
                    {Strings.EXPORT_CSV}
                  </Button>
                ) : undefined}
              </div>
            </form>
          </CardBody>
        </Card>
        {this.props.editorType === Consts.EditorTypes.EDIT && this.props.deckInfo ? (
          <HashtagEditor itemType={Consts.ItemTypes.DECK} urlKey={this.props.deckInfo.urlKey} hashtag={this.props.deckInfo.hashtag} />
        ) : undefined}
        {this.props.editorType === Consts.EditorTypes.EDIT ? (
          <PriceEditor
            title="덱 가격 수정"
            onInputChange={this.onInputChange}
            onPriceSubmit={this.onPriceSubmit}
            sale={this.state.sale}
            price={this.state.price}
            discountPrice={this.state.discountPrice}
            salesLabel={this.state.salesLabel}
          />
        ) : undefined}
        {this.props.editorType === Consts.EditorTypes.EDIT ? (
          <DeckBundleEditor
            deck={this.props.deckInfo}
            onUpdated={() => {
              if (this.props.deckInfo) this.props.actions.getDeckDetail(this.props.deckInfo.urlKey);
            }}
          />
        ) : undefined}
        {this.props.deckInfo ? (
          <Move
            deck={this.props.deckInfo}
            multipleLessons={true}
            onUpdate={() => {
              this.props.actions.getDeckDetail(this.props.deck.urlKey);
            }}
          />
        ) : undefined}
      </div>
    );
  }
}

const styles = {
  name: {
    width: 100,
    display: "inline-block",
    textAlign: "right",
    fontWeight: "bold",
    paddingRight: 10,
    verticalAlign: "top",
    lineHeight: "35px",
  },
  value: {
    width: "calc(100% - 100px)",
    display: "inline-block",
    paddingLeft: 10,
    verticalAlign: "top",
    lineHeight: "35px",
  },
  marginBottom5: {
    marginBottom: 5,
  },
  hidden: {
    display: "none",
  },
  margin0: {
    margin: 0,
  },
};

export default connect(DeckEditor.StateToProps, DeckEditor.DispatchToProps)(DeckEditor);
