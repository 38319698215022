import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';
import MenuGroup from "./MenuGroup";

class Sidebar extends Component {
    static propTypes = {
        location: PropTypes.object
    };

    handleClick(e) {
        e.preventDefault();
        e.target.parentElement.classList.toggle('open');
    }

    activeRoute(routeName) {
        return this.props.location.pathname.indexOf(routeName) > -1 ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown';
    }

    // secondLevelActive(routeName) {
    //   return this.props.location.pathname.indexOf(routeName) > -1 ? "nav nav-second-level collapse in" : "nav nav-second-level collapse";
    // }

    render() {
        return (
            <div className="sidebar">
                <nav className="sidebar-nav">
                    <ul className="nav">
                        <li className="nav-item">
                            <NavLink to={'/dashboard'} className="nav-link" activeClassName="active"><i
                                className="icon-speedometer"/> 대시보드 </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={'/notice'} className="nav-link" activeClassName="active"><i
                                className="icon-pencil"/> 공지 팝업 </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={'/category'} className="nav-link" activeClassName="active"><i
                                className="icon-list"/> 카테고리 </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={'/members'} className="nav-link" activeClassName="active"><i
                                className="icon-user"/> 유저 관리 </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={'/decks'} className="nav-link" activeClassName="active"><i
                                className="icon-credit-card"/> 덱 관리 </NavLink>
                        </li>
                        <MenuGroup
                            label=" CBT 관리 "
                            iconClassName="icon-note"
                            subLinks={[
                                {label: "종목/과목", link: "/cbt/subject", exact: true},
                                {label: "테스트", link: "/cbt/test", exact: true}
                            ]}
                        />
                        <li className="nav-item">
                            <NavLink to={'/memberships'} className="nav-link" activeClassName="active">
                                <i className="icon-layers"/> 멤버쉽 관리
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={'/templates'} className="nav-link" activeClassName="active"><i
                                className="icon-notebook"/> 템플릿 관리 </NavLink>
                        </li>

                        <MenuGroup
                            label="쿠폰"
                            iconClassName="icon-note"
                            subLinks={[
                                {label: "관리", link: "/coupons", exact: true},
                                {label: "추가 기록", link: "/coupons/result", exact: true}
                            ]}
                        />
                    </ul>
                </nav>
            </div>
        );
    }
}

export default Sidebar;
