/**
 * Created by kimchangduk on 2017-08-13.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as Api from "../../Api";
import UserEditor from "../../components/UserEditor";
import {getMessageFromResponse} from "../../utils";
import UserSearch from "../../components/UserSearch";

class Users extends React.Component {
    static propTypes = {
        actions: PropTypes.object
    };

    static StateToProps = (state, ownProps) => {
        return {};
    };

    static DispatchToProps = (dispatch, ownProps) => {
        return {
            actions: {}
        };
    };

    state = {
        user: null
    };

    loadUser(id) {
        Api.getUser(id).then(response => {
            this.setState({user: response.data});
        }).catch(error => {
            alert(getMessageFromResponse(error.response, '유저 정보 조회에 실패했습니다.'));
        });
    }

    onSelectUser = (user) => {
        this.loadUser(user.id);
    };

    onAddAuthority = (authority) => {
        Api.addAuthority(this.state.user.id, authority).then(response => {
            this.loadUser(this.state.user.id);
        }).catch(error => {
            alert(getMessageFromResponse(error.response, '유저 권한 수정에 실패했습니다.'));
        });
    };

    onRemoveAuthorityRequest = () => {
        Api.removeAuthority(this.state.user.id).then(response => {
            this.loadUser(this.state.user.id);
        }).catch(error => {
            alert(getMessageFromResponse(error.response, '유저 권한 수정에 실패했습니다.'));
        });
    };

    render() {
        return (<div>
            <UserSearch onSelect={this.onSelectUser}/>
            {
                this.state.user ?
                    <UserEditor user={this.state.user} onAddAuthorityRequest={this.onAddAuthority} onRemoveAuthorityRequest={this.onRemoveAuthorityRequest}/> : undefined
            }
        </div>);
    }
}

export default connect(Users.StateToProps, Users.DispatchToProps)(Users);