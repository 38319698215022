/**
 * Created by kimchangduk on 2017-08-16.
 */

import React from 'react';
import PropTypes from 'prop-types';
import DefaultDialog from "./DefaultDialog";
import {Button} from "reactstrap";

class AddPackageOrLessonDialog extends React.Component {
    static propTypes = {
        onClose: PropTypes.func,
        onSelectPackage: PropTypes.func,
        onSelectLesson: PropTypes.func,
    };

    onSelectPackage = () => {
        if (this.props.onSelectPackage) {
            this.props.onSelectPackage();
        }

        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    onSelectLesson = () => {
        if (this.props.onSelectLesson) {
            this.props.onSelectLesson();
        }

        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    render() {
        return (<DefaultDialog content={<div style={styles.container}>
            <Button color="primary" size="lg" style={styles.button} onClick={this.onSelectPackage}>패키지 추가</Button>
            <Button size="lg" color="primary" style={styles.button} onClick={this.onSelectLesson}>레슨 추가</Button>
        </div>} onClose={this.props.onClose}/>);
    }
}

const styles = {
    container: {textAlign: 'center'},
    button: {borderRadius: 4, margin: '0 5px', minWidth: 140}
};

export default AddPackageOrLessonDialog;