import React, { Component } from "react";
import PropTypes from "prop-types";
import { getUser } from "../../actions/user";
import { Consts } from "../../constants";
import { connect } from "react-redux";
import { Link, Switch, Route, Redirect } from "react-router-dom";
import Header from "../../components/Header/";
import Sidebar from "../../components/Sidebar/";
import Breadcrumb from "../../components/Breadcrumb/";
import Aside from "../../components/Aside/";
import Footer from "../../components/Footer/";
import AppHistory from "../../history";
import { hasChangedRequestToFailure, hasChangedRequestToSuccess } from "../../utils";

import Dashboard from "../../views/Dashboard/";
import Decks from "../../views/Decks/Decks";
import Users from "../../views/Users/Users";
import CBTTest from "../../views/CBT/CBTTest";
import CBTSubject from "../../views/CBT/CBTSubject";
import Category from "../../views/Category/Category";
import Templates from "../../views/Templates";
import Api from "../../views/Api";
import Memberships from "../../views/Memberships";
import Coupons from "../../views/Coupons/Coupons";
import CouponsAddResult from "../../views/Coupons/CouponsAddResult";
import Notice from "../../views/Notice";

class Full extends Component {
  static propTypes = {
    isLogin: PropTypes.bool,
    loginRequest: PropTypes.string,
    getRequest: PropTypes.string,
    user: PropTypes.object,
    actions: PropTypes.object,
    history: PropTypes.object,
  };

  static StateToProps = (state) => {
    return {
      isLogin: state.user.state.isLogin,
      loginRequest: state.user.state.loginRequest,
      getRequest: state.user.state.getRequest,
      user: state.user.dataSource,
    };
  };

  static DispatchToProps = (dispatch) => {
    return {
      actions: {
        getUser: () => {
          dispatch(getUser());
        },
      },
    };
  };

  componentDidMount() {
    this.props.actions.getUser();
  }

  componentWillReceiveProps(nextProps) {
    if (hasChangedRequestToFailure(this.props.getRequest, nextProps.getRequest)) {
      AppHistory.moveLoginPage(nextProps.history);
    }

    if (
      hasChangedRequestToSuccess(this.props.getRequest, nextProps.getRequest) ||
      hasChangedRequestToSuccess(this.props.loginRequest, nextProps.loginRequest)
    ) {
      const authorities = nextProps.user.authorities;
      if (!authorities.includes(Consts.Authorities.ROLE_SUPERUSER) && !authorities.includes(Consts.Authorities.ROLE_MANAGER)) {
        window.location.replace("/403");
      }
    }

    if (this.props.isLogin && !nextProps.isLogin) {
      AppHistory.moveLoginPage(nextProps.history);
    }
  }

  render() {
    if (this.props.getRequest === Consts.REQUEST_WAITING || this.props.getRequest === Consts.REQUEST_NONE) {
      return <div />;
    }

    const isAdmin =
      this.props.user &&
      this.props.user.authorities &&
      (this.props.user.authorities.includes(Consts.Authorities.ROLE_SUPERUSER) || this.props.user.authorities.includes(Consts.Authorities.ROLE_MANAGER));
    return isAdmin ? (
      <div className="app">
        <Header />
        <div className="app-body">
          <Sidebar {...this.props} />
          <main className="main">
            <Breadcrumb />
            <div className="container-fluid">
              <Switch>
                <Route path="/members" name="Members Page" component={Users} />
                <Route path="/notice" name="Notice Page" component={Notice} />
                <Route path="/api" name="Members Page" component={Api} />
                <Route path="/category" name="Category Page" component={Category} />
                <Route path="/decks" name="Decks Page" component={Decks} />
                <Route path="/dashboard" name="Dashboard" component={Dashboard} />
                <Route path="/cbt/subject" name="CBT 종목/과목" component={CBTSubject} />
                <Route path="/cbt/test" name="CBT 테스트" component={CBTTest} />
                <Route path="/templates" name="템플릿 관리" component={Templates} />
                <Route path="/memberships" name="템플릿 관리" component={Memberships} />
                <Route path="/coupons" name="쿠폰 관리" component={Coupons} exact />
                <Route path="/coupons/result" name="쿠폰 관리" component={CouponsAddResult} exact />
                <Redirect from="/" to="/dashboard" />
              </Switch>
            </div>
          </main>
          <Aside />
        </div>
        <Footer />
      </div>
    ) : (
      <div />
    );
  }
}

export default connect(Full.StateToProps, Full.DispatchToProps)(Full);
