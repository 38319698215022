import * as ActionTypes from "../constants/ActionTypes";
import * as String from "../constants/Strings";
import * as Api from "../Api";
import {getMessageFromResponse} from "../utils";

export function getTemplates() {
    return dispatch => {
        dispatch({type: ActionTypes.GET_TEMPLATE_REQUEST});
        Api.getTemplateLists().then(response => {
            dispatch({type: ActionTypes.GET_TEMPLATE_SUCCESS, data: response.data});
        }).catch(error => {
            dispatch({type: ActionTypes.GET_TEMPLATE_FAILURE, errorMessage: getMessageFromResponse(error.response, String.FAILED_TO_LOAD_DATA)});
        });
    };
}