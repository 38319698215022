import React from "react";
import Radium from "radium";
import PropTypes from "prop-types";
import { Consts } from "../../constants";
import { Button, Card, CardBody, CardHeader, Table } from "reactstrap";
import axios from "axios";
import LoaderBlock from "../../views/Loader/LoaderBlock";

class CouponAddedResults extends React.Component {
  static propTypes = {
    style: PropTypes.object,
  };

  static defaultProps = {};

  state = {
    waiting: false,
    result: null,
  };

  componentDidMount() {
    this.inquery();
  }

  inquery = () => {
    this.setState({ waiting: true });
    axios
      .get("/v1/manage/coupon/added/logs")
      .then((response) => {
        this.setState({ waiting: false, result: response.data });
      })
      .catch((error) => {
        this.setState({ waiting: false });
      });
  };

  render() {
    return (
      <div style={[styles.container, this.props.style]}>
        <Card>
          <CardHeader>쿠폰 추가 결과</CardHeader>
          <CardBody className="editor">
            <div className="position-relative">
              {this.state.result ? (
                <div>
                  <p>Queue: {this.state.result.queue}</p>
                  <div style={styles.tableWrapper}>
                    <Table>
                      <thead>
                        <tr>
                          <th>code</th>
                          <th>result</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.result.logs
                          ? this.state.result.logs.map((item, key) => {
                              return (
                                <tr key={key}>
                                  <td>{item.code}</td>
                                  <td>{item.result.toString()}</td>
                                </tr>
                              );
                            })
                          : undefined}
                      </tbody>
                    </Table>
                  </div>
                </div>
              ) : undefined}
              {this.state.waiting ? <LoaderBlock /> : undefined}
              <Button className="margin-top-10" disabled={this.state.waiting} onClick={this.inquery} color="primary" block>
                조회하기
              </Button>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const styles = {
  container: {},
  style: {},
  tableWrapper: {
    border: "1px solid #cfd8dc",
    borderTop: "none",
    maxHeight: 300,
    overflowY: "auto",
  },
};

export default Radium(CouponAddedResults);
