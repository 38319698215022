/**
 * Created by kimchangduk on 2017-07-23.
 */

import axios from "axios";
import {Consts} from './constants';
import queryString from 'query-string';

const jsonContentTypeHeaderConfig = {
    headers: {
        'Content-Type': 'application/json'
    }
};

//<editor-fold desc="Account controller /v1/account ">
export function getUser(id) {
    if (id === undefined) {
        return axios.get('/v1/account');
    }
    return axios.get(`/v1/manage/account/${id}`);
}

export function login(email, password) {
    return axios.post('/v1/account/login', {email, password});
}

export function loginWithFacebook(token) {
    return axios.get('/v1/account/facebook/login', {params: {token}});
}

export function logout() {
    return axios.post('/logout');
}

export function signUp(name, nickname, email, password, sex, birthday, agreeMail) {
    const params = {name, nickname, email, password, sex, birthday, agreeMail};
    return axios.post('/v1/account/', params);
}

export function forgotPassword(email) {
    return axios.post('/v1/account/forget/password', {email});
}

export function resetPassword(userId, token, newPassword) {
    return axios.put('/v1/account/password/reset', {userId, token, newPassword});
}

export function validateEmail(token) {
    return axios.post('/v1/account/email/verify', {token});
}

export function registerFcmToken(token) {
    return axios.post('/v1/account/fcm', {token});
}

export function deleteFcmToken(token) {
    return axios.delete('/v1/account/fcm', {params: {token}});
}

export function changeProfile(params) {
    return axios.put('/v1/account/', params);
}

export function addAuthority(id, authority) {
    return axios.post(`/v1/manage/account/${id}/auth`, {authType: authority});
}

export function removeAuthority(id) {
    return axios.delete(`/v1/manage/account/${id}/auth`);
}

export function searchEmail(email) {
    return axios.get('/v1/manage/account/search/email', {params: {email}});
}

export function searchUser(keyword) {
    return axios.get('/v1/manage/account/search', {params: {keyword}});
}

//</editor-fold>

//<editor-fold desc="Deck controller /v1/deck">
export function getDeckList() {
    return axios.get('/v1/deck/list');
}

export function getDeckDetails(urlKey) {
    return axios.get(`/v1/deck/${urlKey}`);
}

export function resendVerifyEmail() {
    return axios.get('/v1/account/email/verify/resend');
}

export function getDeckCards(urlKey, lessonId) {
    return axios.get(`/v1/deck/${urlKey}/${lessonId}`);
}

export function changePassword(beforePassword, newPassword) {
    return axios.put('/v1/account/password', {beforePassword, newPassword});
}

//</editor-fold>

//<editor-fold desc="Membox controller">
export function getMemboxTotalInfo() {
    return axios.get('/v1/membox');
}

export function getMemboxDeckInfo(urlKey) {
    return axios.get(`/v1/membox/deck/${urlKey}`);
}

export function getMemboxDeckCards(urlKey) {
    return axios.get(`/v1/membox/deck/${urlKey}/cards`);
}

export function getMemboxDeckLevelCards(urlKey, level, stateType = Consts.MEMORIZE_STATE_READY) {
    return axios.get(`/v1/membox/deck/${urlKey}/level/${level}/cards`, {params: {stateType}});
}

export function memorize(level, cardId) {
    return axios.post(`/v1/membox/level/${level}/`, {cardId});
}

export function getMemboxLevelCards(level, stateType = Consts.MEMORIZE_STATE_READY) {
    return axios.get(`/v1/membox/level/${level}/cards`, {params: {stateType}});
}

export function registerCoupon(couponCode) {
    return axios.post('/v1/account/coupon', {couponCode});
}

//</editor-fold>

//<editor-fold desc="Board">
export function getBoardList(page) {
    return axios.get('/v1/board/list', {params: {page}});
}

export function postBoardItem(title, content) {
    return axios.post('/v1/board', {title, content});
}

export function deleteBoardItem(id) {
    return axios.delete(`/v1/board/${id}`);
}

export function getBoardItem(id) {
    return axios.get(`/v1/board/${id}`);
}

export function editBoardItem(id, title, content) {
    const param = {};

    if (title !== undefined) {
        param.title = title;
    }

    if (content !== undefined) {
        param.content = content;
    }

    return axios.put(`/v1/board/${id}`, param);
}

export function postBoardItemComment(id, content) {
    return axios.post(`/v1/board/${id}/comments`, {content});
}

export function deleteBoardItemComment(id, commentsId) {
    return axios.delete(`/v1/board/${id}/comments/${commentsId}`);
}

//</editor-fold>

//<editor-fold desc="Deck, Card /v1/manage/deck">
export function addDeck(visibility, name, description, dataJson) {
    const params = {};
    const body = description !== null && description !== undefined ?
        {description} : undefined;

    params.visibility = visibility;
    if (name) params.name = name;
    if (dataJson) params.dataJson = dataJson;
    return axios.post(`/v1/manage/deck?${queryString.stringify(params)}`, body);
}

export function editDeck(urlKey, visibility, name, description, dataJson, orderMode) {
    const params = {};
    const body = description !== null && description !== undefined ?
        {description} : undefined;

    if (visibility !== null && visibility !== undefined) params.visibility = visibility;
    if (name !== null && name !== undefined) params.name = name;
    if (dataJson) params.dataJson = dataJson;
    if (orderMode !== null && orderMode !== undefined) params.orderMode = orderMode;

    return axios.put(`/v1/manage/deck/${urlKey}?${queryString.stringify(params)}`, body);
}

export function editDeckImage(urlKey, coverImage) {
    const formData = new FormData();
    if (coverImage) {
        formData.append('coverImage', coverImage);
    }
    return axios.put(`/v1/manage/deck/${urlKey}`, formData);
}

export function editDeckPrice(urlKey, parameter) {
    return axios.put(`/v1/manage/deck/${urlKey}/price?${queryString.stringify(parameter)}`);
}

export function deleteDeck(urlKey) {
    return axios.delete(`/v1/manage/deck/${urlKey}`);
}

export function editDeckElement(urlKey, element) {
    return axios.put(`/v1/manage/deck/${urlKey}/element`, element, jsonContentTypeHeaderConfig);
}

export function addCard(urlKey, templateId, data) {
    return axios.post(`/v1/manage/deck/${urlKey}/card`, {templateId, data: JSON.stringify(data)});
}

export function addCards(urlKey, cardList) {
    return axios.post(`/v1/manage/deck/${urlKey}/card/bulk`, cardList, jsonContentTypeHeaderConfig);
}

export function editCard(urlKey, cardId, templateId, data) {
    return axios.put(`/v1/manage/deck/${urlKey}/card/${cardId}`, {templateId, data: JSON.stringify(data)});
}

export function deleteCard(urlKey, cardId) {
    return axios.delete(`/v1/manage/deck/${urlKey}/card/${cardId}`);
}

export function setDeckBundleItems(urlKey, deckIds, testIds) {
    const params = {};
    if (deckIds && deckIds.length > 0) {
        params.deckIds = deckIds;
    }
    if (testIds && testIds.length > 0) {
        params.testIds = testIds;
    }

    return axios.post(`/v1/manage/deck/${urlKey}/bundle?${queryString.stringify(params)}`);
}

//</editor-fold>

//<editor-fold desc="Category List">
export function getCategoryList() {
    return axios.get(`/v1/category`);
}

export function getCategory(id) {
    return axios.get(`/v1/category/${id}`);
}

export function deleteCategory(id) {
    return axios.delete(`/v1/manage/category/${id}`);
}

export function updateCategory(id, parentId, name) {
    const param = {};
    if (parentId !== undefined) {
        param.parentId = parentId;
    }
    if (name !== undefined) {
        param.name = name;
    }
    return axios.put(`/v1/manage/category/${id}`, param);
}

export function updateCategoryItem(id, deckIds, testIds, membershipIds) {
    let query = '';
    if (deckIds) {
        for (let deckId of deckIds) {
            if (query) {
                query += '&';
            }
            query += 'deckIds=' + deckId;
        }
    }
    if (testIds) {
        for (let testId of testIds) {
            if (query) {
                query += '&';
            }
            query += 'testIds=' + testId;
        }
    }
    if (membershipIds) {
        for (let membershipId of membershipIds) {
            if (query) {
                query += '&';
            }
            query += 'membershipIds=' + membershipId;
        }
    }
    return axios.put(`/v1/manage/category/${id}/item?${query}`);
}

export function createCategory(id, name) {
    const params = {};
    if (id !== null && id !== undefined) {
        params.parentId = id;
    }
    params.name = name;
    return axios.post(`/v1/manage/category`, params);
}

//</editor-fold>

//<editor-fold desc="CBT /v1/manage/cbt">
export function getCBTRowQuestionList(id) {
    return axios.get(`/v1/manage/cbt/${id}/questions/raw`);
}

export function getCBTDetail(id) {
    return axios.get(`/v1/manage/cbt/${id}`);
}

export function getCBTList() {
    return axios.get(`/v1/cbt/list`);
}

export function getCBTClassList() {
    return axios.get('/v1/manage/cbt/class');
}

export function getCBTSubjectList() {
    return axios.get('/v1/manage/cbt/subject');
}

export function addCBTClass(name) {
    return axios.post('/v1/manage/cbt/class', {name});
}

export function addCBTSubject(name) {
    return axios.post('/v1/manage/cbt/subject', {name});
}

export function editCBTSubject(id, name) {
    return axios.post(`/v1/manage/cbt/subject/${id}`, {name});
}

export function addCBTTest(name, classId, year, round, price, minuteLimit, description) {
    const params = {};
    const body = (description != null && description !== undefined) ?
        {description} : undefined;

    params.name = name;
    params.classId = classId;
    if (year) params.year = year;
    if (round) params.round = round;
    if (price !== null && price !== undefined) params.price = price;
    if (minuteLimit !== null && minuteLimit !== undefined) params.minuteLimit = minuteLimit;

    return axios.post(`/v1/manage/cbt?${queryString.stringify(params)}`, body);
}

export function editCBTTest(id, name, classId, year, round, price, minuteLimit, visibility, description) {
    const params = {};
    const body = (description != null && description !== undefined) ?
        {description} : undefined;

    params.name = name;
    params.classId = classId;
    if (year) params.year = year;
    if (round) params.round = round;
    if (price !== null && price !== undefined) params.price = price;
    if (minuteLimit !== null && minuteLimit !== undefined) params.minuteLimit = minuteLimit;
    if (visibility) params.visibility = visibility;

    return axios.put(`/v1/manage/cbt/${id}?${queryString.stringify(params)}`, body);
}

export function editCBTTestImage(id, coverImage) {
    const params = {};
    const formData = new FormData();
    formData.append('coverImage', coverImage);
    return axios.put(`/v1/manage/cbt/${id}?${queryString.stringify(params)}`, formData);
}

export function addCBTQuestion(id, parameters) {
    return axios.post(`/v1/manage/cbt/${id}/question`, parameters);
}

export function editCBTQuestion(id, questionId, parameters) {
    return axios.put(`/v1/manage/cbt/${id}/question/${questionId}`, parameters);
}

export function addCBTQuestionBulk(id, json) {
    return axios.post(`/v1/manage/cbt/${id}/question/bulk`, json, jsonContentTypeHeaderConfig);
}

export function deleteCBTQuestion(id, questionId) {
    return axios.delete(`/v1/manage/cbt/${id}/question/${questionId}`);
}

export function deleteCBTTest(id) {
    return axios.delete(`/v1/manage/cbt/${id}`);
}

export function editCBTQuestionPage(id, questionPage) {
    return axios.put(`/v1/manage/cbt/${id}/questionpage`, questionPage, jsonContentTypeHeaderConfig);
}

export function editCBTPassGrade(id, json) {
    return axios.put(`/v1/manage/cbt/${id}/passgrade`, json, jsonContentTypeHeaderConfig);
}

export function editCBTPrice(urlKey, parameter) {
    return axios.put(`/v1/manage/cbt/${urlKey}/price?${queryString.stringify(parameter)}`);
}

export function addQuestions(id, questionList) {
    return axios.post(`/v1/manage/cbt/${id}/question/bulk`, questionList, jsonContentTypeHeaderConfig);
}

//</editor-fold>

//<editor-fold desc="CSV">
export function convertCsvToJson(jsonFile) {
    const formData = new FormData();
    formData.append('jsonFile', jsonFile);

    return axios.post(`/v1/manage/util/csv2json`, formData);
}

export function convertCBTCsvToJson(imageUri, jsonFile) {
    const formData = new FormData();
    formData.append('csvFile', jsonFile);

    return axios.post(`/v1/manage/util/cbt/csv2json/headercsv?${queryString.stringify({imageUri})}`, formData);
}

export function convertDeckCsvToJson(imageUri, audioUri, jsonFile) {
    const formData = new FormData();
    formData.append('jsonFile', jsonFile);

    return axios.post(`/v1/manage/util/deck/csv2json/multitemplate?${queryString.stringify({
        imageUri,
        audioUri
    })}`, formData);
}

export function convertCsvToDeckJson(rows, specifications, imageUri, jsonFile) {
    const formData = new FormData();
    rows = rows.split('\n').filter(item => item);
    const queryStr = queryString.stringify({
        rows, specifications, imageUri
    });
    formData.append('jsonFile', jsonFile);

    return axios.post(`/v1/manage/util/csv2deckjson?${queryStr}`, formData);
}

//</editor-fold>

//<editor-fold desc="Template /v1/manage/template">
export function getTemplateLists() {
    return axios.get('/v1/manage/template');
}

export function addTemplate(id, name, type, content, rows, specifications) {
    if (typeof(specifications) === 'object') {
        specifications = JSON.stringify(specifications);
    }
    return axios.post('/v1/manage/template', {id, name, type, content, rows, specifications});
}

export function editTemplate(id, newId, name, type, content, rows, specifications) {
    if (typeof(specifications) === 'object') {
        specifications = JSON.stringify(specifications);
    }
    const params = {name, type, content, rows, specifications};
    if (typeof(newId) === 'string') {
        newId = parseInt(newId);
    }
    if (id !== newId) {
        params.newId = newId;
    }
    return axios.put(`/v1/manage/template/${id}`, params);
}

//</editor-fold>

//<editor-fold desc="Membership">
export function getMembershipList() {
    return axios.get('/v1/manage/membership');
}

export function getMembership(urlKey) {
    return axios.get(`/v1/membership/${urlKey}`);
}

export function addMembership(name, description, coverImage) {
    const params = {name};
    const formData = new FormData();
    if (coverImage) {
        formData.append('coverImage', coverImage);
    }
    if (description !== null && description !== undefined) formData.append('description', description);
    return axios.post(`/v1/manage/membership?${queryString.stringify(params)}`, formData);
}

export function editMembership(urlKey, name, description, visibility, deckIds, testIds) {
    const params = {};
    const body = description !== null && description !== undefined ?
        {description} : undefined;

    if (deckIds) {
        params.deckIds = deckIds;
    }
    if (testIds) {
        params.testIds = testIds;
    }
    if (name) {
        params.name = name;
    }
    if (visibility) {
        params.visibility = visibility;
    }

    return axios.put(`/v1/manage/membership/${urlKey}?${queryString.stringify(params)}`, body);
}

export function editMembershipImage(urlKey, coverImage) {
    const formData = new FormData();
    if (coverImage) {
        formData.append('coverImage', coverImage);
    }
    return axios.put(`/v1/manage/membership/${urlKey}`, formData);
}

export function editMembershipPrice(urlKey, parameter) {
    return axios.put(`/v1/manage/membership/${urlKey}/price?${queryString.stringify(parameter)}`);
}

//</editor-fold>

//<editor-fold desc="Hashtag /v1/manage/hashtag/${itemType}/${urlKey}">
export function addHashtag(itemType, urlKey, tag) {
    return axios.post(`/v1/manage/hashtag/${itemType}/${urlKey}`, {tag});
}

export function removeHashtag(itemType, urlKey, tag) {
    return axios.delete(`/v1/manage/hashtag/${itemType}/${urlKey}`, {params: {tag}});
}

export function searchHashtag(name) {
    return axios.get('/v1/hashtag', {params: {name}});
}

//</editor-fold>

//<editor-fold desc="coupon /v1/manage/coupon">
export function searchCoupon(code, page) {
    const params = {};
    if (code !== null && code !== undefined) {
        params.code = code;
    }
    if (page !== null && page !== undefined) {
        params.page = page;
    }
    return axios.get('/v1/manage/coupon', {params});
}

export function getCoupon(code) {
    return axios.get(`/v1/manage/coupon/${code}`);
}

export function editCoupon(params) {
    return axios.put(`/v1/manage/coupon/?${queryString.stringify(params)}`,);
}

export function addCoupon(params) {
    return axios.post(`/v1/manage/coupon?${queryString.stringify(params)}`);
}

export function getCouponAddedLogs() {
    return axios.get('/v1/manage/coupon/added/logs');
}

export function bulkInsertCoupon(codes, params) {
    return axios.post(`/v1/manage/coupon/bulk?${queryString.stringify(params)}`, codes, jsonContentTypeHeaderConfig);
}

//</editor-fold>

//<editor-fold desc="popup /v1/manage/popup">
export function getPopupList() {
    return axios.get('/v1/manage/popup');
}

export function postPopup(content, startDate, endDate) {
    const params = {content};
    if (startDate !== undefined) {
        params.startDate = startDate;
    }
    if (endDate !== undefined) {
        params.endDate = endDate;
    }
    return axios.post(`/v1/manage/popup?${queryString.stringify(params)}`);
}

export function editPopup(id, content, startDate, endDate) {
    const params = {content};
    if (startDate !== undefined) {
        params.startDate = startDate;
    }
    if (endDate !== undefined) {
        params.endDate = endDate;
    }
    return axios.put(`/v1/manage/popup/${id}?${queryString.stringify(params)}`);
}

//</editor-fold>

//<editor-fold desc="item /v1/manage/item">
export function setShowDashboard(itemType, urlKey, showDashboard) {
    return axios.put(`/v1/manage/item/${itemType}/${urlKey}`, {showDashboard});
}

export function setSalesLabel(itemType, urlKey, salesLabel) {
    return axios.put(`/v1/manage/item/${itemType}/${urlKey}`, {salesLabel});
}

//</editor-fold>

export function loginWithUserId(id){
    return axios.get(`/v1/manage/account/login/${id}`);
}