/**
 * Created by kimchangduk on 2017-08-14.
 */

import React from "react";
import update from "immutability-helper";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getDeckDetail } from "../../actions/deckDetail";
import { getDeckCards } from "../../actions/deckCards";
import * as Api from "../../Api";
import { Consts } from "../../constants";
import { Button, Card, CardBody, CardHeader, Input } from "reactstrap";
import { getMessageFromResponse, getLessonFromElement } from "../../utils";
import DialogManager from "../../dialogs/DialogManager";
import CardTemplateSelectField from "../CardTemplateSelectField";

class CardEditor extends React.Component {
  static propTypes = {
    deck: PropTypes.object,
    packagePathIndexs: PropTypes.array,
    lessonId: PropTypes.number,
    card: PropTypes.object,
    deckUrlKey: PropTypes.string,
    editorType: PropTypes.string,
    actions: PropTypes.object,
    onLessonCardAdded: PropTypes.func,
    onDeleteCard: PropTypes.func,
    templates: PropTypes.array,
  };

  static defaultProps = {
    editorType: Consts.EditorTypes.EDIT,
  };

  static StateToProps = (state) => {
    return {
      templates: state.template.dataSource,
    };
  };

  static DispatchToProps = (dispatch, ownProps) => {
    return {
      actions: {
        getDeckDetail: () => {
          dispatch(getDeckDetail(ownProps.deckUrlKey));
        },
        getDeckCards: () => {
          dispatch(getDeckCards(ownProps.deckUrlKey, ownProps.lessonId));
        },
      },
    };
  };

  state = {
    templateId: Consts.Templates[0].id,
    data: update(Consts.Templates[0].defaultData, {}),
  };

  componentDidMount() {
    this.initStatesWithCard(this.props.editorType === Consts.EditorTypes.EDIT ? this.props.card : null);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.card !== nextProps.card || this.props.editorType !== nextProps.editorType) {
      this.initStatesWithCard(nextProps.editorType === Consts.EditorTypes.EDIT ? nextProps.card : null);
    }
  }

  addToLesson = (newCard) => {
    const newElement = update(this.props.deck.element, {});
    const lesson = getLessonFromElement(newElement, this.props.packagePathIndexs, this.props.lessonId);
    if (lesson) {
      if (!lesson.cards) {
        lesson.cards = [];
      }
      lesson.cards.push(newCard.id);
    }
    Api.editDeckElement(this.props.deckUrlKey, newElement)
      .then((response) => {
        this.props.actions.getDeckDetail();
        this.props.actions.getDeckCards();
      })
      .catch((error) => {
        DialogManager.alert(getMessageFromResponse(error.response, "레슨 카드목록 추가에 실패했습니다."));
      });
  };

  initStatesWithCard = (card) => {
    if (card) {
      const templateData = this.buildDefaultTemplateData(card.templateId);
      const template = this.getTemplate(card.templateId);
      const keys = Object.keys(templateData);
      for (let key of keys) {
        if (card.data[key] !== null && card.data[key] !== undefined) {
          let data = card.data[key];
          const specification = template && template.specifications ? template.specifications[key] : null;
          if (specification && specification.type === "ARRAY") {
            templateData[key] = this.arrayToString(data);
          } else {
            templateData[key] = data;
          }
        } else {
          templateData[key] = "";
        }
      }

      this.setState({
        templateId: card.templateId,
        data: templateData,
      });
    } else {
      this.setState({
        templateId: Consts.Templates[0].id,
        data: this.buildDefaultTemplateData(1),
      });
    }
  };

  getTemplate = (id) => {
    if (id === undefined) {
      id = this.state.templateId;
    }
    const templates = this.props.templates;
    let template = templates ? templates.find((a) => a.id === id) : undefined;
    if (template && template.rows) {
      return template;
    }
    return Consts.Templates.find((a) => a.id === id);
  };

  buildDefaultTemplateData(templateId) {
    let template = this.getTemplate(templateId);
    if (template) {
      if (template.rows) {
        const result = {};
        for (let row of template.rows) {
          const specification = template.specifications ? template.specifications[row] : null;
          if (!specification || !specification.type) {
            result[row] = "";
          } else {
            if (specification.type === "BOOL") {
              result[row] = false;
            } else {
              result[row] = "";
            }
          }
        }
        return result;
      } else if (template.defaultData) {
        return update(template.defaultData, {});
      }
    }
    return null;
  }

  arrayToString = (arr) => {
    return arr.join("\n");
  };

  onTemplateChange = (e, template) => {
    this.setState({
      templateId: template.id,
      data: this.buildDefaultTemplateData(template.id),
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const data = update(this.state.data, {});
    const template = this.getTemplate();

    if (template) {
      for (let key of Object.keys(data)) {
        const specification = template.specifications ? template.specifications[key] : null;
        if (specification && specification.type === "ARRAY") {
          data[key] = data[key].split("\n");
        }
        if (typeof data[key] === "string") {
          data[key] = data[key].replace("\n", "<br/>");
        }
      }
    }

    if (this.props.editorType === Consts.EditorTypes.EDIT) {
      Api.editCard(this.props.deckUrlKey, this.props.card.id, this.state.templateId, data)
        .then((response) => {
          this.props.actions.getDeckCards();
        })
        .catch((error) => {
          DialogManager.alert(getMessageFromResponse(error.response, "카드 수정에 실패했습니다."));
        });
    } else {
      Api.addCard(this.props.deckUrlKey, this.state.templateId, data)
        .then((response) => {
          if (this.props.deck && this.props.packagePathIndexs && this.props.lessonId >= 0) {
            this.addToLesson(response.data);
          }
        })
        .catch((error) => {
          DialogManager.alert(getMessageFromResponse(error.response, "카드 추가에 실패했습니다."));
        });
    }
  };

  onDeleteClick = () => {
    DialogManager.confirm("정말로 삭제 하시겠습니까?", () => {
      Api.deleteCard(this.props.deckUrlKey, this.props.card.id)
        .then((response) => {
          if (this.props.onDeleteCard) {
            this.props.onDeleteCard();
          }
          this.props.actions.getDeckDetail();
          this.props.actions.getDeckCards();
        })
        .catch((error) => {
          DialogManager.alert(getMessageFromResponse(error.response, "카드 제거에 실패했습니다."));
        });
    });
  };

  onDataChange = (e) => {
    let newValue = e.target.value;
    if (typeof this.state.data[e.target.name] === "boolean" && typeof newValue !== "boolean") {
      newValue = newValue === "true";
    }
    const newData = update(this.state.data, { [e.target.name]: { $set: newValue } });
    this.setState({
      data: newData,
    });
  };

  renderInput = (itemKey) => {
    const richTextKeys = Consts.TemplateRichTextKeys[this.state.templateId];
    if (richTextKeys && richTextKeys.findIndex((a) => a === itemKey) >= 0) {
      return <Input type="textarea" value={this.state.data[itemKey]} name={itemKey} onChange={this.onDataChange} />;
    }
    return <Input type="text" value={this.state.data[itemKey]} name={itemKey} onChange={this.onDataChange} />;
  };

  renderDataInput = () => {
    const dataKeys = this.state.data ? Object.keys(this.state.data) : null;
    if (!dataKeys) return undefined;
    return (
      <div>
        {dataKeys
          ? dataKeys.map((itemKey, index) => (
              <div key={index} className="data-row">
                <div className="name">{itemKey}</div>
                <div className="value">
                  {typeof this.state.data[itemKey] === "boolean" ? (
                    <Input type="select" value={this.state.data[itemKey]} name={itemKey} onChange={this.onDataChange}>
                      <option value={true}>True</option>
                      <option value={false}>False</option>
                    </Input>
                  ) : (
                    this.renderInput(itemKey)
                  )}
                </div>
              </div>
            ))
          : undefined}
      </div>
    );
  };

  render() {
    return (
      <Card>
        <CardHeader>{this.props.editorType === Consts.EditorTypes.EDIT ? "카드 수정" : "카드 추가"}</CardHeader>
        <CardBody className="editor">
          <form onSubmit={this.onSubmit}>
            {this.props.editorType === Consts.EditorTypes.EDIT ? (
              <div>
                <div style={styles.marginBottom10}>
                  <div style={styles.name}>Id</div>
                  <div style={styles.value}>{this.props.card.id}</div>
                </div>
                <div style={styles.marginBottom10}>
                  <div style={styles.name}>Deck Id</div>
                  <div style={styles.value}>{this.props.card.deckId}</div>
                </div>
                <div style={styles.marginBottom10}>
                  <div style={styles.name}>카드 등록일</div>
                  <div style={styles.value}>{new Date(this.props.card.registeredDate).toLocaleString()}</div>
                </div>
              </div>
            ) : undefined}
            <div>
              <div style={styles.marginBottom10}>
                <div style={styles.name}>Template</div>
                <div style={styles.value}>
                  <CardTemplateSelectField name="templateId" value={this.state.templateId} onChange={this.onTemplateChange} />
                </div>
              </div>
              {this.renderDataInput()}
            </div>
            <div />
            <div>
              <Button type="submit" size="sm" color="primary" block>
                {this.props.editorType === Consts.EditorTypes.EDIT ? "수정" : "추가"}
              </Button>
              {this.props.editorType === Consts.EditorTypes.EDIT ? (
                <Button type="button" size="sm" color="danger" onClick={this.onDeleteClick} block>
                  삭제
                </Button>
              ) : undefined}
            </div>
          </form>
        </CardBody>
      </Card>
    );
  }
}

const styles = {
  widthHalf: {
    width: "50%",
    display: "inline-block",
  },
  name: { width: 100, display: "inline-block", textAlign: "right", fontWeight: "bold", paddingRight: 10 },
  value: { width: "calc(100% - 100px)", display: "inline-block", paddingLeft: 10 },
  marginBottom10: {
    marginBottom: 10,
  },
};

// {"front": "ability","back": "능력","example": "He is a man of many abilities. 그는 다방면에 능력이 많은 사람이에요","voice": "9474578041892700160.mp3"}
// {"question":"다음이 설명하는 마케팅은?","example":"• '공익을 연계한 마케팅’의 준말로, 소비자의 상품 구매가 기업의 사회 기부로 이어지도록 하는 마케팅 기법이다.</br>• 1984년 미국 아메리칸 ...
// {"front": "잠이 깨다","back": "wake up "}
// {"front": "notice","back": "공지, 주의, 통보","class": "명","ex": "Service will be suspended until further notice.","ex_kor": "서비스는 추후 공지가 있을 때까지 일시 중단될 것이다.","ex_desc": "until은 suspend, postpone, put ...
// {"front":"Can I have a drink?  </br>물론이죠","frontVoice":"Can I have a drink?  ","back":"Certainly.","voice":"frontVoice","playVoice":"pop"}
// {"front": "likewise","back": "ad: 또한, 똑같이, 비슷하게"}
// {"front": "product","back": "n 제품</br>n 상품","class": "","ex": "We have to place an advertisement for the launch of the new product.","ex_kor": "우리는 신제품 출시를 위해 광고를 실어야 한다.","ex_desc": ""}

export default connect(CardEditor.StateToProps, CardEditor.DispatchToProps)(CardEditor);
