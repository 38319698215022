/**
 * Created by kimchangduk on 2017-08-14.
 */

import React from "react";
import update from "immutability-helper";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getDeckDetail } from "../../actions/deckDetail";
import * as Api from "../../Api";
import { Consts } from "../../constants";
import { Button, Card, CardBody, CardHeader, Input } from "reactstrap";
import { getMessageFromResponse } from "../../utils";
import DialogManager from "../../dialogs/DialogManager";

class PackageEditor extends React.Component {
  static propTypes = {
    onDelete: PropTypes.func,
    urlKey: PropTypes.string,
    editorType: PropTypes.string,
    package: PropTypes.object,
    element: PropTypes.object,
    packagePathIndexs: PropTypes.array,
    actions: PropTypes.object,
  };

  static defaultProps = {
    editorType: Consts.EditorTypes.EDIT,
  };

  static StateToProps = (state) => {
    return {};
  };

  static DispatchToProps = (dispatch, ownProps) => {
    return {
      actions: {
        getDeckDetail: () => {
          dispatch(getDeckDetail(ownProps.urlKey));
        },
      },
    };
  };

  state = {
    name: "",
    type: PackageTypes.TAB,
    lessons: [],
    packages: [],
  };

  componentDidMount() {
    this.initStatesWithPackage(this.props.editorType === Consts.EditorTypes.EDIT ? this.props.package : null);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.urlKey !== nextProps.urlKey ||
      this.props.editorType !== nextProps.editorType ||
      this.props.package !== nextProps.package ||
      this.props.element !== nextProps.element ||
      this.props.packagePathIndexs !== nextProps.packagePathIndexs
    ) {
      this.initStatesWithPackage(nextProps.editorType === Consts.EditorTypes.EDIT ? nextProps.package : null);
    }
  }

  initStatesWithPackage = (_package) => {
    if (_package) {
      this.setState({
        name: _package.name,
        type: _package.type,
        lessons: _package.lessons,
        packages: _package.packages,
      });
    } else {
      this.setState({
        name: "",
        type: !this.props.packagePathIndexs || this.props.packagePathIndexs.length <= 1 ? PackageTypes.TAB : PackageTypes.UNIT,
        lessons: [],
        packages: [],
      });
    }
  };

  getUpdateElement = (element, depth = undefined) => {
    if (depth === undefined) {
      depth = this.props.packagePathIndexs.length;
    }

    if (!element) {
      return null;
    }
    let updateElement = element;
    for (let i = 0; i < depth; i++) {
      updateElement = updateElement.packages[this.props.packagePathIndexs[i]];
    }
    return updateElement;
  };

  onInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    let element = update(this.props.element, {});
    if (!element) {
      element = { lessons: null, packages: [] };
    }
    const updateElement = this.getUpdateElement(element);

    if (this.props.editorType === Consts.EditorTypes.EDIT) {
      updateElement.name = this.state.name;
      updateElement.type = this.state.type;
    } else {
      if (!updateElement.packages) {
        updateElement.packages = [];
      }
      updateElement.packages.push({ name: this.state.name, lessons: null, packages: null, type: this.state.type });
    }

    Api.editDeckElement(this.props.urlKey, element).then((response) => {
      this.props.actions.getDeckDetail();
    });
  };

  onDeleteClick = () => {
    DialogManager.confirm(`'${this.props.package.name}' 패키지를 삭제하시겠습니까?`, () => {
      const element = update(this.props.element, {});
      const updateElement = this.getUpdateElement(element, this.props.packagePathIndexs.length - 1);
      const deleteIndex = this.props.packagePathIndexs[this.props.packagePathIndexs.length - 1];
      updateElement.packages.splice(deleteIndex, 1);
      Api.editDeckElement(this.props.urlKey, element).then((response) => {
        if (this.props.onDelete) {
          this.props.onDelete(this);
        }
        this.props.actions.getDeckDetail();
      });
    });
  };

  render() {
    let title = "";
    let element = this.props.element;
    if (element) {
      for (let i = 0; i < this.props.packagePathIndexs.length; i++) {
        element = element.packages[this.props.packagePathIndexs[i]];
        if (element) {
          title += element.name + " > ";
        } else {
          title += "??? > ";
        }
      }
    }

    if (this.props.editorType === Consts.EditorTypes.EDIT) {
      title += "패키지 수정";
    } else {
      title += "패키지 추가";
    }

    return (
      <Card>
        <CardHeader>
          {title} {JSON.stringify(this.props.packagePathIndexs)}
        </CardHeader>
        <CardBody>
          <form onSubmit={this.onSubmit}>
            <div style={styles.marginBottom10}>
              <div style={styles.name}>이름</div>
              <div style={styles.value}>
                <Input type="text" name="name" value={this.state.name ? this.state.name : ""} onChange={this.onInputChange} />
              </div>
            </div>
            <div style={styles.marginBottom10}>
              <div style={styles.name}>Type</div>
              <div style={styles.value}>
                <Input type="select" name="type" value={this.state.type ? this.state.type : ""} onChange={this.onInputChange}>
                  <option value={PackageTypes.TAB}>{PackageTypes.TAB}</option>
                  <option value={PackageTypes.UNIT}>{PackageTypes.UNIT}</option>
                </Input>
              </div>
            </div>
            <div>
              <Button type="submit" size="sm" color="primary" block>
                {this.props.editorType === Consts.EditorTypes.EDIT ? "수정" : "추가"}
              </Button>
              <Button type="button" size="sm" color="danger" onClick={this.onDeleteClick} block>
                삭제
              </Button>
            </div>
          </form>
        </CardBody>
      </Card>
    );
  }
}

const styles = {
  name: { width: 100, display: "inline-block", textAlign: "right", fontWeight: "bold", paddingRight: 10 },
  value: { width: "calc(100% - 100px)", display: "inline-block", paddingLeft: 10 },
  marginBottom10: {
    marginBottom: 10,
  },
};

const PackageTypes = {
  TAB: "TAB",
  UNIT: "UNIT",
};

export default connect(PackageEditor.StateToProps, PackageEditor.DispatchToProps)(PackageEditor);
