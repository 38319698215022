/**
 * Created by kimchangduk on 2018-03-03.
 */

import React from "react";
import PropTypes from "prop-types";
import { Input, Table } from "reactstrap";
import Radium from "radium";

class AutoCompleteInput extends React.Component {
  static propTypes = {
    onSelect: PropTypes.func,
    dataSource: PropTypes.array,
    placeholder: PropTypes.string,
    onFilter: PropTypes.func,
    searchResultHeaders: PropTypes.array,
    onTdMapping: PropTypes.func.isRequired,
  };

  state = {
    inputStr: "",
    inputFocus: false,
  };

  handleOnChange = (e) => {
    this.setState({ inputStr: e.target.value });
  };

  onFocus = () => {
    this.setState({ inputFocus: true });
  };

  onBlur = () => {
    window.setTimeout(() => {
      this.setState({ inputFocus: false });
    }, 100);
  };

  render() {
    const itemList =
      this.props.dataSource && this.props.onFilter
        ? this.props.dataSource.filter((a) => {
            return this.props.onFilter(a, this.state.inputStr);
          })
        : this.props.dataSource;

    const resultHeaders = this.props.searchResultHeaders;

    return (
      <div className="position-relative">
        <Input
          type="text"
          value={this.state.inputStr}
          onChange={this.handleOnChange}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          placeholder={this.props.placeholder}
        />
        {this.state.inputFocus && itemList && itemList.length > 0 ? (
          <div style={styles.itemList}>
            <Table className="small-table membox-admin-contents-list">
              {resultHeaders ? (
                <thead>
                  <tr>
                    {resultHeaders.map((item, key) => {
                      return <th key={key}>{item}</th>;
                    })}
                  </tr>
                </thead>
              ) : undefined}
              <tbody>
                {itemList.map((item, key) => {
                  return (
                    <tr
                      key={key}
                      style={styles.listItem}
                      onMouseDown={() => {
                        this.setState({ inputStr: "" });
                        if (this.props.onSelect) this.props.onSelect(item);
                      }}
                    >
                      {this.props.onTdMapping(item).map((item2, key2) => {
                        return <td key={key2}>{item2}</td>;
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        ) : undefined}
      </div>
    );
  }
}

const styles = {
  itemList: {
    position: "absolute",
    top: "100%",
    left: 0,
    right: 0,
    maxHeight: 300,
    height: "auto",
    backgroundColor: "#FFFFFF",
    border: "1px solid #c8c8c8",
    overflowY: "auto",
    zIndex: 1,
  },
  listItem: {
    cursor: "pointer",
    [":hover"]: {
      backgroundColor: "#B3E5FC",
    },
  },
};

export default Radium(AutoCompleteInput);
