/**
 * Created by kimchangduk on 2017-08-22.
 */

import React from 'react';
import TestEditor from "../../components/CBT/TestEditor";

class CBTTest extends React.Component {

    render() {
        return (<div>
            <TestEditor/>
        </div>);
    }
}

export default CBTTest;