/**
 * Created by kimchangduk on 2017-07-25.
 */

import *  as Api from '../Api';
import {Consts, ActionTypes} from '../constants';

export function getDeckList() {
    return dispatch => {
        dispatch({type: ActionTypes.GET_DECK_LIST_REQUEST});
        Api.getDeckList().then(response => {
            dispatch({type: ActionTypes.GET_DECK_LIST_SUCCESS, data: response.data});
        }).catch(error => {
            dispatch({type: ActionTypes.GET_DECK_LIST_FAILURE,});
        });
    };
}