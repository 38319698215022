/* eslint-disable react/no-danger */
/**
 * Created by kimchangduk on 2017-08-15.
 */

import React from "react";
import PropTypes from "prop-types";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";

class DefaultDialog extends React.Component {
  static propTypes = {
    modal: PropTypes.bool,
    onClose: PropTypes.func,
    title: PropTypes.node,
    content: PropTypes.node,
    buttons: PropTypes.array,
  };

  componentDidMount() {}

  render() {
    return (
      <Dialog open={true} onClose={this.props.modal ? undefined : this.props.onClose}>
        {this.props.title !== undefined ? <DialogTitle>{this.props.title}</DialogTitle> : undefined}
        <DialogContent style={{ minWidth: 350 }}>
          {typeof this.props.content === "string" ? (
            <DialogContentText>
              <div dangerouslySetInnerHTML={{ __html: this.props.content }} />
            </DialogContentText>
          ) : (
            this.props.content
          )}
        </DialogContent>
        {this.props.buttons ? (
          <DialogActions>
            {this.props.buttons.map((item, key) => (
              <Button
                key={key}
                onClick={() => {
                  if (item.onClick) {
                    item.onClick(this);
                  }
                }}
                color={item.color ? item.color : "primary"}
              >
                {item.text}
              </Button>
            ))}
          </DialogActions>
        ) : undefined}
      </Dialog>
    );
  }
}

export default DefaultDialog;
