/**
 * Created by kimchangduk on 2017-04-23.
 */

// User
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGIN_WITH_FACEBOOK_REQUEST = 'LOGIN_WITH_FACEBOOK_REQUEST';
export const LOGIN_WITH_FACEBOOK_SUCCESS = 'LOGIN_WITH_FACEBOOK_SUCCESS';
export const LOGIN_WITH_FACEBOOK_FAILURE = 'LOGIN_WITH_FACEBOOK_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE';

export const SIDE_MENU_OPEN = 'SIDE_MENU_OPEN';
export const SIDE_MENU_CLOSE = 'SIDE_MENU_CLOSE';

//<editor-fold desc="Card Deck">
export const GET_DECK_LIST_REQUEST = 'GET_DECK_LIST_REQUEST';
export const GET_DECK_LIST_SUCCESS = 'GET_DECK_LIST_SUCCESS';
export const GET_DECK_LIST_FAILURE = 'GET_DECK_LIST_FAILURE';

export const GET_DECK_DETAIL_REQUEST = 'GET_DECK_DETAIL_REQUEST';
export const GET_DECK_DETAIL_SUCCESS = 'GET_DECK_DETAIL_SUCCESS';
export const GET_DECK_DETAIL_FAILURE = 'GET_DECK_DETAIL_FAILURE';

export const GET_DECK_CARDS_REQUEST = 'GET_DECK_CARDS_REQUEST';
export const GET_DECK_CARDS_SUCCESS = 'GET_DECK_CARDS_SUCCESS';
export const GET_DECK_CARDS_FAILURE = 'GET_DECK_CARDS_FAILURE';
//</editor-fold>

//<editor-fold desc="Membox">
export const GET_TOTAL_MEMBOX_INFO_REQUEST = "GET_TOTAL_MEMBOX_INFO_REQUEST";
export const GET_TOTAL_MEMBOX_INFO_SUCCESS = "GET_TOTAL_MEMBOX_INFO_SUCCESS";
export const GET_TOTAL_MEMBOX_INFO_FAILURE = "GET_TOTAL_MEMBOX_INFO_FAILURE";

export const GET_TOTAL_MEMBOX_CARD_REQUEST = "GET_TOTAL_MEMBOX_CARD_REQUEST";
export const GET_TOTAL_MEMBOX_CARD_SUCCESS = "GET_TOTAL_MEMBOX_CARD_SUCCESS";
export const GET_TOTAL_MEMBOX_CARD_FAILURE = "GET_TOTAL_MEMBOX_CARD_FAILURE";

export const GET_DECK_MEMBOX_INFO_REQUEST = "GET_DECK_MEMBOX_INFO_REQUEST";
export const GET_DECK_MEMBOX_INFO_SUCCESS = "GET_DECK_MEMBOX_INFO_SUCCESS";
export const GET_DECK_MEMBOX_INFO_FAILURE = "GET_DECK_MEMBOX_INFO_FAILURE";

export const GET_DECK_MEMBOX_CARD_REQUEST = "GET_DECK_MEMBOX_CARD_REQUEST";
export const GET_DECK_MEMBOX_CARD_SUCCESS = "GET_DECK_MEMBOX_CARD_SUCCESS";
export const GET_DECK_MEMBOX_CARD_FAILURE = "GET_DECK_MEMBOX_CARD_FAILURE";

export const GET_DECK_MEMBOX_LEVEL_CARD_REQUEST = "GET_DECK_MEMBOX_LEVEL_CARD_REQUEST";
export const GET_DECK_MEMBOX_LEVEL_CARD_SUCCESS = "GET_DECK_MEMBOX_LEVEL_CARD_SUCCESS";
export const GET_DECK_MEMBOX_LEVEL_CARD_FAILURE = "GET_DECK_MEMBOX_LEVEL_CARD_FAILURE";

export const GET_TOTAL_MEMBOX_LEVEL_CARD_REQUEST = "GET_TOTAL_MEMBOX_LEVEL_CARD_REQUEST";
export const GET_TOTAL_MEMBOX_LEVEL_CARD_SUCCESS = "GET_TOTAL_MEMBOX_LEVEL_CARD_SUCCESS";
export const GET_TOTAL_MEMBOX_LEVEL_CARD_FAILURE = "GET_TOTAL_MEMBOX_LEVEL_CARD_FAILURE";

export const MEMORIZE_CARD_REQUEST = "MEMORIZE_CARD_REQUEST";
export const MEMORIZE_CARD_SUCCESS = "MEMORIZE_CARD_SUCCESS";
export const MEMORIZE_CARD_FAILURE = "MEMORIZE_CARD_FAILURE";
//</editor-fold>

//<editor-fold desc="Board">
export const GET_BOARD_LIST_REQUEST = "GET_BOARD_LIST_REQUEST";
export const GET_BOARD_LIST_SUCCESS = "GET_BOARD_LIST_SUCCESS";
export const GET_BOARD_LIST_FAILURE = "GET_BOARD_LIST_FAILURE";

export const GET_BOARD_ITEM_VIEW_REQUEST = "GET_BOARD_ITEM_VIEW_REQUEST";
export const GET_BOARD_ITEM_VIEW_SUCCESS = "GET_BOARD_ITEM_VIEW_SUCCESS";
export const GET_BOARD_ITEM_VIEW_FAILURE = "GET_BOARD_ITEM_VIEW_FAILURE";

export const POST_BOARD_ITEM_REQUEST = "POST_BOARD_ITEM_REQUEST";
export const POST_BOARD_ITEM_SUCCESS = "POST_BOARD_ITEM_SUCCESS";
export const POST_BOARD_ITEM_FAILURE = "POST_BOARD_ITEM_FAILURE";

export const EDIT_BOARD_ITEM_REQUEST = "EDIT_BOARD_ITEM_REQUEST";
export const EDIT_BOARD_ITEM_SUCCESS = "EDIT_BOARD_ITEM_SUCCESS";
export const EDIT_BOARD_ITEM_FAILURE = "EDIT_BOARD_ITEM_FAILURE";

export const DELETE_BOARD_ITEM_REQUEST = "DELETE_BOARD_ITEM_REQUEST";
export const DELETE_BOARD_ITEM_SUCCESS = "DELETE_BOARD_ITEM_SUCCESS";
export const DELETE_BOARD_ITEM_FAILURE = "DELETE_BOARD_ITEM_FAILURE";

export const POST_BOARD_ITEM_COMMENT_REQUEST = "POST_BOARD_ITEM_COMMENT_REQUEST";
export const POST_BOARD_ITEM_COMMENT_SUCCESS = "POST_BOARD_ITEM_COMMENT_SUCCESS";
export const POST_BOARD_ITEM_COMMENT_FAILURE = "POST_BOARD_ITEM_COMMENT_FAILURE";

export const DELETE_BOARD_ITEM_COMMENT_REQUEST = "DELETE_BOARD_ITEM_COMMENT_REQUEST";
export const DELETE_BOARD_ITEM_COMMENT_SUCCESS = "DELETE_BOARD_ITEM_COMMENT_SUCCESS";
export const DELETE_BOARD_ITEM_COMMENT_FAILURE = "DELETE_BOARD_ITEM_COMMENT_FAILURE";
//</editor-fold>

//<editor-fold desc="CBT">
export const GET_CBT_TEST_LIST_REQUEST = "GET_TEST_CLASS_LIST_REQUEST";
export const GET_CBT_TEST_LIST_SUCCESS = "GET_TEST_CLASS_LIST_SUCCESS";
export const GET_CBT_TEST_LIST_FAILURE = "GET_TEST_CLASS_LIST_FAILURE";

export const GET_CBT_CLASS_LIST_REQUEST = "GET_CBT_CLASS_LIST_REQUEST";
export const GET_CBT_CLASS_LIST_SUCCESS = "GET_CBT_CLASS_LIST_SUCCESS";
export const GET_CBT_CLASS_LIST_FAILURE = "GET_CBT_CLASS_LIST_FAILURE";

export const GET_CBT_SUBJECT_LIST_REQUEST = "GET_CBT_SUBJECT_LIST_REQUEST";
export const GET_CBT_SUBJECT_LIST_SUCCESS = "GET_CBT_SUBJECT_LIST_SUCCESS";
export const GET_CBT_SUBJECT_LIST_FAILURE = "GET_CBT_SUBJECT_LIST_FAILURE";

export const GET_CBT_DETAIL_REQUEST = "GET_CBT_DETAIL_REQUEST";
export const GET_CBT_DETAIL_SUCCESS = "GET_CBT_DETAIL_SUCCESS";
export const GET_CBT_DETAIL_FAILURE = "GET_CBT_DETAIL_FAILURE";
//</editor-fold>

//<editor-fold desc="Template">
export const GET_TEMPLATE_REQUEST = "GET_TEMPLATE_REQUEST";
export const GET_TEMPLATE_SUCCESS = "GET_TEMPLATE_SUCCESS";
export const GET_TEMPLATE_FAILURE = "GET_TEMPLATE_FAILURE";
//</editor-fold>

//<editor-fold desc="Membership List">
export const GET_MEMBERSHIP_LIST_REQUEST = "GET_MEMBERSHIP_LIST_REQUEST";
export const GET_MEMBERSHIP_LIST_SUCCESS = "GET_MEMBERSHIP_LIST_SUCCESS";
export const GET_MEMBERSHIP_LIST_FAILURE = "GET_MEMBERSHIP_LIST_FAILURE";

export const GET_MEMBERSHIP_REQUEST = "GET_MEMBERSHIP_REQUEST";
export const GET_MEMBERSHIP_SUCCESS = "GET_MEMBERSHIP_SUCCESS";
export const GET_MEMBERSHIP_FAILURE = "GET_MEMBERSHIP_FAILURE";

export const POST_MEMBERSHIP_REQUEST = "POST_MEMBERSHIP_REQUEST";
export const POST_MEMBERSHIP_SUCCESS = "POST_MEMBERSHIP_SUCCESS";
export const POST_MEMBERSHIP_FAILURE = "POST_MEMBERSHIP_FAILURE";

export const PUT_MEMBERSHIP_REQUEST = "PUT_MEMBERSHIP_REQUEST";
export const PUT_MEMBERSHIP_SUCCESS = "PUT_MEMBERSHIP_SUCCESS";
export const PUT_MEMBERSHIP_FAILURE = "PUT_MEMBERSHIP_FAILURE";
//</editor-fold>

//<editor-fold desc="Hashtag">
export const ADD_HASHTAG_REQUEST = 'ADD_HASHTAG_REQUEST';
export const ADD_HASHTAG_SUCCESS = 'ADD_HASHTAG_SUCCESS';
export const ADD_HASHTAG_FAILURE = 'ADD_HASHTAG_FAILURE';

export const REMOVE_HASHTAG_REQUEST = 'REMOVE_HASHTAG_REQUEST';
export const REMOVE_HASHTAG_SUCCESS = 'REMOVE_HASHTAG_SUCCESS';
export const REMOVE_HASHTAG_FAILURE = 'REMOVE_HASHTAG_FAILURE';
//</editor-fold>