/**
 * Created by kimchangduk on 2018-03-04.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import AutoCompleteInput from "./AutoCompleteInput";
import {Consts} from "../../constants";
import {getDeckList} from "../../actions/deckList";

class DeckAutoCompleteInput extends React.Component {
    static propTypes = {
        actions: PropTypes.object,
        dataSource: PropTypes.array,
        deckListState: PropTypes.object,
        onSelect: PropTypes.func,
        initialLoad: PropTypes.bool
    };

    static defaultProps = {
        initialLoad: true
    };

    static StateToProps = (state, ownProps) => {
        return {
            dataSource: state.deckList.dataSource,
            deckListState: state.deckList.state,
        };
    };

    static DispatchToProps = (dispatch, ownProps) => {
        return {
            actions: {
                getDataSource: () => {
                    dispatch(getDeckList());
                }
            }
        };
    };

    componentDidMount() {
        if (!this.props.initialLoad) {
            return;
        }

        const {deckListState, dataSource} = this.props;
        if (
            deckListState.request !== Consts.REQUEST_WAITING &&
            (
                dataSource ||
                deckListState.request !== Consts.REQUEST_SUCCESS ||
                (deckListState.lastRequestTime - new Date()) > 1000
            )
        ) {
            this.props.actions.getDataSource();
        }
    }

    render() {
        return (<AutoCompleteInput
            onSelect={this.props.onSelect}
            dataSource={this.props.dataSource}
            placeholder="덱 이름 or urlKey or id"
            onFilter={(item, input) => input ?
                (
                    item.name.toLowerCase().match(input.toLowerCase()) ||
                    item.urlKey.toLowerCase().match(input.toLowerCase()) ||
                    item.id.toString().toLowerCase().match(input.toLowerCase())
                )
                : true}
            searchResultHeaders={["", "이름", "urlKey", "id"]}
            onTdMapping={(item) => {
                return [
                    item.coverImage ? <img src={item.coverImage} className="thumbnail"/> : '',
                    item.name,
                    item.urlKey,
                    item.id
                ];
            }}/>);
    }
}

const style = {
    itemItemThumbnail: {
        width: 30, height: 30,
        verticalAlign: 'middle'
    }
};

export default connect(DeckAutoCompleteInput.StateToProps, DeckAutoCompleteInput.DispatchToProps)(DeckAutoCompleteInput);