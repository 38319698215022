/**
 * Created by kimchangduk on 2018-02-07.
 */

import React from 'react';
import PropTypes from 'prop-types';
import CouponAddedResults from "../../components/Coupons/CouponAddedResults";

class CouponsAddResult extends React.Component {
    static propTypes = {
        actions: PropTypes.object,
        location: PropTypes.object,
        history: PropTypes.object
    };

    render() {
        return (<div>
            <CouponAddedResults/>
        </div>);
    }
}

export default CouponsAddResult;