/**
 * Created by kimchangduk on 2017-10-30.
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Card, CardBody, CardHeader, Col } from "reactstrap";
import ListSearch from "../ListSearch/ListSearch";
import DialogManager from "../../dialogs/DialogManager";
import { getMessageFromResponse } from "../../utils";
import * as Api from "../../Api";

class PassGradeEditor extends React.Component {
  static propTypes = {
    onUpdated: PropTypes.func,
    actions: PropTypes.object,
    test: PropTypes.object,
  };

  static StateToProps = (state, ownProps) => {
    return {};
  };

  static DispatchToProps = (dispatch, ownProps) => {
    return {
      actions: {},
    };
  };

  state = {
    passGradeJson: "",
  };

  componentDidMount() {
    this.initialize(this.props.test);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.test !== nextProps.test) {
      this.initialize(nextProps.test);
    }
  }

  initialize = (item) => {
    this.setState({
      passGradeJson: JSON.stringify(item.passGrade ? item.passGrade : {}),
    });
  };

  onInputChange = (e) => {
    let newValue = e.target.value;
    try {
      if (!newValue) {
        newValue = 0;
      } else {
        newValue = parseInt(newValue);
      }
    } catch (error) {
      return;
    }

    const passGradeState = this.state.passGradeJson ? JSON.parse(this.state.passGradeJson) : {};
    if (e.target.name === "null") {
      passGradeState.passScore = newValue;
    } else {
      if (!passGradeState.classSubjects) {
        passGradeState.classSubjects = [];
      }

      const id = parseInt(e.target.name);
      let classSubject = passGradeState.classSubjects.find((a) => a.classSubjectId === id);
      if (!classSubject) {
        classSubject = { classSubjectId: id, passScore: newValue };
        passGradeState.classSubjects.push(classSubject);
      } else {
        classSubject.passScore = newValue;
      }
    }

    this.setState({
      passGradeJson: JSON.stringify(passGradeState),
    });
  };

  onEditClick = () => {
    const passGrade = JSON.parse(this.state.passGradeJson);
    const passGradeClassSubjects = passGrade.classSubjects;
    const classSubjects = this.props.test.classSubjects;
    if (classSubjects && passGradeClassSubjects) {
      passGrade.classSubjects = [];
      for (let classSubject of classSubjects) {
        const passGradeClassSubject = passGradeClassSubjects.find((a) => a.classSubjectId === classSubject.id);
        if (passGradeClassSubject) {
          passGrade.classSubjects.push(passGradeClassSubject);
        }
      }
    }

    Api.editCBTPassGrade(this.props.test.id, JSON.stringify(passGrade))
      .then((response) => {
        if (this.props.onUpdated) {
          this.props.onUpdated();
        }
      })
      .catch((error) => {
        DialogManager.alert(getMessageFromResponse(error.response, "합격 기준 변경에 실패했습니다."));
      });
  };

  render() {
    const passGradeState = this.state.passGradeJson ? JSON.parse(this.state.passGradeJson) : {};
    return (
      <Card>
        <CardHeader>합격 기준 설정</CardHeader>
        <CardBody className="editor">
          <div className="data-row">
            <div className="name" style={styles.width30Percent}>
              전체 점수 기준
            </div>
            <div className="value" style={styles.width70Percent}>
              <input
                type="text"
                name="null"
                value={passGradeState.passScore !== null && passGradeState.passScore !== undefined ? passGradeState.passScore.toString() : ""}
                onChange={this.onInputChange}
              />
            </div>
          </div>
          {this.props.test &&
            this.props.test.classSubjects.map((item, key) => {
              let value = "";
              if (passGradeState && passGradeState.classSubjects) {
                const classSubject = passGradeState.classSubjects.find((a) => a.classSubjectId === item.id);
                if (classSubject !== null && classSubject !== undefined) {
                  value = classSubject.passScore !== null && classSubject.passScore !== undefined ? classSubject.passScore.toString() : "";
                }
              }

              return (
                <div className="data-row" key={key}>
                  <div className="name" style={styles.width30Percent}>{`${item.clazz ? item.clazz.name : ""} / ${item.subject ? item.subject.name : ""}`}</div>
                  <div className="value" style={styles.width70Percent}>
                    <input name={item.id} value={value} onChange={this.onInputChange} type="text" className="full-width" />
                  </div>
                </div>
              );
            })}
          {this.state.passGradeJson}
          <Button block color="primary" onClick={this.onEditClick}>
            수정
          </Button>
        </CardBody>
      </Card>
    );
  }
}

const styles = {
  halfWidth: {
    width: "50%",
  },
  fullWidth: {
    width: "100%",
  },
  width30Percent: {
    width: "30%",
  },
  width70Percent: {
    width: "70%",
  },
};

export default connect(PassGradeEditor.StateToProps, PassGradeEditor.DispatchToProps)(PassGradeEditor);
