/**
 * Created by kimchangduk on 2017-08-28.
 */

import React from "react";
import ReactDOM from "react-dom";
import Radium from "radium";
import PropTypes from "prop-types";
import { Input, Table } from "reactstrap";
import * as Api from "../Api";
import { scrollIntoView } from "../utils";

class UserSearch extends React.Component {
  static propTypes = {
    onSelect: PropTypes.func,
  };

  state = {
    visibleList: false,
    searchText: "",
    searchResults: [],
    selectionIndex: -1,
  };

  searchTimeout = null;

  onKeyDown = (e) => {
    switch (e.keyCode) {
      // 방향 위
      case 38: {
        const newIndex = Math.max(-1, this.state.selectionIndex - 1);
        this.setState({ selectionIndex: newIndex });
        this.scrollSearchResultsTo(newIndex);
        break;
      }

      // 방향 아래
      case 40: {
        const newIndex = Math.min(this.state.searchResults.length - 1, this.state.selectionIndex + 1);
        this.setState({ selectionIndex: newIndex });
        this.scrollSearchResultsTo(newIndex);
        break;
      }

      case 13: {
        const searchResult = this.state.searchResults[this.state.selectionIndex];
        if (searchResult && this.props.onSelect) {
          this.props.onSelect(searchResult);
        }

        const inputComponent = this.refs.input;
        if (inputComponent) {
          const element = ReactDOM.findDOMNode(inputComponent);
          if (element) {
            element.blur();
          }
        }
        break;
      }
    }
  };

  scrollSearchResultsTo = (index) => {
    const container = this.refs["search-results"];
    const item = this.refs[`search-result-${index}`];
    if (!item || !container) {
      return;
    }
    scrollIntoView(item, container);
  };

  onChange = (e) => {
    this.setState({ searchText: e.target.value });

    if (this.searchTimeout) {
      window.clearTimeout(this.searchTimeout);
    }
    const searchText = e.target.value;
    this.searchTimeout = window.setTimeout(() => {
      this.search(searchText);
    }, 300);
  };

  search = (text) => {
    if (!text) {
      this.setState({ searchResults: [], selectionIndex: -1 });
      return;
    }

    Api.searchUser(text)
      .then((response) => {
        if (this.state.searchText === text) this.setState({ searchResults: response.data, selectionIndex: -1 });
      })
      .catch((error) => {
        if (this.state.searchText === text) this.setState({ searchResults: [], selectionIndex: -1 });
      });
  };

  onFocus = () => {
    this.setState({ visibleList: true });
  };

  onBlur = () => {
    setTimeout(() => {
      this.setState({ visibleList: false });
    }, 300);
  };

  render() {
    return (
      <div style={styles.container}>
        <Input
          ref="input"
          type="text"
          placeholder="이메일 or 이름 or 닉네임"
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          value={this.state.searchText}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          style={styles.input}
        />
        {this.state.visibleList && this.state.searchResults.length > 0 ? (
          <div ref="search-results" style={styles.searchResults}>
            <Table>
              <tr>
                <th style={styles.td}>id</th>
                <th style={styles.td}>email</th>
                <th style={styles.td}>name</th>
                <th style={styles.td}>nickname</th>
              </tr>
              {this.state.searchResults.map((item, key) => {
                return (
                  <tr
                    onClick={() => {
                      if (this.props.onSelect) {
                        this.props.onSelect(item);
                      }
                    }}
                    ref={`search-result-${key}`}
                    key={key}
                    style={[styles.searchResult, this.state.selectionIndex === key ? styles.selectedSearchResult : false]}
                  >
                    <td style={styles.td}>{item.id}</td>
                    <td style={styles.td}>{item.email}</td>
                    <td style={styles.td}>{item.name}</td>
                    <td style={styles.td}>{item.nickname}</td>
                  </tr>
                );
              })}
            </Table>
          </div>
        ) : undefined}
      </div>
    );
  }
}

const styles = {
  container: {
    position: "relative",
  },
  input: {},
  searchResults: {
    position: "absolute",
    left: 0,
    right: 0,
    top: "100%",
    maxHeight: 300,
    overflowY: "auto",
    background: "#FFFFFF",
    border: "1px solid #0D47A1",
  },
  searchResult: {
    padding: 5,
    cursor: "pointer",
  },
  selectedSearchResult: {
    background: "#81D4FA",
  },
  td: {
    paddingTop: 3,
    paddingBottom: 3,
  },
};

export default Radium(UserSearch);
