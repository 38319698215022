/**
 * Created by kimchangduk on 2017-07-19.
 */
import {combineReducers} from 'redux';
import userReducer from './user';
import deckListReducer from './deckList';
import deckDetailReducer from './deckDetail';
import deckCardsReducer from './deckCards';
import boardReducer from './board';
import cbtReducer from './cbt';
import templateReducer from './template';
import membershipsReducer from './memberships';
import hashtagReducer from './hashtag';

export default combineReducers({
    user: userReducer,
    deckList: deckListReducer,
    deckDetail: deckDetailReducer,
    deckCards: deckCardsReducer,
    board: boardReducer,
    cbt: cbtReducer,
    template: templateReducer,
    memberships: membershipsReducer,
    hashtag: hashtagReducer
});
