import {ActionTypes} from '../constants';
import * as Api from '../Api';
import {getMessageFromResponse} from "../utils";

export function getMembershipList() {
    return (dispatch) => {
        dispatch({type: ActionTypes.GET_MEMBERSHIP_LIST_REQUEST});
        Api.getMembershipList().then(response => {
            dispatch({type: ActionTypes.GET_MEMBERSHIP_LIST_SUCCESS, data: response.data});
        }).catch(error => {
            dispatch({
                type: ActionTypes.GET_MEMBERSHIP_LIST_FAILURE,
                errorMessage: getMessageFromResponse(error.response, '멤버쉽 리스트를 가져오는데 실패했습니다.')
            });
        });
    };
}

export function getMembership(urlKey) {
    return (dispatch) => {
        dispatch({type: ActionTypes.GET_MEMBERSHIP_REQUEST, urlKey});
        Api.getMembership(urlKey).then(response => {
            dispatch({type: ActionTypes.GET_MEMBERSHIP_SUCCESS, data: response.data, urlKey});
        }).catch(error => {
            dispatch({
                type: ActionTypes.GET_MEMBERSHIP_FAILURE,
                urlKey,
                errorMessage: getMessageFromResponse(error.response, '멤버쉽 리스트를 가져오는데 실패했습니다.')
            });
        });
    };
}

export function addMembership(name, description, coverImage) {
    return (dispatch) => {
        dispatch({type: ActionTypes.POST_MEMBERSHIP_REQUEST});
        Api.addMembership(name, description, coverImage).then(response => {
            if (coverImage !== null && coverImage !== undefined) {
                const responseData = response.data;
                Api.editMembershipImage(responseData.urlKey, coverImage).then(response => {
                    dispatch({type: ActionTypes.POST_MEMBERSHIP_SUCCESS, data: response.data});
                    getMembershipList()(dispatch);
                }).catch(error => {
                    dispatch({type: ActionTypes.POST_MEMBERSHIP_SUCCESS, data: response.data});
                    getMembershipList()(dispatch);
                });
            } else {
                dispatch({type: ActionTypes.POST_MEMBERSHIP_SUCCESS, data: response.data});
                getMembershipList()(dispatch);
            }
        }).catch(error => {
            dispatch({
                type: ActionTypes.POST_MEMBERSHIP_FAILURE,
                errorMessage: getMessageFromResponse(error.response, '멤버쉽 리스트를 추가하는데 실패했습니다.')
            });
        });
    };
}

export function editMembership(urlKey, name, description, visibility, coverImage, deckIds, testIds) {
    return (dispatch) => {
        dispatch({type: ActionTypes.PUT_MEMBERSHIP_REQUEST});
        Api.editMembership(urlKey, name, description, visibility, deckIds, testIds).then(response => {
            if (coverImage !== null && coverImage !== undefined) {
                const responseData = response.data;
                Api.editMembershipImage(responseData.urlKey, coverImage).then(response => {
                    dispatch({type: ActionTypes.PUT_MEMBERSHIP_SUCCESS, data: response.data});
                    getMembershipList()(dispatch);
                }).catch(error => {
                    dispatch({type: ActionTypes.PUT_MEMBERSHIP_SUCCESS, data: response.data});
                    getMembershipList()(dispatch);
                });
            } else {
                dispatch({type: ActionTypes.PUT_MEMBERSHIP_SUCCESS, data: response.data});
                getMembershipList()(dispatch);
            }
        }).catch(error => {
            dispatch({
                type: ActionTypes.PUT_MEMBERSHIP_FAILURE,
                errorMessage: getMessageFromResponse(error.response, '멤버쉽 리스트를 가져오는데 실패했습니다.')
            });
        });
    };
}