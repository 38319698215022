/**
 * Created by kimchangduk on 2017-09-10.
 */

import React from "react";
import update from "immutability-helper";
import { Strings } from "../../constants";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as Api from "../../Api";
import DialogManager from "../../dialogs/DialogManager";
import { Button, Col, Input, Row } from "reactstrap";
import "./Category.scss";
import DeckListEditor from "../../components/MemboxContentsListEditor/DeckListEditor";
import CBTTestListEditor from "../../components/MemboxContentsListEditor/CBTTestListEditor";
import MembershipListEditor from "../../components/MemboxContentsListEditor/MembershipListEditor";

class Category extends React.Component {
  static propTypes = {
    actions: PropTypes.object,
  };

  static StateToProps = (state, ownProps) => {
    return {};
  };

  static DispatchToProps = (dispatch, ownProps) => {
    return {
      actions: {},
    };
  };

  state = {
    categoryList: [],
    selectedCategoryId: null,
    editType: CategoryEditTypes.NONE,
    editName: "",
    deckIds: "[]",
    cbtIds: "[]",
    membershipIds: "[]",
    parentId: null,
  };

  componentDidMount() {
    this.loadCategoryList();
  }

  loadCategoryList = () => {
    Api.getCategoryList()
      .then((response) => {
        this.setState({ categoryList: response.data });
      })
      .catch((error) => {
        DialogManager.alert("카테고리 리스트를 불러올 수 없습니다");
      });
  };

  onCategoryClick = (id, parentId = null) => {
    const category = this.getCategory(id);
    if (category) {
      this.setState({ editType: CategoryEditTypes.UPDATE, selectedCategoryId: id, editName: category.name });
      Api.getCategory(id)
        .then((response) => {
          let deckIds = response.data.deckList ? JSON.stringify(response.data.deckList.map((item) => item.id)) : "[]";
          let cbtIds = response.data.testList ? JSON.stringify(response.data.testList.map((item) => item.id)) : "[]";
          let membershipIds = response.data.membershipList ? JSON.stringify(response.data.membershipList.map((item) => item.id)) : "[]";
          this.setState({ deckIds, cbtIds, membershipIds, parentId });
        })
        .catch((ex) => {
          this.setState({ deckIds: "[]", cbtIds: "[]", membershipIds: "[]", parentId: null });
        });
    }
  };

  onCategoryAddClick = (e) => {
    e.stopPropagation();
    const clickElement = e.target.parentElement;
    if (clickElement) {
      let id = parseInt(clickElement.getAttribute("data"));
      if (isNaN(id)) {
        id = null;
      }
      this.setState({ editType: CategoryEditTypes.CREATE, selectedCategoryId: id, editName: "", parentId: id });
    }
  };

  onUpdateClick = (e) => {
    let parentId = undefined;
    if (this.state.parentId !== null && this.state.parentId !== "") {
      parentId = parseInt(this.state.parentId);
      if (isNaN(parentId)) {
        DialogManager.alert("부모 카테고리 Id가 숫자가 아닙니다.");
        return;
      }
    }

    Api.updateCategory(this.state.selectedCategoryId, parentId, this.state.editName).then(() => {
      this.loadCategoryList();
    });
  };

  onDeleteClick = (e) => {
    const category = this.getCategory(this.state.selectedCategoryId);
    if (category) {
      if (category.subCategories && category.subCategories.length > 0) {
        DialogManager.alert("하위 카테고리를 먼저 삭제해 주세요.");
      } else {
        DialogManager.confirm(Strings.CONFIRM_DELETE_REALLY, () => {
          Api.deleteCategory(this.state.selectedCategoryId).then(() => {
            this.setState({
              selectedCategoryId: -1,
              editType: CategoryEditTypes.NONE,
            });
            this.loadCategoryList();
          });
        });
      }
    } else {
      DialogManager.alert("카테고리를 찾을 수 없습니다");
    }
  };

  onCreateClick = (e) => {
    Api.createCategory(this.state.selectedCategoryId, this.state.editName).then(() => {
      this.loadCategoryList();
    });
  };

  onUpdateItemClick = () => {
    let deckIds = [];
    let testIds = [];
    let membershipIds = [];
    try {
      deckIds = JSON.parse(this.state.deckIds);
    } catch (e) {
      DialogManager.alert("Deck Ids값이 잘못됬습니다.");
      return;
    }

    try {
      testIds = JSON.parse(this.state.cbtIds);
    } catch (e) {
      DialogManager.alert("CBT Ids값이 잘못됬습니다.");
      return;
    }

    try {
      membershipIds = JSON.parse(this.state.membershipIds);
    } catch (e) {
      DialogManager.alert("Membership Ids값이 잘못됬습니다.");
      return;
    }

    Api.updateCategoryItem(this.state.selectedCategoryId, deckIds, testIds, membershipIds);
  };

  getCategory = (id, rootCategories) => {
    if (id === undefined || id === null) {
      return null;
    }

    if (rootCategories === undefined) {
      rootCategories = this.state.categoryList;
    }
    for (let category of rootCategories) {
      if (category.id === id) {
        return category;
      }
      const result = this.getCategory(id, category.subCategories);
      if (result) {
        return result;
      }
    }
    return null;
  };

  renderCategories = () => {
    return (
      <ul className="category-list">
        <li>
          Root
          <i className="icon-plus add-btn" onClick={this.onCategoryAddClick} />
        </li>
        {this.travelCategorys(this.state.categoryList, 1)}
      </ul>
    );
  };

  renderCategoryEditor = () => {
    if (this.state.editType === CategoryEditTypes.NONE) {
      return undefined;
    }

    return (
      <div>
        <p style={styles.colTitle}>카테고리 {this.state.editType === CategoryEditTypes.UPDATE ? "수정" : "생성"}</p>
        <div style={{ padding: 10, backgroundColor: "#FFFFFF", border: "1px solid #cccccc" }}>
          <div>{this.state.editType === CategoryEditTypes.UPDATE ? <strong>Id: {this.state.selectedCategoryId}</strong> : undefined}</div>
          <strong>부모 카테고리 Id</strong>
          {this.state.editType === CategoryEditTypes.UPDATE ? (
            <Input
              value={this.state.parentId === null ? "" : this.state.parentId.toString()}
              onChange={(e) => {
                this.setState({ parentId: e.target.value });
              }}
            />
          ) : (
            <Input value={this.state.selectedCategoryId !== null ? this.state.selectedCategoryId.toString() : ""} disabled />
          )}
          <strong>이름</strong>
          <Input
            placeholder="이름"
            value={this.state.editName}
            onChange={(e) => {
              this.setState({ editName: e.target.value });
            }}
          />
          {this.state.editType === CategoryEditTypes.UPDATE ? (
            <div>
              <Button style={styles.marginTop10} color="primary" block onClick={this.onUpdateClick}>
                수정
              </Button>
              <Button style={styles.marginTop10} color="danger" block onClick={this.onDeleteClick}>
                삭제
              </Button>
            </div>
          ) : (
            <div>
              <Button style={styles.marginTop10} color="primary" block onClick={this.onCreateClick}>
                {this.state.editType === CategoryEditTypes.CREATE ? "생성" : "수정"}
              </Button>
            </div>
          )}
        </div>
        {this.state.editType === CategoryEditTypes.UPDATE ? (
          <div style={{ padding: 10, backgroundColor: "#FFFFFF", border: "1px solid #cccccc", marginTop: 10 }}>
            <div className="margin-bottom-30">
              <strong>Deck Ids</strong>
              <DeckListEditor
                value={this.state.deckIds}
                onChange={(jsonStr) => {
                  this.setState({ deckIds: jsonStr });
                }}
              />
            </div>

            <div className="margin-bottom-30">
              <strong>CBT Ids</strong>
              <CBTTestListEditor
                value={this.state.cbtIds}
                onChange={(jsonStr) => {
                  this.setState({ cbtIds: jsonStr });
                }}
              />
            </div>

            <div className="margin-bottom-30">
              <strong>Membership Ids</strong>
              <MembershipListEditor
                value={this.state.membershipIds}
                onChange={(jsonStr) => {
                  this.setState({ membershipIds: jsonStr });
                }}
              />
            </div>

            <Button style={styles.marginTop10} color="primary" block onClick={this.onUpdateItemClick}>
              수정
            </Button>
          </div>
        ) : undefined}
      </div>
    );
  };

  travelCategorys(categories, depth = 0, array = undefined, parentCategory = undefined) {
    if (!array) {
      array = [];
    }

    if (!categories) {
      return array;
    }

    const paddingLeft = 20 + depth * 15;

    for (let category of categories) {
      array.push(
        <li
          className={category.id === this.state.selectedCategoryId ? "selected" : ""}
          key={category.id}
          style={{ paddingLeft }}
          data={category.id}
          onClick={() => {
            this.onCategoryClick(category.id, parentCategory ? parentCategory.id : null);
          }}
        >
          {category.name}
          <i className="icon-plus add-btn" onClick={this.onCategoryAddClick} />
        </li>
      );
      this.travelCategorys(category.subCategories, depth + 1, array, category);
    }
    return array;
  }

  render() {
    const selectedCategory = this.getCategory(this.state.selectedCategoryId);
    return (
      <div>
        <Row>
          <Col xs="12" md="6">
            <p style={styles.colTitle}>카테고리 목록</p>
            <div style={styles.categoryList}>{this.renderCategories()}</div>
          </Col>
          {this.state.editType !== CategoryEditTypes.NONE ? (
            <Col xs="12" md="6">
              {this.renderCategoryEditor()}
            </Col>
          ) : undefined}
        </Row>
      </div>
    );
  }
}

const CategoryEditTypes = {
  NONE: "NONE",
  UPDATE: "UPDATE",
  CREATE: "CREATE",
};

const styles = {
  categoryList: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #cccccc",
  },
  colTitle: {
    marginLeft: 5,
    marginBottom: 5,
  },
  marginTop10: {
    marginTop: 10,
  },
};

export default connect(Category.StateToProps, Category.DispatchToProps)(Category);
